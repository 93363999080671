import Axios from 'config/axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConstant } from 'store/actions/constantAction';

const urlv4 = '/api/v4/schools/commentCategories';
const urlv5 = '/api/v5/groups/checkin/commentCategories';

let loading = false;

const useCommentCategories = (version = 4) => {
    const COMMENTS = useSelector((state) => state.constants.student_context);
    const TEMPLATES = useSelector((state) => state.constants.student_context_templates);
    const dispatch = useDispatch();

    const [student_context, setStudentContext] = useState([]);
    const [templates, setTemplates] = useState([]);

    const setAllTemplates = (templates) => {
        setTemplates(templates);
        dispatch(updateConstant('student_context_templates', templates));
    };

    useEffect(() => {
        const fetchData = async () => {
            if (COMMENTS) {
                setStudentContext(COMMENTS);
            }
            if (TEMPLATES) {
                setTemplates(TEMPLATES);
            }
            if (!COMMENTS || (!templates && version === 5)) {
                if (loading) return;
                loading = true;
                const response = await Axios.get(version === 5 ? urlv5 : urlv4);
                const comments =
                    version === 5
                        ? response.data.comments.map((comment) => {
                              return { ...comment, default: !comment.commentid.includes('_custom_') };
                          })
                        : response.data.map((comment) => {
                              return { ...comment, default: !comment.commentid.includes('_custom_') };
                          });
                dispatch(updateConstant('student_context', comments));
                if (version === 5) dispatch(updateConstant('student_context_templates', response.data.templates));
                setStudentContext(comments);
                if (version === 5) setTemplates(response.data.templates);
                loading = false;
            }
        };

        fetchData();
    }, [COMMENTS, dispatch, version]);

    return version === 5 ? { contexts: student_context, templates, setAllTemplates } : student_context;
};

export default useCommentCategories;
