import axios from 'config/axios';
import Auth from './Auth';

class Groups {
    static async getUsers(groupId, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(
                `/api/v4/groups/users?userType=${opts.userType || ''}&gradeId=${
                    opts.gradeId === -1 ? '' : +opts.gradeId === 0 ? 0 : +opts.gradeId || ''
                }&firstName=${opts.firstName || ''}&include=user_classes`
            )
            .then((res) => res.data);
    }

    static async getClasses(opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(
                `/api/v4/groups/classes?${
                    opts?.sisId
                        ? `sisId=${encodeURIComponent(opts.sisId)}&`
                        : opts?.providerClassId
                        ? `providerClassId=${encodeURIComponent(opts.providerClassId)}&`
                        : ''
                }include=${opts.include?.join(',') || ''}`
            )
            .then((res) => res.data);
    }

    static async getGroupCheckInComments({ groupId, start, end, range, currentFilters }) {
        return await axios.get(`/api/v3/checkin/groups/${groupId}/reason?range=${range}&start=${start}&end=${end}`);
    }

    static async getGroupCheckinSummaryForRings(opts = {}) {
        await Auth.checkCurrentAuthToken();
        const cF = Object.keys(opts.currentFilters)
            .map((filter) => {
                return `${filter}=${
                    Array.isArray(opts.currentFilters[filter])
                        ? opts.currentFilters[filter].join(',')
                        : opts.currentFilters[filter]
                }`;
            })
            .join('&');
        return await axios
            .get(`/api/v4/groups/checkin/dailySummary?range=${opts.range || ''}&${cF}`)
            .then((r) => r.data);
    }

    static async getCheckInSummary({ startDate, endDate, range, currentFilters }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(
                `/api/v4/groups/checkInSummary?range=${range}&startDate=${startDate}&endDate=${endDate}&${Object.keys(
                    currentFilters
                )
                    .map((filter) => {
                        return `${filter}=${
                            Array.isArray(currentFilters[filter])
                                ? currentFilters[filter].join(',')
                                : currentFilters[filter]
                        }`;
                    })
                    .join('&')}${opts.grades ? `&grades=${opts.grades}` : ''}`
            )
            .then((res) => res.data);
    }

    static async getCheckInSummaryExport({ startDate = {}, endDate = {}, range = {}, currentFilters = {} }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(
                `/api/v4/groups/checkInSummary/export?range=${range}&startDate=${startDate}&endDate=${endDate}&${Object.keys(
                    currentFilters
                )
                    ?.map((filter) => {
                        return `${filter}=${
                            Array.isArray(currentFilters[filter])
                                ? currentFilters[filter].join(',')
                                : currentFilters[filter]
                        }`;
                    })
                    .join('&')}`
            )
            .then((res) => res.data);
    }

    static async getStudentCommentSummary({ classId, startDate, endDate, range, currentFilters }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(
                `/api/v4/groups/studentCommentSummary?range=${range}&startDate=${startDate}&endDate=${endDate}&${Object.keys(
                    currentFilters
                )
                    .map((filter) => {
                        return `${filter}=${
                            Array.isArray(currentFilters[filter])
                                ? currentFilters[filter].join(',')
                                : currentFilters[filter]
                        }`;
                    })
                    .join('&')}`
            )
            .then((res) => res.data);
    }

    static async getClassesActivityData({ startDate, endDate, range, currentFilters }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(
                `/api/v4/groups/classesActivityData?range=${range}&startDate=${startDate}&endDate=${endDate}&${Object.keys(
                    currentFilters
                )
                    ?.map((filter) => {
                        return `${filter}=${
                            Array.isArray(currentFilters[filter])
                                ? currentFilters[filter].join(',')
                                : currentFilters[filter]
                        }`;
                    })
                    .join('&')}`
            )
            .then((res) => res.data);
    }

    static async getGroupUsers(opts = {}) {
        return await axios
            .get(
                `/api/v4/groups/groupUsers/?${opts.include ? `include=${opts.include}&` : ''}${
                    opts.studentLogin ? 'studentLogin=true&' : ''
                }${
                    opts.search
                        ? Object.keys(opts.search)
                              ?.map((searchKey) => {
                                  return `${searchKey}=${opts.search[searchKey]}`;
                              })
                              .join('&')
                        : ''
                }`
            )
            .then((r) => r.data);
    }

    static async getCheckInForStudentRequiringAttention(userIds, opts = {}) {
        const { startDate, endDate, range, currentFilters } = opts;
        return await axios
            .post(
                `/api/v4/groups/studentCheckInById/?range=${range}&startDate=${startDate}&endDate=${endDate}&${
                    opts?.recommendedUsers ? 'recommendedUsers=true' : ''
                }&${Object.keys(currentFilters)
                    .map((filter) => {
                        return `${filter}=${
                            Array.isArray(currentFilters[filter])
                                ? currentFilters[filter].join(',')
                                : currentFilters[filter]
                        }`;
                    })
                    .join('&')}`,
                userIds
            )
            .then((r) => r.data);
    }

    static async exportStudentCheckins({ currentFilters, startDate, endDate, range }) {
        return await axios.get(
            `/api/v4/groups/exportStudentCheckins/?range=${range}&startDate=${startDate}&endDate=${endDate}${
                currentFilters
                    ? `&${Object.keys(currentFilters)
                          .map((filter) => {
                              return `${filter}=${
                                  Array.isArray(currentFilters[filter])
                                      ? currentFilters[filter].join(',')
                                      : currentFilters[filter]
                              }`;
                          })
                          .join('&')}`
                    : ''
            }`
        );
    }

    static async getUnreviewedJournalEntries({ opts = { classId: '', include: '' } }) {
        return await axios.get(
            `/api/v4/groups/unreviewed-journal-entries?${
                opts?.classId
                    ? `classId=${Array.isArray(opts.classId) ? opts.classId?.join(',') : [opts.classId]?.join(',')}&`
                    : ''
            }${opts?.include ? `include=${opts.include}` : ''}`
        );
    }

    static async markJournalAsReviewed({ recordId }) {
        return await axios.put(`/api/v4/groups/mark-journal-reviewed/${recordId}`);
    }

    static async uploadClasses(data) {
        return await axios.post(`/api/v4/groups/upload-classes`, data);
    }
}

export default Groups;
