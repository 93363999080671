//https://github.com/vimeo/player.js#ended
import React, { Component } from 'react';

import { isEmpty } from '@lsgo/lsgo-common';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { CATEGORIES, EVENTS, STUDENTS } from '../../constants/googleAnalytics';
import Player from '@vimeo/player';
import { ampli } from 'ampli';

class VideoActivity extends Component {
    constructor(props) {
        super(props);

        this.videoObject = React.createRef();
        this.player = null;
        this.state = {
            loaded: false,
            title: '',
            context: this.props.context,
        };

        const { demoMode } = this.props;
    }

    componentDidMount() {
        // Added timeout as the ref was returnning null on component did mount
        setTimeout(() => {
            if (this.videoObject.current) {
                const player = new Player(this.videoObject.current);
                this.player = player;

                player.on('play', this.onPlay);

                player.on('ended', this.onEnded);

                player.on('pause', this.onPause);

                player.on('seeked', this.onSeeked);

                this.getVideoTitle();

                /* player.onPlay = this.onPlay;
                player.onPause = this.onPause;
                player.onended = this.onEnded; */
            }
        }, 100);

        //clear border and whitebackground;
        const VideoContainer = document.getElementsByClassName('student-box2')[0];
        VideoContainer?.classList.add('clear-border-background');
    }

    componentWillUnmount() {
        const VideoContainer = document.getElementsByClassName('student-box2')[0];
        VideoContainer?.classList.remove('clear-border-background');
    }

    getVideoTitle = () => {
        this.player
            .getVideoTitle(this.props.videoUrl)
            .then((title) => {
                this.setState({ ...this.state, title });
            })
            .catch(function (error) {
                console.log({ error });
            });
    };

    onSeeked = (e) => {
        ampli.videoActioned({
            name: this.state.title,
            context: this.state.context,
            triggered_by: 'system',
            action: 'seeked',
            percent: e.percent,
            seconds: e.seconds,
            duration: e.duration,
        });
    };

    onPlay = (e) => {
        ampli.videoActioned({
            name: this.state.title,
            context: this.state.context,
            triggered_by: 'system',
            action: 'played',
            percent: e.percent,
            seconds: e.seconds,
            duration: e.duration,
        });
        this.setState({
            loaded: true,
        });
    };

    onPause = (e) => {
        try {
            ampli.videoActioned({
                name: this.state.title,
                context: this.state.context,
                triggered_by: 'system',
                action: 'paused',
                percent: e.percent,
                seconds: e.seconds,
                duration: e.duration,
            });
            ReactGA.event({
                category: CATEGORIES.STUDENT_ACTIONS,
                action: EVENTS.VIDEO_PAUSED,
            });
        } catch (e) {
            console.error(e);
        }
    };

    onEnded = (e) => {
        try {
            ampli.videoActioned({
                name: this.state.title,
                context: this.state.context,
                triggered_by: 'system',
                action: 'ended',
                percent: e.percent,
                seconds: e.seconds,
                duration: e.duration,
            });
            ReactGA.event({
                category: CATEGORIES.STUDENT_ACTIONS,
                action: EVENTS.VIDEO_ENDED,
            });
        } catch (e) {
            console.error(e);
        }

        this.props.activityDone();
    };

    componentDidUpdate(prevProps) {
        const { pauseActivity } = this.props.ui;

        if (pauseActivity !== prevProps.ui.pauseActivity) {
            if (pauseActivity) {
                console.log('user updated');
                this.player.pause();
            } else {
                console.log('user updated');
                this.player.play();
            }
        }
    }

    render() {
        const { videoUrl } = this.props;
        console.log(videoUrl, 'url');
        return (
            <div className='video-wrapper'>
                {videoUrl && (
                    <>
                        {/* <ReactPlayer
                            url={`https://player.vimeo.com/video/${videoUrl}`}
                            playing
                            width={'100%'}
                            height="58vh"
                            onEnded={this.onEnded}
                            onPlay={this.onPlay}
                            onPause={this.onPause}
                        /> */}
                        <iframe
                            title={this.props.title}
                            ref={this.videoObject}
                            style={
                                this.props.style || {
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                }
                            }
                            src={`https://player.vimeo.com/video/${videoUrl}?autoplay=1`}
                            frameBorder='0'
                            allow='autoplay; fullscreen'
                            allowFullScreen
                            autoPlay={1}
                        ></iframe>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ui: state.ui,
});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoActivity);
