import {
    GET_ERRORS,
    ADD_CLASS,
    REMOVE_CLASS,
    HIDE_CLASS_LOADER,
    EDIT_CLASS,
    GET_CLASS_DATA,
    REMOVE_UNIT,
    SET_CURRENT_CLASS,
    ADD_CLASS_V2,
    SET_CLASS,
    ADD_TEACHERS_TO_CLASS,
    ADD_STUDENTS_TO_CLASS,
    SET_UNIT_ACTIVE,
    REMOVE_STUDENTS_FROM_CLASS,
    UPDATE_CLASS_USERS,
    SHOW_CLASS_LOADER,
    ADMIN_CLASS_VIEW,
    ATTENDANCE,
} from './actionTypes';
import { toggleModal, showNotification, setVisibleUnit } from './uiActions';
import { showServerNotification } from './uiActions';
import Notification from '../../components/UI/Notification';
import { addUnitToStore } from 'store/actions/unitActions';

import axios from 'config/axios';
import Classes from 'Api/Classes';
import Units from 'Api/Units';

export const updateClassUsersInState =
    ({ users, classId, actionType }) =>
    async (dispatch) => {
        try {
            console.log(users, classId, actionType, 'action');
            dispatch({
                type: UPDATE_CLASS_USERS,
                payload: { classId, users, actionType },
            });
        } catch (err) {
            console.log({ err });
            dispatch({
                type: GET_ERRORS,
                payload: err,
            });
            // dispatch(showServerNotification('Failed to update users in class', [], 5000, null, 'warning'));
        }
    };

export const updateAttendance =
    ({ isAttendance, students, completed }) =>
    async (dispatch) => {
        try {
            dispatch({
                type: ATTENDANCE,
                payload: { isAttendance, students, completed },
            });
        } catch (err) {
            console.log({ err });
            dispatch({
                type: GET_ERRORS,
                payload: err,
            });
            // dispatch(showServerNotification('Failed to update users in class', [], 5000, null, 'warning'));
        }
    };

export const updateAdminClassView = (classId, className) => async (dispatch) => {
    try {
        dispatch({
            type: ADMIN_CLASS_VIEW,
            payload: { classId, className },
        });
    } catch (err) {
        console.log({ err });
        dispatch({
            type: GET_ERRORS,
            payload: err,
        });
    }
};

/**
 * Dispatch new class action to the reducer
 * @param {object} result - server data
 * @return {void}
 */
export const deleteUnitFromState = (classId, unitId) => (dispatch) => {
    dispatch({
        type: REMOVE_UNIT,
        payload: { classId, unitId },
    });
};

/**
 * Delete class record
 * 1. send data to the server
 * 2. update redux
 * 3. remove the dialog with form
 * @param  {object} classData
 * @return {void}
 */
export const deleteUnit = (unit) => async (dispatch) => {
    try {
        const { unitId } = unit;

        const result = await axios.delete('/api/v4/lessons/school/unit/delete/' + unitId);

        if (result) {
            // remove class from redux state
            // dispatch(deleteUnitFromState(unitId));

            //notify
            dispatch(showServerNotification('Unit deleted successfully', [], 5000, null));

            return true;
        }
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });

        return err;
    }
};

export const setCurrentClass = (classObject) => async (dispatch) => {
    dispatch({
        type: SET_CURRENT_CLASS,
        payload: classObject,
    });
};

/**
 * Get class data by class id
 * @param classId
 * @return {void}
 */
export const getClass = (classId) => (dispatch) => {
    dispatch({
        type: GET_CLASS_DATA,
        payload: { classId: classId },
    });
};

export const hideLoad = (dispatch) => {
    dispatch({
        type: HIDE_CLASS_LOADER,
    });
};

export const setLoading = (load) => {
    return { type: load ? SHOW_CLASS_LOADER : HIDE_CLASS_LOADER };
};

/**
 * Dispatch new class action to the reducer
 * @param {object} result - server data
 * @return {void}
 */
export const addNewClass = (result) => (dispatch) => {
    dispatch({
        type: ADD_CLASS,
        payload: result,
    });
};

export const addClass = (result) => (dispatch) => {
    dispatch({
        type: ADD_CLASS_V2,
        payload: result,
    });
};

/**
 * Create new class record
 * 1. send data to the server
 * 2. update redux
 * 3. remove the dialog with form
 * @param  {object} classData
 * @return {void}
 */
export const createClass = (classData, history, modalState) => async (dispatch) => {
    try {
        let classUsers = [];
        if (classData.students) classUsers = [...classUsers, ...classData.students.map((s) => s.id)];
        if (classData.teachers) classUsers = [...classUsers, ...classData.teachers.map((t) => t.id)];
        let newClassData = {
            className: classData.className,
            users: classUsers,
        };
        const result = await Classes.createClass(newClassData);

        if (result) {
            let newClass = result.data;
            newClass['units'] = [];
            newClass['classGrades'] = [];
            newClass['Students'] = [];
            newClass['teachers'] = result.data.users;

            //add new class
            dispatch(addNewClass(newClass));
            history && history.push('/teacher');

            if (!modalState) {
                //remove the dialog
                dispatch(toggleModal(false));
            }

            //show notification
            dispatch(showServerNotification(`Successfully created class: ${newClass.className}`, [], 5000, null));
            return newClass;
        }
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
        return err;
    }
};

/**
 * Dispatch new class action to the reducer
 * @param {object} result - server data
 * @return {void}
 */
export const editClassList = (result) => (dispatch) => {
    dispatch({
        type: EDIT_CLASS,
        payload: result,
    });
};

export const setClass = (clss) => (dispatch) => {
    dispatch({ type: SET_CLASS, payload: clss });
};

/**
 * edit class record
 * 1. send data to the server
 * 2. update redux
 * 3. remove the dialog with form
 * @param  {object} classData
 * @return {void}
 */
export const editClass = (classData) => async (dispatch) => {
    try {
        dispatch(editClassList(classData));

        //remove the dialog
        dispatch(toggleModal(false));

        //show notification
        !classData.Students &&
            dispatch(showServerNotification('Class details were saved successfully', [], 5000, null));
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response,
        });
    }
};

/**
 * Dispatch new class action to the reducer
 * @param {object} result - server data
 * @return {void}
 */
export const deleteClassFromState = (classId) => (dispatch) => {
    dispatch({
        type: REMOVE_CLASS,
        payload: classId,
    });
};

/**
 * Delete class record
 * 1. send data to the server
 * 2. update redux
 * 3. remove the dialog with form
 * @param  {object} classData
 * @return {void}
 */
export const deleteClass = (classId, teacherId) => async (dispatch) => {
    try {
        const result = await Classes.deleteClass(classId, teacherId);

        if (result) {
            //remove class from redux state
            dispatch(deleteClassFromState(classId));

            //notify
            dispatch(showServerNotification(`Class deleted successfully`, [], 5000, null));
        }
    } catch (err) {
        console.log(err, 'err');
        dispatch({
            type: GET_ERRORS,
            payload: err.response,
        });
    }
};

export const getTemplateUnitsForSchool = (groupId) => async (dispatch) => {
    try {
        const templateUnits = await axios.get(`/api/v4/lessons/curriculum/school/${groupId}`);
        return templateUnits.data;
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
        dispatch(showNotification(Notification.DANGER, 'Failed to get default courses'));

        return err;
    }
};

export const createClassUnit = (classId, unit) => async (dispatch) => {
    try {
        const createUnit = await Units.createClassUnit(classId, unit);

        if (createUnit) {
            //add new unit to store
            dispatch(addUnitToStore(createUnit.newUnitData));
            //set new unit as visible unit on curriculum
            dispatch(setVisibleUnit(createUnit.newUnitData.unitId));
            //clearactive unit state
            dispatch({ type: SET_UNIT_ACTIVE, payload: { unitId: createUnit.newUnitData.unitId } });
            return createUnit;
        }
        return 'Failed to create unit';
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
        dispatch(showNotification(Notification.DANGER, 'Failed to get default courses'));

        return err;
    }
};

export const editUnit = (unitId, unit) => async (dispatch) => {
    try {
        console.log(unitId, unit);
        const editUnit = await Units.editUnit(unitId, unit);
        if (editUnit) {
            return editUnit;
        }
        return 'Failed to update unit';
    } catch (err) {
        console.log(err);

        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
        dispatch(showNotification(Notification.DANGER, 'Failed to get default courses'));

        return err;
    }
};

export const getUnitById = (unitId) => async (dispatch) => {
    try {
        const getUnit = await axios.get(`/api/v4/lessons/curriculum/class/edit/${unitId}`);
        if (getUnit) {
            return getUnit.data;
        }
        return 'Failed to create unit';
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
        dispatch(showNotification(Notification.DANGER, 'Failed to get default courses'));

        return err;
    }
};

export const createSchoolUnit = (schoolId, unit) => async (dispatch) => {
    try {
        const createUnit = await Units.createSchoolUnit(schoolId, unit);

        return createUnit;
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
        dispatch(showNotification(Notification.DANGER, 'Failed to get default courses'));

        return err;
    }
};
