import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { CATEGORIES, EVENTS } from 'constants/googleAnalytics';
import { connect } from 'react-redux';

import Api from 'Api';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import htmlParser from 'html-react-parser';
import ListItem from '@mui/material/ListItem';
import Badge from '@mui/material/Badge';

import { isEmpty } from '@lsgo/lsgo-common';
import moment from 'moment';

import { emitTrackingEvent } from 'utils/clientSocket';
import { ampli } from 'ampli';

const NotificationFetchLimit = 3;

const PlatformNotification = (props) => {
    // Notification State
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [unread, setUnread] = useState(0);

    const [notifications, setNotifications] = useState([]);
    const [notificationPagination, setNotificationPagination] = useState({
        limit: NotificationFetchLimit,
        offset: 0,
        more: true,
    });

    const [openNotificationId, setOpenNotificationId] = useState(false);
    const [loadingNotifications, setLoadingNotifications] = useState(false);
    const [lastNotification, setLastNotification] = useState(null);

    const menuId3 = 'notifications-menu';

    const isNotificationsMenuOpen = Boolean(anchorEl3);
    const handleNotificationMenuOpen = (event) => {
        //GA custom event for view notifications
        ReactGA.event({
            category: CATEGORIES.TEACHER_ACTIONS,
            action: EVENTS.VIEW_NOTIFICATIONS,
        });
        ampli.buttonClicked({ button_name: 'notification_bell', location: 'Top Navigation Bar' });
        setAnchorEl3(event.currentTarget);
        emitTrackingEvent({
            area: 'SIDEBAR',
            action: 'NOTIFICATION_CENTER',
            params: {
                open: true,
            },
        });
    };

    const handleNotificationMenuClose = () => {
        setAnchorEl3(null);
        setOpenNotificationId(false);
        emitTrackingEvent({
            area: 'SIDEBAR',
            action: 'NOTIFICATION_CENTER',
            params: {
                open: false,
            },
        });
    };

    const loadNotifications = async () => {
        setLoadingNotifications(true);

        const received = await Api.getNotifications(
            props.auth.teacher.id,
            notificationPagination.limit,
            notificationPagination.offset
        );

        if (isEmpty(received.notifications) || typeof received.notifications !== 'object') {
            setNotificationPagination({
                limit: NotificationFetchLimit,
                offset: notificationPagination.offset,
                more: false,
            });
            return;
        } else {
            const newNotifications = [...notifications, ...received.notifications];

            //sort notifications to have all unread first
            setNotifications([...newNotifications]);
            setNotificationPagination({
                limit: NotificationFetchLimit,
                offset: notificationPagination.offset + NotificationFetchLimit,
                more: !received.notifications.length < NotificationFetchLimit,
            });

            if (lastNotification === null) {
                setLastNotification(newNotifications[0].notifiedAt);
            }

            //prepares the number of unread notifications
            setUnread(received.unreadCount);
        }

        setLoadingNotifications(false);
    };

    useEffect(() => {
        //load initial set of notiofications
        loadNotifications();
        //eslint-disble-next-line
    }, []);

    useEffect(() => {
        // add new notification to list
        function addNewNotification() {
            // const notificationData = Object.assign({}, { notificationStatus: 0 }, props.platformNotifications.data);
            const notificationData = props.platformNotifications.data;

            if (notificationData && !isEmpty(notificationData)) {
                setNotifications([notificationData, ...notifications]);
                setUnread(unread + 1);
            }
        }

        addNewNotification();
        //eslint-disable-next-line
    }, [props.platformNotifications]);

    useEffect(() => {
        function openNotificationSummary() {
            if (!isEmpty(props.openNotification)) {
                document.getElementById('notification-bell-icon').click();
                setOpenNotificationId(
                    `notification-${props.openNotification.notificationId}-${moment(
                        props.openNotification.notifiedAt
                    ).unix()}`
                );
                handleUnread(props.openNotification.notificationId, props.openNotification.notifiedAt);
            }
        }
        openNotificationSummary();

        //eslint-disable-next-line
    }, [props.openNotification]);

    async function handleUnread(notificationId, notifiedAt) {
        const unreadNotifications = unread - 1;
        setUnread(unreadNotifications);
        await Api.readNotification(props.auth.teacher.id, notificationId, notifiedAt);

        //GA custom event for reading notification
        ReactGA.event({
            category: CATEGORIES.TEACHER_ACTIONS,
            action: `${EVENTS.READ_NOTIFICATIONS}/${notificationId}`,
        });

        //update notification as read
        setNotifications((currentNotifications) => {
            const updatedList = [...currentNotifications];
            const updateIndex = currentNotifications.findIndex(
                (nf) => nf.notificationId === notificationId && nf.notifiedAt === notifiedAt
            );

            updatedList[updateIndex].notificationStatus = 1;

            return updatedList;
        });
    }

    const getNotificationList = () => {
        const notificationList = [
            <ListItem key='notification-title' className='my-notification-title'>
                <Typography variant='h6'>My Notifications</Typography>
                <IconButton variant='contained' color='secondary' onClick={handleNotificationMenuClose}>
                    <i className='fas fa-times' />
                </IconButton>
            </ListItem>,
        ];

        !isEmpty(notifications)
            ? notifications.map((notification) => {
                  const newNotification = notification.notificationStatus === 0;
                  const notificationMoment = moment(notification.notifiedAt);
                  const timeElapsed =
                      moment().diff(notificationMoment, 'days') <= 7
                          ? notificationMoment.fromNow()
                          : notificationMoment.format('MMM D, YYYY');

                  notificationList.push(
                      <ListItem className='notification-listitem' key={`notification-${notificationMoment.unix()}`}>
                          <Accordion
                              TransitionProps={{ unmountOnExit: true }}
                              onChange={(event, expanded) => {
                                  if (notification.notificationStatus === 0 && expanded === true) {
                                      if (unread >= 1) {
                                          //new unread count
                                          handleUnread(notification.notificationId, notification.notifiedAt);
                                      }
                                  }
                              }}
                              key={`notification-${notification.notificationId}-${notificationMoment.unix()}`}
                              className={`header-notification-accordion ${
                                  newNotification ? 'notification-new' : 'notification-read'
                              }`}
                              defaultExpanded={
                                  openNotificationId ===
                                  `notification-${notification.notificationId}-${notificationMoment.unix()}`
                              }
                          >
                              <AccordionSummary
                                  aria-controls='panel1a-content'
                                  id={`n-${notification.notificationId}-${notificationMoment.unix()}`}
                                  expandIcon={<i className='fas fa-chevron-down' />}
                              >
                                  <div style={{ maxWidth: '100%' }}>
                                      <Typography
                                          style={{
                                              fontWeight: newNotification ? 700 : 400,
                                          }}
                                          variant='subtitle1'
                                      >
                                          {notification.notificationTitle}
                                      </Typography>
                                      <Typography variant='body2'>
                                          <small>{timeElapsed}</small>
                                      </Typography>
                                  </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                  <div className='notification-message'>
                                      {htmlParser(notification.notificationMessage)}
                                  </div>
                              </AccordionDetails>
                          </Accordion>
                      </ListItem>
                  );
              })
            : notificationList.push(
                  <ListItem key='no-notifications'>
                      <Typography variant='body1' className='text-center w-100'>
                          You have no new notifications
                      </Typography>
                  </ListItem>
              );

        notificationList.push(
            <ListItem className='notification-listitem-more px-4' key={`notification-loadmore`}>
                {notificationPagination.more ? (
                    <>
                        {/* <Button variant='contained' color='secondary' onClick={loadNotifications}>
                        Load More
                    </Button> */}
                        <Typography
                            className='load-more-notifications text-right w-100 pr-2 cursor-pointer'
                            onClick={loadNotifications}
                        >
                            Load More
                            <i
                                className={`ml-2 fa  ${loadingNotifications ? 'fa-spin fa-spinner' : 'fa-ellipsis-h'}`}
                            />
                        </Typography>
                    </>
                ) : (
                    <Typography className='no-more-notifications text-muted text-center w-100'>
                        No more notifications
                    </Typography>
                )}
            </ListItem>
        );

        return notificationList;
    };

    return (
        <>
            <Menu
                id={menuId3}
                anchorEl={anchorEl3}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isNotificationsMenuOpen}
                onClose={handleNotificationMenuClose}
                className='header-notifications-list'
            >
                {getNotificationList()}
            </Menu>
            <IconButton
                edge='start'
                className='notification-menu'
                data-pagename='HEADER'
                data-objectname='HELP_ICON'
                onClick={handleNotificationMenuOpen}
            >
                <Badge
                    badgeContent={unread}
                    color='error'
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    style={{ color: '#fff', textTransform: 'none', margin: 8, marginTop: 8 }}
                    className='cursor-pointer'
                    data-pagename='SIDEBAR'
                    data-objectname='NOTIFICATIONS'
                    id='notification-bell-icon'
                >
                    <i className='fas fa-bell' />
                </Badge>{' '}
            </IconButton>
        </>
    );
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    platformNotifications: state.ui.platformNotifications,
    openNotification: state.ui.openNotification,
});

export default connect(mapStateToProps)(PlatformNotification);
