import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Typography } from '@mui/material';
import Skeleton from '@mui/lab/Skeleton';

/**
 * Text field group controled by Formik. Takes in a loading prop to render skeleton whilst data is loading.
 *@returns JSX
 */
const TextFieldGroup = ({
    name,
    placeholder,
    label,
    value,
    error,
    type,
    onChange,
    loading,
    disableClassName,
    studentEdit,
    validate,
}) => {
    return (
        <div className={!disableClassName ? 'form-group row' : ''} style={{ minWidth: 100 }}>
            {label && (
                <Typography className='col-3 col-form-label' variant='body2' style={{ fontFamily: 'Museo700' }}>
                    {label}
                </Typography>
            )}
            {!studentEdit && (
                <div className='col'>
                    {loading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Field
                            type={type}
                            name={name}
                            className={`form-control ${error ? 'is-invalid' : ''}`}
                            placeholder={placeholder}
                            value={value}
                            error={error}
                            onChange={onChange}
                            validate={validate}
                        />
                    )}
                    {error && <div className='invalid-feedback'>{error}</div>}
                </div>
            )}
            {studentEdit && (
                <>
                    {loading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Field
                            type={type}
                            name={name}
                            className={`form-control ${error ? 'is-invalid' : ''}`}
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                        />
                    )}
                    {error && <div className='invalid-feedback'>{error}</div>}
                </>
            )}
        </div>
    );
};

TextFieldGroup.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    error: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

TextFieldGroup.defaultProps = {
    type: 'text',
};

export default TextFieldGroup;
