import {
    TOGGLE_MODAL,
    TOGGLE_STUDENT_OVERLAY,
    PAUSE_STUDENT,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    SET_VISIBLE_UNIT,
    SERVER_NOTIFICATIONS,
    PLATFORM_NOTIFICATIONS,
    OPEN_NOTIFICATION,
    SET_DIALOG_PREVIEW,
    TOGGLE_ADMIN_VIEW,
    SET_HEADER_LABEL,
    FEATURE_FLAG,
} from '../actions/actionTypes';

const initialState = {
    showModal: false,
    dashboardModal: 'none',
    studentOverlay: false,
    pauseActivity: false,
    notification: {
        type: undefined,
        message: null,
    },
    beta: true,
    visibleUnit: null,
    serverNotifications: {},
    platformNotifications: {},
    openNotification: {},
    dialogPreview: null,
    isAdminView: false,
    headerLabel: null,
    featureFlag: {},
    meta: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TOGGLE_MODAL: {
            return {
                ...state,
                showModal: action.payload.showModal,
                dashboardModal: action.payload.dashboardModal || 'none',
                meta: action.payload.meta,
            };
        }
        case TOGGLE_STUDENT_OVERLAY: {
            return {
                ...state,
                studentOverlay: action.payload.studentOverlay,
            };
        }
        case PAUSE_STUDENT: {
            return {
                ...state,
                pauseActivity: action.payload.pause,
            };
        }
        case SHOW_NOTIFICATION: {
            return {
                ...state,
                notification: {
                    type: action.payload.notificationType,
                    message: action.payload.content,
                },
            };
        }
        case HIDE_NOTIFICATION: {
            return {
                ...state,
                notification: {},
            };
        }
        case SET_VISIBLE_UNIT: {
            return { ...state, visibleUnit: action.payload.unitId };
        }
        case SERVER_NOTIFICATIONS: {
            return {
                ...state,
                serverNotifications: {
                    message: action.payload.content,
                    children: action.payload.children,
                    timeOut: action.payload.timeOut,
                    onClick: action.payload.onClick,
                    type: action.payload.type,
                },
            };
        }
        case PLATFORM_NOTIFICATIONS: {
            const { data, level, levelId } = action.payload;
            const idOfLevel = levelId ? `${level}-${levelId}` : null;
            return {
                ...state,
                platformNotifications: {
                    data: data,
                    level: idOfLevel,
                },
            };
        }
        case OPEN_NOTIFICATION: {
            const { notificationId, notifiedAt } = action.payload;
            return {
                ...state,
                openNotification: {
                    notificationId: notificationId,
                    notifiedAt: notifiedAt,
                },
            };
        }
        case TOGGLE_ADMIN_VIEW: {
            const { adminView } = action.payload;
            return {
                ...state,
                isAdminView: adminView !== undefined ? adminView : !state.isAdminView,
                headerLabel: (adminView !== undefined ? adminView : !state.isAdminView) ? 'School Admin' : null,
            };
        }
        case SET_HEADER_LABEL: {
            const { headerLabel } = action.payload;
            return { ...state, headerLabel, isAdminView: false };
        }
        case SET_DIALOG_PREVIEW: {
            return { ...state, dialogPreview: action.payload };
        }
        case FEATURE_FLAG: {
            return {
                ...state,
                featureFlag: {
                    ...state?.featureFlag,
                    [action.payload.featureLabel]: {
                        checked: action.payload.checked,
                        accessAllowed: action.payload.accessAllowed,
                    },
                },
            };
        }
        default:
            return state;
    }
}
