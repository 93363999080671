import activityTypes from '../constants/activityTypes';
import physical_awareness from '../assets/images/cogs/physical_awareness.png';
import positive_relationships from '../assets/images/cogs/positive_relationships.png';
import self_awareness from '../assets/images/cogs/self_awareness.png';
import self_management from '../assets/images/cogs/self_management.png';
import social_awareness from '../assets/images/cogs/social_awareness.png';
import wise_decisions from '../assets/images/cogs/wise_decisions.png';
import { Buffer } from 'buffer';
import moment from 'moment';

export const getActivityByType = (type, key = null) => {
    const data = activityTypes
        .filter((activity) => {
            return activity._id === type;
        })
        .shift();

    if (data && key) {
        return data[key];
    } else {
        return data;
    }
};

export const extractDataAttributes = (props) => {
    return Object.keys(props)
        .filter((name) => name.includes('data-'))
        .reduce((obj, key) => {
            return {
                ...obj,
                [key]: props[key],
            };
        }, {});
};

export const getCogImage = (cogName) => {
    const images = {
        physical_awareness: physical_awareness,
        positive_relationships: positive_relationships,
        self_awareness: self_awareness,
        self_management: self_management,
        social_awareness: social_awareness,
        wise_decisions: wise_decisions,
    };

    const name = cogName && cogName.replace(' ', '_');
    return images[name];
};

export const lessonGroupsToAreasAndFoci = (lessonGroups) => {
    const names = lessonGroups.map((group) => {
        const focusNames = group.lessons.map((lesson) => {
            if (!lesson.focus) {
                return [];
            }
            return [lesson.focus.learningArea.areaName, lesson.focus.focusName];
        });

        return focusNames;
    });
    const flatten = [];
    names.forEach((nameGroup) => {
        nameGroup.forEach((pair) => {
            flatten.push(pair);
        });
    });
    const temp = flatten[0].map((col, i) => flatten.map((row) => row[i]));
    return [[...new Set(temp[0])], [...new Set(temp[1])]];
};

export const createQueryStringFromObject = (query) => {
    return Object.keys(query)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(query[k]))
        .join('&');
};

export const redirectToLSGOShop = (query = {}) => {
    const queryString = createQueryStringFromObject(query);
    if (window.location.hostname.includes('-staging')) {
        window.open(`https://shop-staging.lifeskillsgo.com?${queryString}`);
    } else if (window.location.hostname.includes('lifeskillsgo.com')) {
        window.open(`https://shop.lifeskillsgo.com?${queryString}`);
    } else {
        window.open(`http://localhost:3004?${queryString}`);
    }
};

export const shuffleArray = (array) => {
    let currentIndex = array.length;
    let randomIndex = null;

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

const defaultExport = {
    getActivityByType: getActivityByType,
};

export default defaultExport;

export const STRIPE_API_KEY =
    window.location.href.indexOf('-staging') > -1
        ? 'pk_test_ZtLZDgTm2sjPrRUWmRzsxmRC'
        : 'pk_live_mamkS6FszqmKUBGd11CMFXFa';

export const studentAppLink =
    window.location.hostname.includes('lifeskillsgo') && window.location.hostname.includes('staging')
        ? `//student-staging.lifeskillsgo.com`
        : !window.location.hostname.includes('lifeskillsgo')
        ? `//localhost:3001`
        : `//student.lifeskillsgo.com`;

export const teacherAppLink =
    window.location.hostname.includes('lifeskillsgo') && window.location.hostname.includes('staging')
        ? `https://teacher-staging.lifeskillsgo.com`
        : !window.location.hostname.includes('lifeskillsgo')
        ? `http://localhost:3000`
        : `https://teacher.lifeskillsgo.com`;

export const resourceAppLink =
    window.location.hostname.includes('lifeskillsgo') && window.location.hostname.includes('staging')
        ? `//resources-staging.lifeskillsgo.com`
        : !window.location.hostname.includes('lifeskillsgo')
        ? `//localhost:3005`
        : `//resources.lifeskillsgo.com`;

export const parseJwt = (token) => {
    let base64Payload;
    let payload;
    return new Promise((resolve, reject) => {
        try {
            base64Payload = token.split('.')[1];
            payload = Buffer.from(base64Payload, 'base64');
            return resolve(JSON.parse(payload.toString()));
        } catch (e) {
            console.log(e, 'error');
            return reject(null);
        }
    });
};

export const timeBetween = (start, end) => {
    const format = 'hh:mm:ss';

    // var time = moment() gives you current time. no format required.
    const time = moment();
    const beforeTime = moment(start, format);
    const afterTime = moment(end, format);

    if (time.isBetween(beforeTime, afterTime)) {
        return true;
    } else {
        return false;
    }
};
