import React, { useEffect } from 'react';
import axios from 'config/axios';

import { AppCues as AppcuesIds } from 'constants/index';

const AppCuesEvents = () => {
    useEffect(() => {
        if (window.Appcues) {
            window.Appcues.on('flow_skipped', (eventData) => {
                const { flowId } = eventData;
                const { path, name } = AppcuesIds.find((flow) => flow.id === flowId);
                const { host } = window.location;
                axios.post('/api/v4/constants/events', { flowId, path, host, name });
            });
        }
    }, [window.Appcues]);

    return <div></div>;
};

export default AppCuesEvents;
