/**-------------------- REACT AND REDUX IMPORTS --------------------**/

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { CATEGORIES, EVENTS } from 'constants/googleAnalytics';
import { deleteClass, createClass, editClass, updateClassUsersInState } from 'store/actions/classActions'; // We are handling classes in this component, we want to create, edit or delete
import { toggleModal } from 'store/actions/uiActions';

/**-------------------- FORMIK AND YUP VALIDATION --------------------**/

import { withFormik, Form } from 'formik';
import { string as YupString, object as YupObject } from 'yup';
import TextFieldGroup from '../Common/TextFieldGroup';

/**-------------------- CUSTOM THEMING --------------------**/

import CustomColors from 'assets/customisedMUI/CustomColors';
import {
    Button,
    Chip,
    Typography,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Autocomplete,
    TextField,
} from '@mui/material';

import './class.scss';

/**-------------------- SERVER REQUEST - AXIOS --------------------**/

import Teachers from 'Api/Teachers';

/**-------------------- UTILITIES --------------------**/
import { isEmpty } from '@lsgo/lsgo-common';
import Classes from 'Api/Classes';
import Groups from 'Api/Groups';

/**
 * ClassDetailsForm renders the view to modify, edit or delete a class. It takes a mode (editing) on props as well as a currentClass is true.
 * @param {object} props - Props passed in from parent
 * @param {object} props.currentClass - The current class object for editing class
 * @param {boolean} props.edit - The mode of the form (editing/new): true for editing.
 */
const ClassDetailsForm = (props) => {
    /**-------------------- STATE HANDLING --------------------**/
    // Get a list of teachers from the current class. Setup a state variable to hold a list of teachers from the users current school
    const [schoolTeachers, setSchoolTeachers] = useState([]);
    const fullScreen = useMediaQuery('(max-width:767px)');
    const [currentTeachers, setCurrentTeachers] = useState([]);

    // Destructure props
    const { values, edit, errors } = props;

    /**-------------------- ASYNC SIDE EFFECT HANDLING --------------------**/
    // Get a list of school teachers to use as recommendations when adding new teacher
    useEffect(() => {
        props.setValues({ ...values, otherTeachers: currentTeachers });
        async function fetchData() {
            const schoolData = await Groups.getGroupUsers({ include: ['teachers'] });

            setSchoolTeachers(
                // Map teachers to autocomplete objects {id: teacherId, name: fullName}
                schoolData?.teachers
                    ?.filter((u) => u.userType === 'teacher')
                    .map((teacher) => {
                        return {
                            id: teacher.id,
                            name: teacher.firstName + ' ' + teacher.lastName,
                            firstName: teacher.firstName,
                            lastName: teacher.lastName,
                            userType: teacher.userType,
                        };
                    })
            );
        }
        fetchData();
        //eslint-disable-next-line
    }, [props.auth.teacher.groups.groupId]);

    useEffect(() => {
        props.currentClass &&
            props.currentClass.teachers &&
            setCurrentTeachers(
                props.currentClass.teachers.map((teacher) => {
                    return {
                        id: teacher.id,
                        name: teacher.firstName + ' ' + teacher.lastName,
                    };
                })
            );
    }, [props.currentClass]);

    /** Render the form as a Modal that is returned to the main view. The name 'newClass' can be invokd on redux UI state to render this modal
     * On modal close, call the Formik setValues to clear values, as well as the Autocomplete clear props clear values onlcik handler.
     *
     */
    return (
        <Dialog
            maxWidth='lg'
            className='badges-dialog'
            open={
                props.ui.showModal &&
                (props.ui.dashboardModal === 'newClass' || props.ui.dashboardModal === 'editClass')
            }
            fullWidth={true}
            fullScreen={fullScreen}
            onClose={() => {
                props.resetForm();
                props.toggleModal('', false);
            }}
        >
            <DialogTitle>
                <Typography variant='h6' paragraph style={{ fontFamily: 'Museo700', textAlign: 'center' }}>
                    {edit ? 'Edit details for your class' : 'Enter details for your class'}{' '}
                    {/** Switch title based on edit mode */}
                </Typography>
                <span
                    className='close-dialog pull-right'
                    onClick={() => {
                        props.resetForm();
                        props.toggleModal('', false);
                    }}
                >
                    <i className='far fa-times-circle' />
                </span>
            </DialogTitle>
            <DialogContent>
                <Form>
                    <TextFieldGroup
                        name='className'
                        label='Class name'
                        value={values.className}
                        error={errors.className}
                        onChange={(e) => {
                            props.setValues({
                                ...values,
                                className: e.target.value,
                            }); /** Set the form value for className on edit of the text field */
                        }}
                    />
                    {/** Only allow editing of class teachers when editing class. Not available for new class. */}
                    {values.classId !== null && edit && (
                        <Autocomplete
                            multiple
                            id='tags=standard'
                            options={schoolTeachers} //.filter((t) => !currentTeachers.map((ct) => ct.id).includes(t.id))}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        {option.name}
                                    </li>
                                );
                            }}
                            defaultValue={
                                schoolTeachers &&
                                currentTeachers &&
                                schoolTeachers.filter((pl) => currentTeachers.map((item) => item.id).includes(pl.id))
                            }
                            value={
                                schoolTeachers &&
                                currentTeachers &&
                                schoolTeachers.filter((pl) => currentTeachers.map((item) => item.id).includes(pl.id))
                            }
                            onChange={(e, val) => {
                                const classTeachers = currentTeachers.find((t) => t.id === props.auth.teacher.id)
                                    ? [
                                          currentTeachers.find((t) => t.id === props.auth.teacher.id),
                                          ...val.filter((t) => t.id !== props.auth.teacher.id),
                                      ]
                                    : [...val.filter((t) => t.id !== props.auth.teacher.id)];
                                props.setValues({
                                    classId: props.currentClass?.classId,
                                    otherTeachers: classTeachers,
                                });
                                setCurrentTeachers(classTeachers);
                            }}
                            renderTags={(tagValue, getTagProps) => {
                                return tagValue.map((option, index) => (
                                    <Chip
                                        key={option.id}
                                        label={option.id === props.auth.teacher.id ? 'You' : option.name}
                                        {...getTagProps({ index })}
                                        style={{
                                            backgroundColor: CustomColors('teal'),
                                            margin: 4,
                                            textTransform: 'capitalize',
                                            fontSize: 15,
                                            fontWeight: 'bold',
                                        }}
                                        disabled={option.id === props.auth.teacher.id}
                                    />
                                ));
                            }}
                            getOptionDisabled={(option) => option.id === props.auth.teacher.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='standard'
                                    label='Class Teachers'
                                    placeholder='Select class teachers'
                                    onError={(e) => console.log(e, 'e')}
                                />
                            )}
                        />
                    )}
                </Form>
            </DialogContent>
            <DialogActions>
                {props.ui.dashboardModal === 'editClass' && (
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                            props.onDeleteClass(values.classId, props.auth.teacher.id);
                            props.toggleModal('', false);
                        }}
                    >
                        Archive Class
                    </Button>
                )}
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        props.edit
                            ? Classes.updateClass(values.classId, { className: values.className }).then(
                                  async (classResult) => {
                                      props.onEditClass(classResult.data);
                                      const teachersToAdd = currentTeachers
                                          ?.map((t) => t.id)
                                          .filter(
                                              (t) =>
                                                  !props.currentClass?.teachers
                                                      ?.map((teacher) => teacher.id)
                                                      .includes(t)
                                          );
                                      const teachersToRemove = props.currentClass?.teachers
                                          ?.map((t) => t.id)
                                          .filter((t) => !currentTeachers?.map((teacher) => teacher.id).includes(t));

                                      !isEmpty(teachersToRemove) &&
                                          (await Classes.removeUsers({
                                              classId: props.currentClass?.classId,
                                              userId: teachersToRemove,
                                          }).then(() =>
                                              props.updateClassUsersInState({
                                                  users: props.currentClass?.teachers.filter((t) =>
                                                      teachersToRemove.includes(t.id)
                                                  ),
                                                  classId: values.classId,
                                                  actionType: 'remove',
                                              })
                                          ));

                                      !isEmpty(teachersToAdd) &&
                                          (await Classes.addUsers({
                                              classId: props.currentClass?.classId,
                                              userId: teachersToAdd,
                                          }).then(() =>
                                              props.updateClassUsersInState({
                                                  users: schoolTeachers.filter((t) => teachersToAdd.includes(t.id)),
                                                  classId: values.classId,
                                                  actionType: 'add',
                                              })
                                          ));

                                      props.toggleModal('', false);
                                  }
                              )
                            : props.handleSubmit();
                    }}
                >
                    {edit ? 'Update Class' : 'Create New Class'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// Pull used redux state into props
const mapStateToProps = (state) => {
    return {
        currentClass: state.classes?.classes?.find((c) => c.classId === state?.classes?.currentClass?.classId),
        auth: state.auth,
        ui: state.ui,
    };
};

// Setup dispatch for redux actions and set as functions on props
const mapDispatchToProps = (dispatch) => {
    return {
        onCreateClass: (data, history, modalState) => dispatch(createClass(data, history, modalState)),
        onEditClass: (data) => dispatch(editClass(data)),
        onDeleteClass: (classId, teacherId) => dispatch(deleteClass(classId, teacherId)),
        toggleModal: (name, state) => dispatch(toggleModal(name, state)),
        updateClassUsersInState: ({ users, classId, actionType }) =>
            dispatch(updateClassUsersInState({ users, classId, actionType })),
    };
};

// Export component connected to redux, including the Formik and Yup validation. Connect is called outside of Fromik to allow redux state to be used in Formik.
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        withFormik({
            mapPropsToValues({ edit, currentClass, isRollover }) {
                return {
                    className: (edit && currentClass && currentClass?.className) || '',
                    classId: (edit && currentClass && currentClass?.classId) || null,
                };
            },
            validationSchema: YupObject().shape({
                className: YupString()
                    .required('Class name is required')
                    .max(30, 'Class name must be at most 30 characters'),
            }),
            handleSubmit(values, { props }) {
                //GA custom event for create or edit class
                ReactGA.event({
                    category: CATEGORIES.TEACHER_ACTIONS,
                    action: props.edit ? EVENTS.EDIT_CLASS : EVENTS.CREATE_CLASS,
                });

                !props.edit &&
                    props
                        .onCreateClass(values, props.history, props.keepOpenOnSubmit)
                        .then(() => props.toggleModal('', false));
            },
            enableReinitialize: true,
        })(ClassDetailsForm)
    )
);
