import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Drawer, useMediaQuery, Divider, Avatar, Menu, MenuItem } from '@mui/material';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { theme } from 'assets/customisedMUI';

import { toggleModal, setHeaderLabel } from 'store/actions/uiActions';
import { toggleDeveloperMode } from 'store/actions/developerActions';
import { useSideBarOptions } from 'hooks';
import OrderedClassList from 'components/Navigation/Sidebar/OrderedClassList';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ClassDetailsForm from 'components/Classes/ClassDetailsForm';
import { ampli } from 'ampli';
import { useUserTenants } from 'hooks';
import axios from 'config/axios';

const Sidebar = (props) => {
    const screenSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { open, onShowModal, history, setHeaderLabel, location, hasClass, developer, toggleDeveloperMode } = props;
    const [sidebarState, actions] = useSideBarOptions();
    const [menuAnchor, setMenuAnchor] = useState(null);
    const isDeveloperMode = developer.developerMode;
    window.lsgo.registerLsgoFunction('developerMode', toggleDeveloperMode);
    if (!sidebarState.loaded) {
        actions.load();
    }
    const { sidebarOptions = [] } = sidebarState;
    const tenants = useUserTenants();

    const [openSubMenu, setOpenSubMenu] = useState(null);

    const dashboardOption = sidebarOptions.find((o) => o.id === 'dashboard')?.options[0];
    const reportingOption = sidebarOptions.find((o) => o.id === 'reporting')?.options[0];
    const adminResourceOption = sidebarOptions.find((o) => o.id === 'admin-resources')?.options[0];

    const handleSidebarMenu = (menu) => {
        setOpenSubMenu(openSubMenu === menu ? null : menu);
    };

    const closeMenu = () => {
        setMenuAnchor(null);
    };

    const handleMenuItemClick = async (item) => {
        await axios.post(`/api/v4/auth/login/audience/${item.schoolId}`);
        window.location.reload();
    };

    useEffect(() => {
        let currentView = null;

        sidebarOptions.forEach((option) => {
            if (currentView) return;
            option.options.forEach((menuItem) => {
                if (currentView) return;
                if (menuItem.options) {
                    menuItem.options.forEach((subItem) => {
                        if (currentView) return;
                        if (subItem.link === location.pathname) {
                            currentView = subItem;
                        }
                    });
                } else {
                    if (menuItem.link === location.pathname) {
                        currentView = menuItem;
                        currentView = {
                            ...currentView,
                            title: menuItem.title
                                ? `${option.section ? `${option.section} - ` : ''}${menuItem.title}`
                                : null,
                        };
                    }
                }
            });
        });
        setHeaderLabel(currentView ? currentView.title : null);
    }, [location, sidebarOptions]);

    const handleSidebarMenuItem = (menuItem) => {
        ampli.buttonClicked({ button_name: menuItem.title, location: 'Sidebar - v2' });
        console.log(menuItem);
        if (menuItem.link) {
            if (menuItem.type) {
                if (menuItem.type === 'video')
                    props.onShowModal(true, 'video_activity_modal', { video: menuItem.meta });
                else window.open(menuItem.link, '_blank');
            } else {
                history.push(menuItem.link);
            }
        } else if (menuItem.action) props[menuItem.action]();
        !props.defaultOpen && props.setOpen(false);
    };

    const drawerProps = {
        variant: 'persistent',
        open: open,

        ModalProps: screenSmall
            ? {
                  keepMounted: true, // Better open performance on mobile.
              }
            : {},
    };

    const drawerList = (
        <div
            className={`drawer-menu ${props.ui.headerLabel && !props.ui.isAdminView ? 'single-level' : ''} ${
                !hasClass ? 'no-class' : ''
            } ${props.teacher && props.teacher.alias ? 'alias' : ''} hidden-print`}
        >
            <TreeView data-pagename='SIDEBAR'>
                <div
                    className={`sidebar-option`}
                    style={{
                        fontFamily: 'Imprima',
                    }}
                >
                    {tenants.length > 1 ? (
                        <button
                            onClick={(e) => {
                                setMenuAnchor(e.currentTarget);
                            }}
                        >
                            <div className='col'>
                                {props.teacher &&
                                    props.teacher.groups &&
                                    props.teacher.groups &&
                                    props.teacher.groups.nickname}
                                <i className='fas fa-exchange-alt ml-2' />
                            </div>
                        </button>
                    ) : props.teacher && props.teacher.groups && props.teacher.groups ? (
                        props.teacher.groups.nickname
                    ) : (
                        ''
                    )}
                </div>
                <Divider className='sidebar-divider pull-up pull-down' />
                {dashboardOption && (
                    <div
                        className={`sidebar-option clickable header small ${
                            dashboardOption.link === location.pathname ? 'selected' : ''
                        }`}
                        style={{
                            fontFamily: 'Imprima',
                        }}
                        onClick={() => {
                            ampli.buttonClicked({ button_name: dashboardOption.title, location: 'Sidebar - v2' });
                            history.push(dashboardOption.link);
                        }}
                    >
                        {dashboardOption.title}
                    </div>
                )}
                {reportingOption && (
                    <div
                        className={`sidebar-option clickable header small ${
                            reportingOption.link === location.pathname ? 'selected' : ''
                        }`}
                        style={{
                            fontFamily: 'Imprima',
                        }}
                        onClick={() => {
                            ampli.buttonClicked({ button_name: 'School Reports', location: 'Sidebar - v2' });
                            history.push(reportingOption.link);
                        }}
                    >
                        {reportingOption.title}
                    </div>
                )}
                {adminResourceOption && (
                    <div className='sidebar-expand-wrapper'>
                        <div
                            className={`sidebar-option clickable small ${
                                openSubMenu === adminResourceOption.title ? 'selected' : ''
                            }`}
                            style={{
                                fontFamily: 'Imprima',
                            }}
                            onClick={() => {
                                handleSidebarMenu(adminResourceOption.title);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') handleSidebarMenu(adminResourceOption.title);
                            }}
                            role='button'
                            aria-expanded={openSubMenu === adminResourceOption.title ? 'true' : 'false'}
                        >
                            {adminResourceOption.icon && (
                                <div className='class-logo'>
                                    <i className={adminResourceOption.icon} />
                                </div>
                            )}
                            <div className='class-label'>{adminResourceOption.title}</div>
                            <div className='sidebar-expand'>
                                <i
                                    className={`${
                                        openSubMenu === adminResourceOption.title
                                            ? 'fas fa-chevron-up'
                                            : 'fas fa-chevron-down'
                                    }`}
                                />
                            </div>
                        </div>
                        <div className={`sidebar-submenu ${openSubMenu === adminResourceOption.title ? 'open' : ''}`}>
                            {adminResourceOption.options.map((subItem) => {
                                if (subItem.hidden) {
                                    return null;
                                }
                                return (
                                    <div
                                        className={`sidebar-option small ${
                                            subItem.link === location.pathname ? 'selected' : ''
                                        } ${subItem.disabled ? 'disabled' : 'clickable'}`}
                                        key={subItem.title}
                                        role='button'
                                        tabIndex={0}
                                        onClick={() => {
                                            !subItem.disabled && handleSidebarMenuItem(subItem);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') !subItem.disabled && handleSidebarMenuItem(subItem);
                                        }}
                                    >
                                        <div className='class-label' style={{ paddingLeft: subItem.type ? '5px' : '' }}>
                                            {subItem.title}
                                        </div>
                                        {subItem.type && (
                                            <div className='end-icon'>
                                                <i className={`fas fa-${subItem.type}`} />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}

                <Divider className='sidebar-divider  pull-up pull-down' />

                <div key={`section-classes`} className='sidebar-option text-center'>
                    My Classes
                </div>

                <DndProvider backend={HTML5Backend} key={1}>
                    <OrderedClassList {...props} isClassDashboard={location.pathname === '/teacher'} />
                </DndProvider>
                <Divider className='sidebar-divider  pull-up pull-down' />
                <div
                    className={`sidebar-option clickable class-create ${!hasClass ? '' : ''}`}
                    role='button'
                    tabIndex={0}
                    onClick={() => {
                        ampli.buttonClicked({ button_name: 'create_class', location: 'Sidebar' });
                        onShowModal(true, 'newClass');
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') onShowModal(true, 'newClass');
                    }}
                >
                    <div className='class-logo'>
                        <Avatar variant='circular' alt={'3P'} className='outlined'>
                            <i className='fas fa-plus'></i>
                        </Avatar>
                    </div>
                    <div className={`class-label `}>
                        <p className='text'>Create class</p>
                    </div>
                </div>
                <Divider className='sidebar-divider pull-up' />

                {sidebarOptions.map((option, index) => {
                    const dividerShow =
                        index !== sidebarOptions.length - 1 &&
                        sidebarOptions[index + 1].options.find((op) => !op.hidden);
                    return (
                        <>
                            {option.section && (
                                <div key={`section-${option.section}`} className='sidebar-option small title'>
                                    {option.section}
                                </div>
                            )}
                            {Array.isArray(option.options)
                                ? option.options.map((item) => {
                                      let menuItem = item;
                                      if (props.ui.isAdminView && item.admin) {
                                          menuItem = item.admin;
                                      }
                                      if (menuItem.hidden) {
                                          return null;
                                      }
                                      return Array.isArray(menuItem.options) ? (
                                          <div className='sidebar-expand-wrapper' key={menuItem.title}>
                                              <div
                                                  className={`sidebar-option small clickable`}
                                                  onClick={() => {
                                                      handleSidebarMenu(menuItem.title);
                                                  }}
                                                  onKeyDown={(e) => {
                                                      if (e.key === 'Enter') handleSidebarMenu(menuItem.title);
                                                  }}
                                                  role='button'
                                                  aria-expanded={openSubMenu === menuItem.title ? 'true' : 'false'}
                                                  tabIndex={0}
                                              >
                                                  {menuItem.icon && (
                                                      <div className='class-logo'>
                                                          <i className={menuItem.icon} />
                                                      </div>
                                                  )}
                                                  <div className='class-label'>{menuItem.title}</div>
                                                  <div className='sidebar-expand'>
                                                      <i
                                                          className={`${
                                                              openSubMenu === menuItem.title
                                                                  ? 'fas fa-chevron-up'
                                                                  : 'fas fa-chevron-down'
                                                          }`}
                                                      />
                                                  </div>
                                              </div>
                                              <div
                                                  className={`sidebar-submenu ${
                                                      openSubMenu === menuItem.title ? 'open' : ''
                                                  }`}
                                              >
                                                  {menuItem.options.map((subItem) => {
                                                      if (subItem.hidden) {
                                                          return null;
                                                      }
                                                      return (
                                                          <div
                                                              className={`sidebar-option small clickable ${
                                                                  subItem.link === location.pathname ? 'selected' : ''
                                                              }`}
                                                              key={subItem.title}
                                                              role='button'
                                                              tabIndex={0}
                                                              onClick={() => {
                                                                  handleSidebarMenuItem(subItem);
                                                              }}
                                                              onKeyDown={(e) => {
                                                                  if (e.key === 'Enter') handleSidebarMenuItem(subItem);
                                                              }}
                                                          >
                                                              <div
                                                                  className='class-label'
                                                                  style={{ paddingLeft: subItem.type ? '5px' : '' }}
                                                              >
                                                                  {subItem.title}
                                                              </div>
                                                              {subItem.type && (
                                                                  <div className='end-icon'>
                                                                      <i className={`fas fa-${subItem.type}`} />
                                                                  </div>
                                                              )}
                                                          </div>
                                                      );
                                                  })}
                                              </div>
                                          </div>
                                      ) : (
                                          <div
                                              className={`sidebar-option small clickable ${
                                                  menuItem.link === location.pathname ? 'selected' : ''
                                              }`}
                                              role='button'
                                              tabIndex={0}
                                              onClick={() => {
                                                  handleSidebarMenuItem(menuItem);
                                              }}
                                              onKeyDown={(e) => {
                                                  if (e.key === 'Enter') handleSidebarMenuItem(menuItem);
                                              }}
                                              key={menuItem.title}
                                          >
                                              {menuItem.icon && (
                                                  <div className='class-logo'>
                                                      <i className={menuItem.icon} />
                                                  </div>
                                              )}
                                              <div
                                                  className='class-label'
                                                  style={{ paddingLeft: menuItem.type ? '5px' : '' }}
                                              >
                                                  {menuItem.title}
                                              </div>
                                              {menuItem.type && (
                                                  <div className='end-icon'>
                                                      <i className={`fas fa-${menuItem.type}`} />
                                                  </div>
                                              )}
                                          </div>
                                      );
                                  })
                                : null}
                            {dividerShow && <Divider className='sidebar-divider' />}
                        </>
                    );
                })}
                <Divider className='sidebar-divider' />
                <div key={`section-contact-us`} className='sidebar-option small title'>
                    Contact Us
                </div>
                <div
                    className={`sidebar-option small clickable`}
                    key={'contact-email'}
                    role='button'
                    onClick={() => {
                        window.open('mailto:support@lifeskillsgroup.com.au');
                    }}
                    onKeyDown={(e) => {
                        window.open('mailto:support@lifeskillsgroup.com.au');
                    }}
                >
                    <div className='class-logo'>
                        <i className={'fas fa-envelope'} />
                    </div>
                    <div className='class-label' style={{ paddingLeft: '0' }}>
                        Email Support
                    </div>
                </div>
                <div
                    className={`sidebar-option small clickable`}
                    key={'contact-form'}
                    role='button'
                    onClick={() => {
                        window.open('https://www.lifeskillsgroup.com.au/contact', '_blank');
                    }}
                    onKeyDown={(e) => {
                        window.open('https://www.lifeskillsgroup.com.au/contact', '_blank');
                    }}
                >
                    <div className='class-logo'>
                        <i className={'fas fa-question-circle'} />
                    </div>
                    <div className='class-label' style={{ paddingLeft: '0' }}>
                        Request Support
                    </div>
                </div>
                <div
                    className={`sidebar-option small clickable`}
                    key={'contact-phone'}
                    role='button'
                    onClick={() => {
                        window.open('tel:+1300908051');
                    }}
                    onKeyDown={(e) => {
                        window.open('tel:+1300908051');
                    }}
                >
                    <div className='class-logo'>
                        <i className={'fas fa-phone'} />
                    </div>
                    <div className='class-label' style={{ paddingLeft: '0' }}>
                        Phone Support
                    </div>
                </div>
                <div key={`section-phone-typed`} className='sidebar-option small'>
                    1300 908 051
                </div>
                {isDeveloperMode && (
                    <>
                        <Divider className='sidebar-divider' />
                        <div key={`section-contact-us`} className='sidebar-option small title'>
                            Developer Options
                        </div>
                        <div
                            className={`sidebar-option small clickable`}
                            key={'contact-email'}
                            role='button'
                            onClick={() => history.push('/developer/duplicates')}
                            onKeyDown={(e) => history.push('/developer/duplicates')}
                        >
                            <div className='class-logo'>
                                <i className={'fas fa-clone'} />
                            </div>
                            <div className='class-label' style={{ paddingLeft: '0' }}>
                                Compare Duplicates
                            </div>
                        </div>
                    </>
                )}
            </TreeView>
            {props.ui.showModal &&
                (props.ui.dashboardModal === 'newClass' || props.ui.dashboardModal === 'editClass') && (
                    <ClassDetailsForm
                        schoolId={props.auth.teacher.user_groups.schoolId}
                        edit={props.ui.dashboardModal === 'editClass'}
                        closeModal={() => props.onShowModal(false, '')}
                    />
                )}
            <Menu
                className='header-menu'
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={closeMenu}
                variant='menu'
            >
                {tenants.map((item) => {
                    return (
                        <MenuItem
                            onClick={() => {
                                handleMenuItemClick(item);
                                closeMenu();
                            }}
                        >
                            {item.schoolName}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );

    return <Drawer {...drawerProps}>{drawerList}</Drawer>;
};

const mapDispatchToProps = (dispatch) => {
    return {
        onShowModal: (modalState, modalName, meta) => dispatch(toggleModal(modalState, modalName, meta)),
        setHeaderLabel: (headerLabel) => dispatch(setHeaderLabel(headerLabel)),
        toggleDeveloperMode: () => dispatch(toggleDeveloperMode),
    };
};

const mapStateToProps = (state) => {
    return {
        classes: state.classes.classes,
        hasClass: state.classes.classes && state.classes.classes.length > 0,
        currentClass: state.classes.currentClass,
        ui: state.ui,
        auth: state.auth,
        developer: state.developer,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
