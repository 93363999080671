import Axios from 'config/axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConstant } from 'store/actions/constantAction';

const url = '/api/v4/schools/tenants';

let loading = false;

const useUserTenants = () => {
    const TENANTS = useSelector((state) => state.constants.tenants);
    const dispatch = useDispatch();

    const [userTenants, setUserTenants] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (TENANTS) {
                setUserTenants(TENANTS);
            } else {
                if (loading) return;
                loading = true;
                const response = await Axios.get(url);
                dispatch(
                    updateConstant(
                        'tenants',
                        response.data.map((school) => ({ ...school, ...school.AllSchool, AllSchool: null }))
                    )
                );
                setUserTenants(response.data);
                loading = false;
            }
        };

        fetchData();
    }, [TENANTS, dispatch]);

    return userTenants;
};

export default useUserTenants;
