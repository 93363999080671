import moment from 'moment';
import { Buffer } from 'buffer';
import AXIOS from 'axios';
import axios from 'config/axios';
const fabric = require('fabric').fabric;

export const studentGrade = (grade) => {
    return Math.floor((grade + 1) / 2);
};

export const getArrayOfUniqueObjects = (arr, uniqueIdentifier) => {
    const result = [];
    const map = new Map();
    for (const item of arr) {
        if (!map.has(item[uniqueIdentifier])) {
            map.set(item[uniqueIdentifier], true); // set any value to Map
            result.push(item);
        }
    }
    return result;
};

export const getDaysArray = ({ startDate, endDate, range, lessonSessions = null, frequency }) => {
    let start = moment().startOf('day');
    let end = moment().endOf('day');

    switch (range) {
        case 'daily': {
            break;
        }
        case 'seven_days': {
            start = moment().startOf('day').subtract(7, 'days');
            end = moment().endOf('day');
            break;
        }
        case 'fourteen_days': {
            start = moment().startOf('day').subtract(14, 'days');
            end = moment().endOf('day');
            break;
        }
        case 'thirty_days': {
            start = moment().startOf('day').subtract(30, 'days');
            end = moment().endOf('day');
            break;
        }
        case 'ninety_days': {
            start = moment().startOf('day').subtract(90, 'days');
            end = moment().endOf('day');
            break;
        }
        case 'custom': {
            start = moment(startDate).startOf('day');
            end = moment(endDate).endOf('day');
            break;
        }
        default: {
            start = moment(startDate).startOf('day');
            end = moment(endDate).endOf('day');
            break;
        }
    }
    let arr = [];
    if (['weekly', 'monthly'].includes(frequency)) {
        if (frequency === 'monthly') {
            if (start <= end) {
                let interim = start.clone();

                while (end > interim || interim.format('M') === end.format('M')) {
                    arr.push(interim.format('MMMM, YYYY'));
                    interim.add(1, 'month');
                }
            }
        }
        if (frequency === 'weekly') {
            if (start <= end) {
                let interim = start.clone();

                while (end > interim || interim.weeks() === end.weeks()) {
                    arr.push(new Date(moment(interim.startOf('week').format('MM/DD/YYYY'))));
                    interim.add(7, 'days');
                }
            }
        }
    } else {
        for (var dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            if (lessonSessions)
                for (var session of lessonSessions) {
                    arr.push(new Date(dt.setHours(session)));
                }
            else arr.push(new Date(dt));
        }
    }
    return arr;
};

export const dayCollections = {
    default: {
        label: 'Default',
        id: 'default',
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    },
    seven_days: {
        start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
        label: 'Last 7 days',
        id: 'seven_days',
    },
    fourteen_days: {
        start: moment().subtract(13, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
        label: 'Last 14 days',
        id: 'fourteen_days',
    },
    thirty_days: {
        start: moment().subtract(29, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
        label: 'Last 30 days',
        id: 'thirty_days',
    },
    ninety_days: {
        start: moment().subtract(89, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
        label: 'Last 90 days',
        id: 'ninety_days',
    },
    custom: { start: null, end: null, label: 'Custom', id: 'custom' },
};

export const b64toBlob = (
    b64Data,
    { sliceSize, type } = { sliceSize: 512, type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
) => {
    const byteCharacters = Buffer.from(b64Data, 'base64').toString('binary');
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
        type,
    });
    return blob;
};
export const generateImageFromJSON = async (jsonData) => {
    return await new Promise((resolve, reject) => {
        try {
            var canvas = new fabric.Canvas('paper', { isDrawingMode: false });
            let newJson = JSON.parse(jsonData);

            newJson.objects = newJson.objects.map((obj) => {
                if (obj.type === 'image') {
                    obj.crossOrigin = 'anonymous';
                    return obj;
                }
                return obj;
            });
            newJson = JSON.stringify(newJson);
            canvas.loadFromJSON(newJson, function () {
                canvas.renderAll();
                const width =
                    canvas?._objects[0]?.width <= 0 ? 1000 : Math.ceil(canvas?._objects[0]?.width / 2000) * 2000;
                const height =
                    canvas?._objects[0]?.height <= 0 ? 1000 : Math.ceil(canvas?._objects[0]?.height / 2000) * 2000;
                canvas.setWidth(width);
                canvas.setHeight(height);
                canvas.setBackgroundColor('#fff');
                resolve(canvas.toDataURL('image/png'));
            });
        } catch (e) {
            reject(e);
        }
    });
};

export const getSignedUrl = async (record) => {
    let canvasData = '';
    const signedUrl = await axios.get(`/api/v4/users/signed-url/${record.userId}/${record.data.drawing}`);

    if (record?.data?.isJournal) {
        const returnData = await AXIOS({
            url: signedUrl.data,
            method: 'GET',
        });
        canvasData = JSON.stringify(returnData.data);

        return { canvasData, isJournal: true };
    } else {
        canvasData = signedUrl.data;

        return { canvasData, isJournal: false };
    }
};
