import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { AppBar, IconButton, Typography, Avatar, Badge, Menu, MenuItem, Button } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Alert } from '@mui/material';

import { StudentAppLink } from '@lsgo/lsgo-fe';

import { connect } from 'react-redux';
import { logOutTeacher } from 'store/actions/authActions';
import { Link } from 'react-router-dom';
import NavTabs from 'components/Navigation/Sidebar/NavTabs';
import PlatformNotifications from 'components/Navigation/Sidebar/PlatformNotifications';
import { logOutAlias } from 'store/actions/authActions';
import { teacherAppLink } from 'utils';

import clsx from 'clsx';
import { emitTrackingEvent } from 'utils/clientSocket';
import theme from 'assets/customisedMUI/theme';

/**-------------------- IMAGE ASSETS --------------------**/
import logo from 'assets/images/logo_long_horizontal.svg';
import { ampli } from 'ampli';

const menus = {
    profile: [
        {
            title: 'Profile',
            link: '/teacher/profile',
        },
        { title: 'Logout', action: 'onLogOutTeacher' },
    ],
};

const Header = (props) => {
    // const classes = useStyles();
    const { history } = props;
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [menu, setMenu] = useState('profile');

    const closeMenu = () => {
        setMenuAnchor(null);
    };

    const handleMenuItemClick = (menuItem) => {
        ampli.buttonClicked({ button_name: menuItem.title, location: 'Top Navigation Bar' });
        if (menuItem.link) {
            if (menuItem.type && menuItem.type !== 'appcues') {
                window.open(menuItem.link, '_blank');
            } else if (menuItem.type === 'appcues') {
                window.location.href =
                    menuItem.link[Object.keys(menuItem.link).find((key) => key === props.location.pathname)];
            } else {
                window.location.href = menuItem.link;
            }
        } else if (menuItem.action) props[menuItem.action]();
    };

    return (
        <div className='hidden-print'>
            <AppBar
                id='lsgo-header'
                position='fixed'
                className={clsx({
                    'header-level-one': true,
                    alias: props.profile.alias,
                })}
                sx={{
                    zIndex: theme.zIndex.drawer + 1,
                    transition: theme.transitions.create(['width', 'margin'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    backgroundColor: theme.palette.blue.main,
                }}
            >
                {props.profile && props.profile.alias && (
                    <Typography
                        id='demo-notification'
                        variant='body1'
                        component='div'
                        display='block'
                        className='demo-info-block p-2'
                        style={{
                            width: '100%',
                            fontFamily: 'Museo700',
                            textAlign: 'center',
                            fontSize: 12,
                            backgroundColor: 'yellow',
                            color: 'rgba(0, 0, 0, 0.87)',
                        }}
                    >
                        You are viewing Life Skills GO as {props.profile.name}. To return to your dashboard click{' '}
                        <button
                            onClick={async () => {
                                await props.logOutAlias({ redirect: true });
                            }}
                            style={{
                                background: 'none !important',
                                border: 'none',
                                padding: '0 !important',
                                color: '#069',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            here
                        </button>
                    </Typography>
                )}
                {props.adminClassView && props.adminClassView.status && (
                    <Typography
                        id='demo-notification'
                        variant='body1'
                        component='div'
                        display='block'
                        className='demo-info-block p-2'
                        style={{
                            width: '100%',
                            fontFamily: 'Museo700',
                            textAlign: 'center',
                            fontSize: 12,
                            backgroundColor: 'yellow',
                            color: 'rgba(0, 0, 0, 0.87)',
                        }}
                    >
                        You are viewing Class: {props.adminClassView.className}. To return to your dashboard click{' '}
                        <button
                            onClick={async () => {
                                window.location.href = '/';
                            }}
                            style={{
                                background: 'none !important',
                                border: 'none',
                                padding: '0 !important',
                                color: '#069',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            here
                        </button>
                    </Typography>
                )}
                <div className='app-bar-container'>
                    <div className='app-bar-left'>
                        <div className='button-container'>
                            <div className='button-box'>
                                <div className='button-box-internal'>
                                    {!props.externalLayout && (
                                        <IconButton
                                            aria-label='open drawer'
                                            onClick={() => {
                                                props.setOpen(!props.open);
                                                emitTrackingEvent({
                                                    area: 'SIDEBAR',
                                                    action: !props.open ? 'EXPAND' : 'CONTRACT',
                                                });
                                            }}
                                            edge='start'
                                            className='menu-bars'
                                            data-pagename='SIDEBAR'
                                            data-objectname='EXPAND'
                                        >
                                            <i className='fas fa-bars' />
                                        </IconButton>
                                    )}
                                </div>
                            </div>

                            {props.externalLayout ? (
                                <div className='logo-header-empty'>
                                    <img
                                        src={logo}
                                        className='logo-header-img hidden-print'
                                        alt='Life Skills GO'
                                        height={'22px'}
                                    />
                                </div>
                            ) : (
                                <Link
                                    to={props.profile.groups.roleId === 3 ? '/school/admin/exec-report' : '/teacher'}
                                    title='LSGO'
                                    data-pagename='SIDEBAR'
                                    data-objectname='DASHBOARD_LOGO'
                                    className='logo-header'
                                >
                                    <img src={logo} className='logo-header-img hidden-print' alt='Life Skills GO' />
                                </Link>
                            )}

                            {!props.externalLayout && (
                                <div className='header-class-text'>
                                    <Typography variant='h6'>
                                        {props.headerLabel
                                            ? props.headerLabel
                                            : props.currentClass && props.currentClass.className}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='app-bar-center'>
                        {!props.externalLayout && (
                            <NavTabs hidden={props.headerLabel} hasClass={props.hasClass} scrollable />
                        )}
                    </div>
                    <div className='app-bar-right'>
                        <div className='help-wrapper'>
                            <div>
                                <div className='help-icon'>
                                    <Button
                                        edge='start'
                                        className='menu-help'
                                        data-pagename='SIDEBAR'
                                        data-objectname='HELP'
                                        onClick={(e) => {
                                            props.externalLayout
                                                ? history.push(
                                                      `/${
                                                          props.location.pathname.includes('sentral')
                                                              ? 'sentral'
                                                              : props.location.pathname.includes('schoolBytes')
                                                              ? 'schoolBytes'
                                                              : 'teacher/classes/dashboard'
                                                      }/getting-started/?source=${encodeURIComponent(
                                                          props.history.location.pathname
                                                      )}`
                                                  )
                                                : history.push('/teacher/classes/dashboard/getting-started');
                                        }}
                                        style={{
                                            marginRight: 10,
                                            marginTop: 0,
                                            minWidth: 120,
                                            backgroundColor: 'hsl(31, 93%, 54%)',
                                        }}
                                    >
                                        <Typography style={{ color: 'white', fontSize: 16, fontFamily: 'Museo700' }}>
                                            How to... <i className='fas fa-question-circle' />
                                        </Typography>
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className='profile-options'>
                            <div>
                                <div className='profile-option-container'>
                                    <div className='profile-option-inner'>
                                        <div className='option-row'>
                                            <div>
                                                <PlatformNotifications />
                                            </div>
                                            {!props.externalLayout && (
                                                <Badge
                                                    overlap='circular'
                                                    variant='dot'
                                                    aria-controls='simple-menu'
                                                    aria-haspopup='true'
                                                    style={{ color: '#fff', textTransform: 'none' }}
                                                    id='notification-bell-icon'
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    className={'user-profile'}
                                                    classes={{
                                                        badge: props.connectedSockets.includes(
                                                            String(props.currentClass && props.currentClass.classId)
                                                        )
                                                            ? { backgroundColor: '#00a000' }
                                                            : null,
                                                    }}
                                                >
                                                    <Avatar
                                                        onClick={(e) => {
                                                            setMenu('profile');
                                                            setMenuAnchor(e.currentTarget);
                                                        }}
                                                        variant='circular'
                                                        alt={props.profile && props.profile.name}
                                                        className={
                                                            ['blue', 'brown', 'red'][
                                                                props.profile &&
                                                                    props.profile.name &&
                                                                    props.profile.name
                                                                        .split(' ')
                                                                        .map((substring) => substring[0])
                                                                        .join('')[0]
                                                                        .toUpperCase()
                                                                        .charCodeAt(0) % 3
                                                            ]
                                                        }
                                                    >
                                                        {props.profile &&
                                                            props.profile.name &&
                                                            props.profile.name
                                                                .split(' ')
                                                                .map((substring) => substring[0])
                                                                .join('')}
                                                    </Avatar>
                                                </Badge>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AppBar>
            {props.hasClass && !props.externalLayout && (
                <AppBar
                    id='lsgo-header'
                    position='fixed'
                    className={clsx({
                        'header-level-two': true,
                        hidden: props.headerLabel ? true : false,
                        alias: props.profile.alias,
                    })}
                    sx={{
                        zIndex: theme.zIndex.drawer + 1,
                        transition: theme.transitions.create(['width', 'margin'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                        backgroundColor: theme.palette.blue.main,
                    }}
                >
                    <div className='app-bar-center hidden-print'>
                        <NavTabs scrollable hidden={props.headerLabel} hasClass={props.hasClass} />
                    </div>
                </AppBar>
            )}

            <Menu
                className='header-menu'
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={closeMenu}
                variant='menu'
            >
                {menus[menu].map((item) => {
                    if (item.type === 'appcues' && !Object.keys(item.link).includes(props.location.pathname))
                        return null;
                    return (
                        <MenuItem
                            onClick={() => {
                                handleMenuItemClick(item);
                                closeMenu();
                            }}
                        >
                            {item.title} <i className={`fas fa-${item.type}`} style={{ fontSize: 12, marginLeft: 8 }} />
                        </MenuItem>
                    );
                })}
            </Menu>
            <Dialog
                maxWidth='sm'
                className='badges-dialog'
                open={props.profile.id && props.profile.userType !== 'teacher'}
                fullWidth={true}
                fullScreen={false}
            >
                <DialogTitle textAlign='center'>You are logged in as a student</DialogTitle>
                <DialogContent>
                    <Alert severity='warning'>
                        Student accounts do not have access to the teacher platform. If you are a student you can{' '}
                        <a href={`${StudentAppLink(window.location)}`}>
                            access the student application by clicking here.
                        </a>{' '}
                        {`${
                            props.profile.alias
                                ? 'If you are a teacher that has logged in as a student, use the button below to return to your teacher profile. '
                                : ' '
                        }`}
                        Alternatively, please logout of your account.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onLogOutTeacher}>Logout</Button>
                    {props.profile.alias && (
                        <Button
                            onClick={() => {
                                props.logOutAlias({ redirect: true });
                            }}
                            variant='contained'
                        >
                            Return to my teacher profile
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.auth.teacher,
        currentClass: state.classes.currentClass,
        headerLabel: state.ui.headerLabel,
        connectedSockets: state.socketCommon.connectedSockets,
        hasClass: state.classes.classes && state.classes.classes.length > 0,
        adminClassView: state.classes && state.classes.adminClassView,
    };
};
const mapDispatchToProps = (dispatch) => {
    return { onLogOutTeacher: () => dispatch(logOutTeacher()), logOutAlias: (data) => dispatch(logOutAlias(data)) };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
