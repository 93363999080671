import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

import './footer.scss';
import Api from 'Api';
import { emitTrackingEvent } from 'utils/clientSocket';

/**
 *@returns JSX
 */
const Footer = (props) => {
    const [release, setRelease] = useState(null);
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();

    useEffect(() => {
        async function fetchVersion() {
            const newRelease = await Api.getVersion();
            setRelease(newRelease);
        }

       // fetchVersion();
    }, []);

    return (
        <footer className={`footer-admin hidden-print`}>
            <Typography variant='body2' className='footer-copy'>
                Copyright <i className='far fa-copyright' /> {currentYear} Life Skills GO{' '}
                <a
                    href='https://help.lifeskillsgroup.com.au/life-skills-go-change-list'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => {
                        emitTrackingEvent({
                            area: 'FOOTER',
                            action: 'VERSION_NUMBER_CHANGELIST',
                            classId: null,
                            params: {},
                        });
                    }}
                >
                    {/* v{release ? release : '0.0.0'} */}
                    v2023.05.01
                </a>
            </Typography>

            <ul className='footer-links'>
                <li className='footer-link'>
                    Contact:
                    <a
                        href={`mailto:${'support@lifeskillsgroup.com.au'}?Subject=Life Skills GO`}
                        onClick={() => {
                            emitTrackingEvent({
                                area: 'FOOTER',
                                action: 'SUPPORT_EMAIL',
                                classId: null,
                                params: {},
                            });
                        }}
                    >
                        <i className='fas fa-envelope ' />
                    </a>
                    <a
                        href='tel:1300908051'
                        onClick={() => {
                            emitTrackingEvent({
                                area: 'FOOTER',
                                action: 'SUPPORT_PHONE',
                                classId: null,
                                params: {},
                            });
                        }}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        <i className='fas fa-phone fa-flip-horizontal ' />
                    </a>
                </li>
                <li className='footer-link'>
                    <a
                        onClick={() => {
                            emitTrackingEvent({
                                area: 'FOOTER',
                                action: 'FACEBOOK_GROUP',
                                classId: null,
                                params: {},
                            });
                        }}
                        href='https://www.facebook.com/groups/GOusers/'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='facebook-social'
                    >
                        User Group <i className='fab fa-facebook-square' />
                    </a>
                </li>
                <li className='footer-link'>
                    <a
                        onClick={() => {
                            emitTrackingEvent({
                                area: 'FOOTER',
                                action: 'TERMS_AND_CONDITIONS',
                                classId: null,
                                params: {},
                            });
                        }}
                        style={{ fontSize: 12 }}
                        href='https://www.lifeskillsgroup.com.au/terms-and-conditions'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Terms and Conditions
                    </a>
                </li>
            </ul>
        </footer>
    );
};

export default Footer;
