import axios from 'config/axios';
import Auth from './Auth';

class Constants {
    static async getGrades() {
        await Auth.checkCurrentAuthToken();
        return await axios.get('/api/v4/constants/grades');
    }

    static async getSidebarOptions() {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/constants/sidebar`);
    }

    static async getCommentCategories() {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/schools/commentCategories`).then((r) => r.data);
    }
}

export default Constants;
