import { Dialog, DialogActions, DialogContent, Button, DialogTitle, Typography } from '@mui/material';
import VideoActivity from 'components/StudentView/VideoActivity';
import { toggleModal } from 'store/actions/uiActions';
import { connect } from 'react-redux';

const VideoPlayer = (props) => {
    const currentVideo = props?.ui?.meta?.video;

    const closedModal = () => {
        props.toggleModal(false, 'video_activity_modal', null);
    };

    return (
        <Dialog fullWidth='true' maxWidth='md' onClose={closedModal} open={currentVideo}>
            <DialogTitle>
                <Typography variant='h6'>{currentVideo && currentVideo.name}</Typography>
            </DialogTitle>
            <DialogContent>
                <div className='video-wrapper'>
                    {currentVideo && (
                        <div className='resource-video-modal' style={{ height: '100%', minHeight: '50vh' }}>
                            <VideoActivity
                                videoUrl={currentVideo.id}
                                context={'resource_section'}
                                style={{ width: '100%', height: '100%', minHeight: '50vh' }}
                            />
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={closedModal}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return { ui: state.ui };
};

const mapDispatchToProps = (dispatch) => {
    return { toggleModal: (modalState, modalName, meta) => dispatch(toggleModal(modalState, modalName, meta)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
