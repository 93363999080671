import React, { Component, Suspense, lazy, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter, useLocation } from 'react-router-dom';
import AdminLayout from './hoc/AdminLayouts/AdminLayout';

import AdminEmptyLayout from './hoc/AdminLayouts/EmptyLayout';
import StudentsLayout from './hoc/StudentsLayouts/EmptyLayout';
import Loader from 'components/UI/Spinner/Spinner';
import ReactGA from 'react-ga4';
import { ID } from 'constants/googleAnalytics';

import setAuthToken from './utils/setAuthToken';
import { parseJwt } from 'utils';
import { logOutTeacher, setCurrentTeacher, handleSSO } from './store/actions/authActions';
import { toggleFeatureFlag, toggleModal } from './store/actions/uiActions';
import { toggleDeveloperMode } from 'store/actions/developerActions';
import store from './store';

// favicon for platform set dynamically
import Favicon from './assets/images/favicons/favicon.ico';
import AppleFavicon from './assets/images/favicons/apple-touch-icon.png';
import Favicon32 from './assets/images/favicons/favicon-32x32.png';

import StudentAlert from 'components/StudentView/StudentAlert';

import moment from 'moment';
import Page404 from 'components/UI/404';

import './GlobalAppStyle.scss';

import Auth from 'Api/Auth';
import Teacher from 'Api/Teachers';

// Event listeners
import { AppCuesEvents } from './events';
import { showServerNotification } from 'store/actions/uiActions';
import { isEmpty, Logger } from '@lsgo/lsgo-common';
import queryString from 'query-string';
import { ampli } from 'ampli';
import { licenseTypes, roleTypes } from 'constants/options';
import ExternalEmptyLayout from 'hoc/ExternalEmptyLayout';
import Users from 'Api/Users';
import axios from 'config/axios';
import { useParams } from 'react-router-dom';

ReactGA.initialize(ID);

const RegisterComponent = lazy(() => import('containers/Auth/Register'));
const Register = (props) => (
    <Suspense fallback={<Loader />}>
        <RegisterComponent {...props} />
    </Suspense>
);

const ForgotPasswordComponent = lazy(() => import('containers/Auth/ForgotPassword'));
const ForgotPassword = (props) => (
    <Suspense fallback={<Loader />}>
        <ForgotPasswordComponent {...props} />
    </Suspense>
);

const ResetPasswordComponent = lazy(() => import('containers/Auth/ResetPassword'));
const ResetPassword = (props) => (
    <Suspense fallback={<Loader />}>
        <ResetPasswordComponent {...props} />
    </Suspense>
);

const ProfileComponent = lazy(() => import('containers/Teacher/Profile'));
const Profile = (props) => (
    <Suspense fallback={<Loader />}>
        <ProfileComponent {...props} />
    </Suspense>
);

const ResourceComponent = lazy(() => import('containers/Resource/Resource'));
const Resource = (props) => (
    <Suspense fallback={<Loader />}>
        <ResourceComponent {...props} />
    </Suspense>
);

const LoginComponent = lazy(() => import('containers/Auth/Login'));
const Login = (props) => (
    <Suspense fallback={<Loader />}>
        <LoginComponent {...props} />
    </Suspense>
);

const QuickWeatherReportComponent = lazy(() => import('containers/WeatherReport/QuickWeatherReport'));
const QuickWeatherReport = (props) => (
    <Suspense fallback={<Loader />}>
        <QuickWeatherReportComponent {...props} />
    </Suspense>
);

const SchoolComponent = lazy(() => import('containers/Schools/School'));
const School = (props) => (
    <Suspense fallback={<Loader />}>
        <SchoolComponent {...props} />
    </Suspense>
);

const MyClassesComponent = lazy(() => import('containers/Classes/MyClasses'));
const MyClasses = (props) => (
    <Suspense fallback={<Loader />}>
        <MyClassesComponent {...props} />
    </Suspense>
);

const DeveloperDashboardComponent = lazy(() => import('containers/Developer/DeveloperDashboard'));
const DeveloperDashboard = (props) => (
    <Suspense fallback={<Loader />}>
        <DeveloperDashboardComponent {...props} />
    </Suspense>
);

const FormsComponent = lazy(() => import('containers/Forms/Forms'));
const Forms = (props) => (
    <Suspense fallback={<Loader />}>
        <FormsComponent {...props} />
    </Suspense>
);

const SchoolAdminComponent = lazy(() => import('routing/SchoolAdmin'));
const SchoolAdmin = (props) => (
    <Suspense fallback={<Loader />}>
        <SchoolAdminComponent {...props} />
    </Suspense>
);

const TeacherWellbeingComponent = lazy(() => import('containers/Resource/TeacherWellbeing'));
const TeacherWellbeing = (props) => (
    <Suspense fallback={<Loader />}>
        <TeacherWellbeingComponent {...props} />
    </Suspense>
);

const RollCallComponent = lazy(() => import('containers/WeatherReport/RollCall'));
const RollCall = (props) => (
    <Suspense fallback={<Loader />}>
        <RollCallComponent {...props} />
    </Suspense>
);

const LeaderBoardComponent = lazy(() => import('containers/Schools/Leaderboard'));
const LeaderBoard = (props) => (
    <Suspense fallback={<Loader />}>
        <LeaderBoardComponent {...props} />
    </Suspense>
);

const GoogleRegistrationComponent = lazy(() => import('containers/Auth/GoogleRegistration'));
const GoogleRegistration = (props) => (
    <Suspense fallback={<Loader />}>
        <GoogleRegistrationComponent {...props} />
    </Suspense>
);

const TrialRegisterComponent = lazy(() => import('containers/Auth/TrialRegister'));
const TrialRegister = () => (
    <Suspense fallback={<Loader />}>
        <TrialRegisterComponent />
    </Suspense>
);

const SentralComponent = lazy(() => import('containers/Sentral/Sentral'));
const Sentral = () => (
    <Suspense fallback={<Loader />}>
        <SentralComponent />
    </Suspense>
);

const SchoolBytesComponent = lazy(() => import('containers/ExternalProviders/SchoolBytes/SchoolBytes'));
const SchoolBytes = () => (
    <Suspense fallback={<Loader />}>
        <SchoolBytesComponent />
    </Suspense>
);

const SentralStudentLayoutComponent = lazy(() => import('containers/Sentral/SentralStudentLayout'));
const SentralStudentLayout = () => (
    <Suspense fallback={<Loader />}>
        <SentralStudentLayoutComponent />
    </Suspense>
);

const SchoolBytesStudentLayoutComponent = lazy(() => import('containers/ExternalProviders/SchoolBytes/StudentsLayout'));
const SchoolBytesStudentLayout = () => (
    <Suspense fallback={<Loader />}>
        <SchoolBytesStudentLayoutComponent />
    </Suspense>
);

const SentralInactiveComponent = lazy(() => import('containers/Sentral/InactiveTrial'));
const InactiveTrial = () => (
    <Suspense fallback={<Loader />}>
        <SentralInactiveComponent />
    </Suspense>
);

const AliasLoggedInComponent = lazy(() => import('containers/Auth/AliasLoggedIn'));
const AliasLoggedIn = () => (
    <Suspense fallback={<Loader />}>
        <AliasLoggedInComponent />
    </Suspense>
);

const ExternalExecComponent = lazy(() => import('containers/Schools/ExecutiveReport'));
const ExternalExec = () => (
    <Suspense fallback={<Loader />}>
        <ExternalExecComponent />
    </Suspense>
);

const OTPComponent = lazy(() => import('components/Auth/OTP'));
const OTP = () => (
    <Suspense fallback={<Loader />}>
        <OTPComponent />
    </Suspense>
);

const TestComp = () => {
    return store.getState().auth.isAuthenticated ? (
        <ExternalExec />
    ) : (
        <div>Request Failed. Cannot find authentication data for HubSpot dashboard.</div>
    );
};

const TestAuthComp = () => {
    // localStorage.removeItem('lsgoRT');
    const { tok } = useParams();
    localStorage.setItem('lsgoRT', tok);
    window.location.href = '/hs/external';
    return <div>Hi</div>;
};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hubspot: false,
            hubspotScript: false,
            logoutWarningTimer: null,
            history: props.history,
            authLoop: true,
        };
        this.loadHubSpot = this.loadHubSpot.bind(this);
        this.handleVideoQuery = this.handleVideoQuery.bind(this);
    }

    static propTypes = {
        location: PropTypes.object.isRequired,
    };

    loadHubSpot() {
        if (
            this.state.hubspot === false &&
            window.location.hostname.includes('lifeskillsgo') &&
            !window.location.href.includes('/forms/')
        ) {
            const script = document.createElement('script');

            script.src = '//js.hs-scripts.com/4596264.js';
            script.async = true;
            script.id = 'hs-script-loader';

            document.body.appendChild(script);

            this.setState({ hubspot: true });
        }
    }

    handleVideoQuery() {
        const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
        if (queryParams['video.id'] && !store.getState().ui.showModal) {
            store.dispatch(
                toggleModal(true, 'video_activity_modal', {
                    video: { id: queryParams['video.id'], name: queryParams['video.name'] },
                })
            );
        }
        if (queryParams['setIsDeveloper']) {
            store.dispatch(toggleDeveloperMode);
        }
    }

    componentDidMount() {
        //Google Analytics for pages
        this.state.history.listen((location, action) => {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        });
        this.handleVideoQuery();

        window.location.hostname.includes('lifeskillsgo') &&
            ampli.load({
                environment: window.location.hostname.includes('staging') ? 'development' : 'production',
            });

        const fetchApiToken = () => {
            // this.setState({ authLoop: true });
            Auth.userV2({
                history: this.state.history,
                redirectUri: this.state.history.location.search,
                lsgoRT: localStorage.getItem('lsgoRT'),
            })
                .then(async (token) => {
                    if (token) {
                        axios.get(`/api/v4/schools/feature-flag/dashboardV5`)?.then((r) => {
                            const accessAllowed = r?.data?.accessAllowed;
                            store.dispatch(toggleFeatureFlag('dashboardV5', true, !!accessAllowed));
                        });
                        const decoded = await parseJwt(token.replace('Bearer ', ''));
                        if (decoded.id) {
                            try {
                                if (
                                    !isEmpty(decoded.tfaEnabled) &&
                                    decoded.tfaEnabled &&
                                    !isEmpty(decoded.tfaAuthenticated) &&
                                    !decoded.tfaAuthenticated
                                ) {
                                    console.log('pushing for tfa');
                                    this.props.history.push('/tfa/verify/');
                                } else {
                                    const profile = await Users.getUserProfile(decoded.id);
                                    ampli.identify(profile.data.id, {
                                        license: licenseTypes[profile.data.groups.licenseId],
                                        role: roleTypes[profile.data.groups.roleId],
                                        payment_status: profile.data.groups.paymentStatus || 'free',
                                        group_id: profile.data.groups.groupId,
                                        school_id: profile.data.schoolId,
                                    });
                                    if (!profile.data.name) profile.data.name = profile.data.firstName;
                                    store.dispatch(setCurrentTeacher({ ...profile.data, alias: decoded.alias }));
                                }
                            } catch (e) {
                                console.log(e);
                            }
                        } else if (store.getState().auth.isAuthenticated) store.dispatch(logOutTeacher());
                    }
                    this.setState({ authLoop: false });
                })
                .catch((err) => this.setState({ authLoop: false }));
        };

        fetchApiToken();

        this.authenticationLoop = setInterval(() => {
            if (store.getState().auth.isAuthenticated) fetchApiToken();
        }, 1.5 * 60 * 60 * 1000); // Refresh token every 1.5 hours

        document.getElementById('favicon').href = Favicon;
        document.getElementById('favicon32').href = Favicon32;
        document.getElementById('faviconApple').href = AppleFavicon;
        const path = this.props.location.pathname;

        //check if admin has logged in as teacher
        if (this.props.location.pathname.indexOf('/teacher/Bearer') !== -1) {
            const token = this.props.location.pathname.split('/')[2];
            // set token
            setAuthToken(token);

            parseJwt(token.replace('Bearer ', '')).then((decoded) => {
                Users.getUserProfile(decoded.id).then((profile) => {
                    if (!profile.data.name) profile.data.name = profile.data.firstName;
                    store.dispatch(setCurrentTeacher({ ...profile.data, alias: decoded.alias }));
                });

                // // redirect to /teacher
                // this.props.history.push('/teacher');

                return;
            });
        }
        if (!path.includes('teacher/register') && !path.includes('teacher/verify')) {
            this.loadHubSpot();
        }
    }

    componentWillUnmount() {
        clearInterval(this.authenticationLoop);
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
        } = this.props;
        const previousLocation = prevProps.location.pathname;

        if (pathname !== previousLocation) {
            window.Appcues && window.Appcues.page();
        }
        const path = this.props.location.pathname;
        if (this.state.hubspot === false && !path.includes('teacher/register') && !path.includes('teacher/verify')) {
            this.loadHubSpot();
        }
        if (this.state.authLoop && store.getState().auth.isAuthenticated) {
            this.setState({ authLoop: false });
        }
    }

    render() {
        return this.state.authLoop ? (
            <Loader />
        ) : (
            <>
                <Switch>
                    <ExternalEmptyLayout embed path='/forms' component={Forms} />
                    <AdminLayout path='/teacher/resource' component={Resource} />
                    <AdminLayout exact path='/teacher/profile' component={Profile} />
                    <AdminLayout path='/teacher/wellbeing' component={TeacherWellbeing} />
                    <AdminLayout exact path='/teacher/leaderboard' component={LeaderBoard} />
                    <AdminEmptyLayout exact path='/' component={Login} />
                    <AdminEmptyLayout exact path='/trial' component={TrialRegister} />
                    <AdminEmptyLayout exact path='/teacher/register/:token' component={Register} />
                    <AdminEmptyLayout exact path='/teacher/googleRegistration' component={GoogleRegistration} />
                    <StudentsLayout
                        exact
                        path='/teacher/quick-weather-report/:classId'
                        component={QuickWeatherReport}
                    />

                    <StudentsLayout path='/teacher/rollcall/:classId' component={RollCall} />

                    <StudentsLayout sentralUnsafeRoute path='/sentral/attendance' component={SentralStudentLayout} />
                    <StudentsLayout sentralUnsafeRoute path='/sentral/checkin' component={SentralStudentLayout} />
                    <ExternalEmptyLayout sentralUnsafeRoute path='/sentral' component={Sentral} />
                    <ExternalEmptyLayout path='/sentral-inactive' component={InactiveTrial} />
                    <ExternalEmptyLayout path='/schoolBytes-inactive' component={InactiveTrial} />
                    <ExternalEmptyLayout hsExternal path='/hs/external' component={TestComp} />
                    <ExternalEmptyLayout hsExternal path='/hs/auth/oneshot/:tok' component={TestAuthComp} />

                    <StudentsLayout
                        schoolBytesUnsafeRoute
                        path='/schoolBytes/attendance'
                        component={SchoolBytesStudentLayout}
                    />
                    <StudentsLayout
                        schoolBytesUnsafeRoute
                        path='/schoolBytes/checkin'
                        component={SchoolBytesStudentLayout}
                    />
                    <ExternalEmptyLayout schoolBytesUnsafeRoute path='/schoolBytes' component={SchoolBytes} />

                    <AdminLayout path='/teacher' component={MyClasses} />
                    <AdminLayout path='/school/admin' component={SchoolAdmin} />
                    <AdminLayout path='/developer' component={DeveloperDashboard} />

                    <AdminEmptyLayout exact path='/users/forgotPassword' component={ForgotPassword} />
                    <AdminEmptyLayout exact path='/users/password/reset/:token' component={ResetPassword} />
                    <AdminEmptyLayout exact path='/tfa/verify/' component={OTP} />
                    <Page404 />
                </Switch>
                <StudentAlert />
                <AppCuesEvents />
            </>
        );
    }
}

export default withRouter(App);
