import { Api, isProd, isStaging } from '@lsgo/lsgo-fe';
import axios from 'axios';

const version = '1.0.0';

export const api = new Api({ location: window.location, application: `lsgo_teacher_application_${version}` });

export const dataApi = axios.create({
    baseURL: window.location.hostname.includes('lifeskillsgo.com')
        ? window.location.hostname.includes('-staging')
            ? 'https://data-staging.lifeskillsgo.com'
            : 'https://data.lifeskillsgo.com'
        : window.location.protocol + '//' + window.location.hostname + ':5001',
    headers: { 'X-TimeZoneOffset': new Date().getTimezoneOffset() * -1 * 60 * 1000 },
});

export default api.axiosInstance;
