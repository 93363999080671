import React from 'react';
import lostGif from 'assets/images/lost.gif';
import logo from 'assets/images/logo.jpg';
import { Link } from 'react-router-dom';

export default function Page404({ error }) {
    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                background: '#ccc url(' + lostGif + ') no-repeat center bottom',
                backgroundSize: 'cover',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
            }}
        >
            <div
                style={{
                    width: '100%',
                    maxWidth: '500px',
                    margin: '0 auto',
                    padding: 80,
                    backgroundColor: 'rgba(204,204,204,0.8)',
                    textAlign: 'center',
                    borderRadius: 4,
                }}
            >
                <div>
                    <img src={logo} style={{ maxHeight: '140px' }} />
                </div>
                {!error ? (
                    <h3>
                        It seems like you are lost. Go back to <Link to='/teacher'>Dashboard</Link>
                    </h3>
                ) : (
                    <h3>
                        Oops. There was an error. Go back to <Link to='/teacher'>Dashboard</Link>.
                    </h3>
                )}
            </div>
        </div>
    );
}
