import React from 'react';
import Button from '@mui/material/Button';
import CustomColors from './CustomColors';
import { extractDataAttributes } from 'utils';

export default function IconButton(props) {
    const dataAttributes = extractDataAttributes(props);
    return (
        <Button
            {...dataAttributes}
            variant={props.variant}
            style={{
                backgroundColor: CustomColors(props.backgroundColor, props.backgroundColorShade),
                color: CustomColors(props.color, props.shade),
                // border: `${props.border} solid ${CustomColors(props.color)}`,
                textTransform: 'none',
                fontFamily: props.font || 'Museo700',
                marginRight: props.marginRight || '10px',
                marginTop: props.marginTop || '8px',
                fontWeight: 'bold',
                float: props.align,
            }}
            className={`lsgo-icon-button lsgo-button-${props.color} ${props.label ? '' : 'icon-only'} ${
                (props.className && props.className) || ''
            }`}
            onClick={props.onClick}
            color={props.inbuiltColor}
            disabled={props.disabled}
        >
            <i className={props.icon} style={{ marginRight: props.label ? 10 : 0 }}></i>
            {props.label}
        </Button>
    );
}
