import {
    TOGGLE_MODAL,
    TOGGLE_STUDENT_OVERLAY,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    CLEAR_ERRORS,
    SET_VISIBLE_UNIT,
    SERVER_NOTIFICATIONS,
    PLATFORM_NOTIFICATIONS,
    OPEN_NOTIFICATION,
    SET_DIALOG_PREVIEW,
    TOGGLE_ADMIN_VIEW,
    SET_HEADER_LABEL,
    FEATURE_FLAG,
} from './actionTypes';
import Notification from 'components/UI/Notification';
import axios from 'config/axios';
import { GET_ERRORS } from './actionTypes';

export const toggleFeatureFlag = (featureLabel, checked, accessAllowed) => {
    return {
        type: FEATURE_FLAG,
        payload: { featureLabel, checked, accessAllowed },
    };
};

export const toggleModal = (modalState, modalName, meta) => {
    return {
        type: TOGGLE_MODAL,
        payload: { showModal: modalState, dashboardModal: modalName, meta },
    };
};

export const toggleStudentOverlay = (overlayState) => {
    return {
        type: TOGGLE_STUDENT_OVERLAY,
        payload: { studentOverlay: overlayState },
    };
};

export const setVisibleUnit = (unitId) => {
    return {
        type: SET_VISIBLE_UNIT,
        payload: { unitId },
    };
};

export const toggleAdminView = (adminView) => {
    return {
        type: TOGGLE_ADMIN_VIEW,
        payload: { adminView },
    };
};

export const setHeaderLabel = (headerLabel) => {
    return {
        type: SET_HEADER_LABEL,
        payload: { headerLabel },
    };
};

export const showNotification = (notificationType, content) => (dispatch) => {
    dispatch(showNotificationTimeOut(notificationType, content));

    setTimeout(() => {
        dispatch(hideNotification());
    }, 8000);
};

export const showServerNotification = (content, children, timeOut, onClick, type) => (dispatch) => {
    dispatch(showServerNotificationTimeOut(content, children, timeOut, onClick, type));
};

export const showServerNotificationTimeOut = (content, children, timeOut, onClick, type) => {
    return {
        type: SERVER_NOTIFICATIONS,
        payload: { content: content, children: children, timeOut, onClick, type },
    };
};

export const showNotificationTimeOut = (notificationType, content) => {
    return {
        type: SHOW_NOTIFICATION,
        payload: { notificationType: notificationType, content: content },
    };
};

export const hideNotification = () => {
    return {
        type: HIDE_NOTIFICATION,
    };
};

export const cleanError = () => {
    return {
        type: CLEAR_ERRORS,
    };
};

export const showPrintingInstruction = () => (dispatch) => {
    dispatch(showNotification(Notification.INFO, 'Use "Ctrl + P" or "Cmd + P" to print this page content.'));
};

export const platformNotifications = (data, level, levelId) => {
    return {
        type: PLATFORM_NOTIFICATIONS,
        payload: { data: data, level: level, levelId },
    };
};

export const openNotification = (notificationObject) => {
    return {
        type: OPEN_NOTIFICATION,
        payload: notificationObject,
    };
};

export const setDialogPreview = (value) => {
    return {
        type: SET_DIALOG_PREVIEW,
        payload: value,
    };
};
