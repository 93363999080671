import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: { main: '#13B5EA', contrastText: '#ffffff' },
        secondary: { main: '#666766', contrastText: '#ffffff' },
        pink: { main: '#e20177', contrastText: '#ffffff' },
        blue: { main: '#13B5EA', contrastText: '#000000' },
        grey: { main: '#666766', contrastText: '#ffffff', light: 'hsl(120, 0%, 60%)', dark: 'hsl(120, 0%, 20%)' },
        orange: { main: '#f78e1e', contrastText: '#ffffff' },
        green: { main: '#C1D82F', contrastText: '#ffffff' },
        mustard: { main: '#E1D04D', contrastText: '#ffffff' },
        yellow: { main: 'hsl(44, 100%, 50%)', contrastText: '#000000' },
        purple: { main: 'hsl(275, 86%, 50%)', contrastText: '#ffffff' },
        teal: { main: '#4FB7AA', contrastText: '#ffffff' },
        red: { main: '#D73816', contrastText: '#ffffff' },
    },
    typography: {
        fontFamily: ['Imprima', 'Museo700'].join(', '),
        root: { fontFamily: ['Imprima', 'Museo700'].join(', ') },
        h1: { fontFamily: ['Museo700'].join(', '), marginBottom: 16 },
        h2: { fontFamily: ['Museo700'].join(', '), marginBottom: 16 },
        h3: { fontFamily: ['Museo700'].join(', '), marginBottom: 16 },
        h4: { fontFamily: ['Museo700'].join(', '), marginBottom: 16 },
        h5: { fontFamily: ['Museo700'].join(', '), marginBottom: 16 },
        h6: { fontFamily: ['Museo700'].join(', '), marginBottom: 16 },
    },
    tableHeadCell: {
        style: {
            backgroundColor: '#EEF2F3',
            color: '#000000',
            fontWeight: 'bold',
            textAlign: 'left',
        },
    },
    tableBoldHeadStyle: {
        style: {
            backgroundColor: '#EEF2F3',
            color: '#000000',
            fontWeight: 'bold',
            textAlign: 'left',
            fontFamily: 'Museo700',
        },
    },
    tableCalendarHeadStyle: {
        style: {
            backgroundColor: '#fff',
            color: '#000000',
            fontWeight: 'bold',
            textAlign: 'left',
            fontFamily: 'Museo700',
            border: '1px solid black',
        },
    },
    tableCalendarCellStyle: {
        style: {
            backgroundColor: '#fff',
            color: '#000000',
            textAlign: 'left',
            border: '1px solid black',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Museo700',
                    textTransform: 'none',
                    marginTop: 8,
                    marginLeft: 0,
                    marginRight: 8,
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                extended: {
                    textTransform: 'none',
                    fontFamily: 'Museo700',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(105deg, white 199px, #13B5EA 199px)',
                    minHeight: '64px !important',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: 'Museo700',
                    padding: '23px 12px',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: ({ ownerState, theme }) => ({
                    backgroundColor: ownerState.color === 'screen' ? '#13b5ea' : 'white',
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    height: 10,
                    width: 108,
                }),
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .Mui-disabled': {
                        color: 'rgba(0,0,0,0.6) !important',
                        '-webkit-text-fill-color': 'rgba(0,0,0,0.6) !important',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: 'black',
                },
                input: {
                    color: 'black',
                    '&::placeholder': {
                        opacity: 0.7,
                    },
                },
            },
        },
        MuiAlert: {
            variants: [
                {
                    props: { variant: 'compact' },
                    style: {
                        padding: 0,
                        fontSize: 12,
                        backgroundColor: 'hsl(120, 0%, 96%)',
                    },
                },
            ],
        },
    },
});

export default theme;
