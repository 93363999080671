import Sun from 'assets/images/student/weather/Sun.gif';
import Storm from 'assets/images/student/weather/Storm.gif';
import Rainfall from 'assets/images/student/weather/Rainfall.gif';
import Rainbow from 'assets/images/student/weather/Rainbow.gif';
import Cloudy from 'assets/images/student/weather/Cloudy.gif';

import Storm_Argumentative from 'assets/images/student/weather/Storm_Argumentative.gif';
//import Stormy_Scheming_Sly from 'assets/images/student/weather/Stormy_Scheming_Sly.gif';
import Storm_Frustration from 'assets/images/student/weather/Storm_Frustration.gif';
import Rainfall_Anxious_Trepidatious from 'assets/images/student/weather/Rainfall_Anxious_Trepidatious.gif';
import Rainfall_Nervous from 'assets/images/student/weather/Rainfall_Nervous_Anxiety.gif';
import Rainfall_Impatient from 'assets/images/student/weather/Rainfall_Impatient.gif';
import Rainfall_Tired from 'assets/images/student/weather/Rainfall_Tired.gif';
import Rainfall_Hurt from 'assets/images/student/weather/Rainfall_Hurt.gif';
import Rainfall_Miserable from 'assets/images/student/weather/Rainfall_Miserable.gif';
import Cloudy_Bored from 'assets/images/student/weather/Cloudy_Bored.gif';
import Partly_Quiet from 'assets/images/student/weather/Partly_Quiet.gif';
import Partly_Rainfall_Stormy_Busy from 'assets/images/student/weather/Partly_Rainfall_Stormy_Busy.gif';
//import Sunny_Loved from '../../assets/images/student/weather/Sunny_Loved.gif';
import Rainbow_Sunny_Partly_Calm from 'assets/images/student/weather/Rainbow_Sunny_Partly_Calm.gif';
import Cloudy_Cautious from 'assets/images/student/weather/Cloudy_Cautious.gif';
import Sun_Happy from 'assets/images/student/weather/Sun_Happy.gif';
import Sun_Content from 'assets/images/student/weather/Sun_Content.gif';
import Rainbow_Arrogance from 'assets/images/student/weather/Rainbow_Arrogance.gif';
import Rainbow_Energized from 'assets/images/student/weather/Rainbow_Energized.gif';
import Rainbow_Excited_Awe from 'assets/images/student/weather/Rainbow_Excited_Awe.gif';
import Rainbow_Pride from 'assets/images/student/weather/Rainbow_Pride.gif';
import Rainbow_Silly from 'assets/images/student/weather/Rainbow_Silly.gif';
import Rainbow_confident from 'assets/images/student/weather/Rainbow_confident.gif';

import Partly_Peaceful from 'assets/images/student/weather/Partly_Peaceful.gif';
import Partly_Hopeful from 'assets/images/student/weather/Partly_Hopeful.gif';
import Partly_Safe from 'assets/images/student/weather/Partly_Safe.gif';
import Partly_Shy from 'assets/images/student/weather/Partly_Shy.gif';
import Rainbow_Cheerful from 'assets/images/student/weather/Rainbow_Cheerful.gif';
import Rainbow_Loved from 'assets/images/student/weather/Rainbow_Loved.gif';
import Rainbow_Overjoyed from 'assets/images/student/weather/Rainbow_Overjoyed.gif';
import Rainbow_Surprised from 'assets/images/student/weather/Rainbow_Surprised.gif';
import Rainfall_Annoyed from 'assets/images/student/weather/Rainfall_Annoyed.gif';
import Rainfall_Embarassed from 'assets/images/student/weather/Rainfall_Embarassed.gif';
import Rainfall_Lonely from 'assets/images/student/weather/Rainfall_Lonely.gif';
import Rainfall_Sad from 'assets/images/student/weather/Rainfall_Sad.gif';
import Rainfall_Scared from 'assets/images/student/weather/Rainfall_Scared.gif';
//import Rainfall_Uncomfortable from 'assets/images/student/weather/Rainfall_Uncomfortable.gif';
import Rainfall_Worried from 'assets/images/student/weather/Rainfall_Worried.gif';
//import Stormy_Angry from 'assets/images/student/weather/Stormy_Angry.gif';
import Stormy_Ashamed from 'assets/images/student/weather/Stormy_Ashamed.gif';
//import Stormy_Cranky from 'assets/images/student/weather/Stormy_Cranky.gif';
import Stormy_Disappointed from 'assets/images/student/weather/Stormy_Disappointed.gif';
import Stormy_Frightened from 'assets/images/student/weather/Stormy_Frightened.gif';
import Stormy_Jealous from 'assets/images/student/weather/Stormy_Jealous.gif';
import Sunny_Brave from 'assets/images/student/weather/Sunny_Brave.gif';
import Sunny_Comfortable from 'assets/images/student/weather/Sunny_Comfortable.gif';
import Sunny_Friendly from 'assets/images/student/weather/Sunny_Friendly.gif';
import Sunny_Grateful from 'assets/images/student/weather/Sunny_Grateful.gif';
import Sunny_Kind from 'assets/images/student/weather/Sunny_Kind.gif';

export const ZONES_OF_REGULATION = {
    1: 'Blue',
    2: 'Green',
    3: 'Orange',
    4: 'Red',
};

export const TYPES = {
    //QUIZ TYPES
    QUIZ: {
        ID: 100,
        TYPE: 'QUIZ',
        TITLE: 'Quiz',
        COMPONENT: 'Quiz',
    },
    QUIZ_MULTI_SINGLE: {
        ID: 101,
        TITLE: 'Single Answer',
        TYPE: 'QUIZ_MULTI_SINGLE',
        COMPONENT: 'Quiz',
    },
    QUIZ_MULTI_MULTI: {
        ID: 102,
        TITLE: 'Multiple Answer',
        TYPE: 'QUIZ_MULTI_MULTI',
        COMPONENT: 'Quiz',
    },
    QUIZ_TEXT_FIELD: {
        ID: 103,
        TITLE: 'Open ended',
        TYPE: 'QUIZ_TEXT_FIELD',
        COMPONENT: 'Quiz',
    },
    QUIZ_DRAW_RESPONSE: {
        ID: 104,
        TITLE: 'Draw response',
        TYPE: 'QUIZ_DRAW_RESPONSE',
        COMPONENT: 'Quiz',
    },
    //ANIMATIONS
    ANIMATION: {
        ID: 200,
        TITLE: 'Animation',
        TYPE: 'ANIMATION',
    },
    //GAMES
    GAME: {
        ID: 300,
        TYPE: 'GAME',
        TITLE: 'Games',
        COMPONENT: 'Game',
    },

    //VIDEOS
    VIDEO: {
        ID: 400,
        TITLE: 'Videos',
        TYPE: 'VIDEO',
        COMPONENT: 'Video',
    },
    //STATIC VIDEOS
    STATIC_VIDEO: {
        ID: 800,
        TITLE: 'Videos',
        TYPE: 'STATIC_VIDEO',
        COMPONENT: 'Video',
    },
    WEATHERREPORT: {
        ID: 500,
        TITLE: 'Emotion Check-in',
        TYPE: 'WEATHERREPORT',
        COMPONENT: 'WeatherReport',
    },
    WEATHERCHECKIN: {
        ID: 600,
        TITLE: 'Emotions Check In',
        TYPE: 'WEATHERCHECKIN',
        COMPONENT: 'WeatherCheckin',
    },
};

export const WHEATHER_REPORT_DB_ID = 999999999998;
export const WHEATHER_CHECKIN_DB_ID = 999999999999;

export const ENDGAME_ID = 301;

export const ACTIVITY_SUMMARY = {
    activityId: 999999999997,
    title: 'Summary',
    sliderImage: 'activity_summary',
    content: {},
    activityType: 'Summary',
};

export const WEATHER_REPORT_COMPLETION = [
    {
        id: 0,
        action: 'Pause',
        description: 'You have selected to halt student lesson progression after completing this Emotion Check-in.',
    },
    {
        id: 1,
        action: 'Continue',
        description: 'Students go to default course after completion of the Emotion Check-in.',
    },
];

export const GAME_TYPES = [
    { id: 300, type: 'General' },
    { id: 310, type: 'Scenario Based' },
];

//THIS STRUCTURE USED IN THE ACTIVITY MANAGER
export default [
    {
        _id: TYPES.QUIZ.ID,
        type: TYPES.QUIZ.TYPE,
        title: 'Quiz',
        icon: 'dripicons-checklist',
        component: 'Quiz',
        url: '/admin/activity/quiz',
        parent: null,
    },
    {
        _id: TYPES.QUIZ_MULTI_SINGLE.ID,
        type: TYPES.QUIZ_MULTI_SINGLE.TYPE,
        title: 'Single Answer',
        description: 'Multiple choice single answer',
        icon: 'dripicons-checklist',
        component: 'Quizzes/QuizMultiSingle',
        studentComponent: 'StudentView/QuizSingleAnswer',
        sliderImage: 'quiz_multi_single',
        celebration: true,
        parent: TYPES.QUIZ.COMPONENT,
        reportTitle: 'Multiple choice, single answer',
    },
    {
        _id: TYPES.QUIZ_MULTI_MULTI.ID,
        type: TYPES.QUIZ_MULTI_MULTI.TYPE,
        title: 'Multiple Answer',
        description: 'Multiple choice multiple answer',
        icon: 'dripicons-view-list',
        component: 'Quizzes/QuizMultiMulti',
        studentComponent: 'StudentView/QuizMultiAnswer',
        sliderImage: 'quiz_multi_multi',
        parent: TYPES.QUIZ.COMPONENT,
        reportTitle: 'Multiple choice, multiple answer',
    },
    {
        _id: TYPES.QUIZ_TEXT_FIELD.ID,
        type: TYPES.QUIZ_TEXT_FIELD.TYPE,
        title: 'Text Field',
        description: 'Open-ended text field',
        icon: 'dripicons-blog',
        component: 'Quizzes/QuizTextField',
        studentComponent: 'StudentView/QuizOpenEnded',
        sliderImage: 'quiz_text_response',
        parent: TYPES.QUIZ.COMPONENT,
        reportTitle: 'Open response',
    },
    {
        _id: TYPES.QUIZ_DRAW_RESPONSE.ID,
        type: TYPES.QUIZ_DRAW_RESPONSE.TYPE,
        title: 'Draw Response',
        description: 'Open-ended draw response',
        icon: 'dripicons-pencil',
        component: 'Quizzes/QuizDrawResponse',
        studentComponent: 'StudentView/QuizDrawResponse',
        sliderImage: 'quiz_draw_response',
        parent: TYPES.QUIZ.COMPONENT,
        reportTitle: 'Draw Response',
    },
    {
        _id: TYPES.GAME.ID,
        type: TYPES.GAME.TYPE,
        title: 'Game',
        icon: 'dripicons-gaming',
        component: 'Game',
        studentComponent: 'StudentView/GameActivity',
        url: '/admin/activity/games',
        sliderImage: 'game',
        reportTitle: 'Game',
        parent: null,
    },
    {
        _id: TYPES.VIDEO.ID,
        type: TYPES.VIDEO.TYPE,
        title: 'Video',
        icon: 'dripicons-camcorder',
        component: 'Video',
        studentComponent: 'StudentView/VideoActivity',
        url: '/admin/activity/videos',
        sliderImage: 'character_animations',
        parent: null,
    },
    {
        _id: TYPES.STATIC_VIDEO.ID,
        type: TYPES.STATIC_VIDEO.TYPE,
        title: 'Video',
        icon: 'dripicons-camcorder',
        component: 'Videos',
        studentComponent: 'StudentView/StaticVideoActivity',
        url: '/admin/activity/videos',
        sliderImage: 'character_animations',
        parent: TYPES.VIDEO.COMPONENT,
    },
    {
        _id: TYPES.WEATHERREPORT.ID,
        type: TYPES.WEATHERREPORT.TYPE,
        title: 'Emotion Check-in',
        icon: 'dripicons-cloud',
        component: 'WeatherReport',
        studentComponent: 'StudentView/WeatherReportActivity',
        url: '/admin/activity/weather-report',
        sliderImage: 'weather_report',
        parent: 1,
    },
    {
        _id: TYPES.WEATHERCHECKIN.ID,
        type: TYPES.WEATHERCHECKIN.TYPE,
        title: 'Emotion Check-in',
        icon: 'dripicons-cloud-upload',
        component: 'WeatherCheckin',
        url: '/admin/activity/weather-check-in',
        sliderImage: 'weather_check_in',
        parent: 1,
    },
];
