import { ADD_UNIT_TO_STATE, ADD_LESSON_TO_STATE, SET_UNIT_ACTIVE, EDIT_LESSON_START_DATE } from './actionTypes';
import axios from 'config/axios';

import Notification from 'components/UI/Notification';
import { showNotification, showServerNotification } from './uiActions';

export const addUnitToStore = (unit) => async (dispatch) => {
    const currentLessonObj =
        unit.LessonGroups && unit.LessonGroups.find((group) => +group.UnitLessonGroup.isCurrent === 1);
    const currentLesson = (currentLessonObj && currentLessonObj.groupId) || unit.LessonGroups[0].groupId;
    unit.UnitLessonGroup = [];
    unit.LessonGroups = unit.LessonGroups.map((group) => {
        unit.UnitLessonGroup.push(group.UnitLessonGroup);
        delete group.UnitLessonGroup;
        dispatch({
            type: ADD_LESSON_TO_STATE,
            payload: { ...group, unitId: unit.unitId },
        });
        return group.groupId;
    });
    dispatch({
        type: ADD_UNIT_TO_STATE,
        payload: { ...unit, currentLesson },
    });
};

export const setLessonStartDate = (unitId, groupId, startDate) => async (dispatch) => {
    try {
        dispatch({ type: EDIT_LESSON_START_DATE, payload: { unitId, groupId, startDate } });
    } catch (err) {
        dispatch(showNotification(Notification.DANGER, 'Failed to assign unit to class'));
    }
};
