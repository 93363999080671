import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import test_class_icon from 'assets/images/test_class_icon.svg';
import { Avatar } from '@mui/material';
import { connect } from 'react-redux';
import Classes from 'Api/Classes';
import { showServerNotification } from 'store/actions/uiActions';
import { setCurrentClass, editClass } from 'store/actions/classActions';
import gci from 'assets/images/gc.png';

const ItemTypes = {
    CARD: 'card',
};

const ClassCard = (props) => {
    const { setHeaderLabel, history, moveClass, id, findCard, classCards, googleId, isClassDashboard } = props;
    const isDemo = String(id).includes('demo_');
    const originalIndex = findCard(id).index;
    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            canDrop: () => false,
            hover({ id: draggedId }) {
                if (draggedId !== id) {
                    const { index: overIndex } = findCard(id);
                    moveClass(draggedId, overIndex);
                }
            },
        }),
        [findCard, moveClass]
    );
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            canDrag: () => !String(id).includes('demo_'),
            item: { id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: async (item, monitor) => {
                const { id: droppedId, originalIndex } = item;
                const didDrop = monitor.didDrop();
                if (!didDrop) {
                    moveClass(droppedId, originalIndex);
                } else {
                    await Classes.updateClass(null, classCards).then((r) => {
                        r.status === 200
                            ? props.showServerNotification(`Successfully updated order of classes`, [], 3000)
                            : props.showServerNotification(`Failed to update order of classes`, [], 3000);
                    });
                }
            },
        }),
        [id, originalIndex, moveClass]
    );
    const opacity = isDragging ? 0 : 1;
    const selectedClass = props.classCards.find((c) => c.classId === id);

    return (
        <li
            ref={(node) => (!isDemo ? drag(drop(node)) : null)}
            style={{ opacity }}
            className={`sidebar-option clickable ${
                String(props.classId) === String(props.currentClass?.classId) && isClassDashboard ? 'selected' : ''
            }`}
            onClick={async () => {
                const users = await Classes.getClassUsers(selectedClass.classId, {
                    include: ['students', 'teachers'],
                });
                props.onEditClass({ ...selectedClass, Students: users?.students, teachers: users?.teachers });
                props.setCurrentClass(selectedClass);
                setHeaderLabel(null);
                if (!history.location.pathname.includes('/teacher/classes')) {
                    history.push('/teacher');
                } else if (history.location.pathname.includes('/teacher/classes/curriculum/class')) {
                    history.push('/teacher/classes/dashboard/curriculum');
                }
                !props.defaultOpen && props.setOpen(false);
            }}
            onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                    const users = await Classes.getClassUsers(selectedClass.classId, {
                        include: ['students', 'teachers'],
                    });
                    props.onEditClass({ ...selectedClass, Students: users?.students, teachers: users?.teachers });
                    props.setCurrentClass(selectedClass);
                }
                setHeaderLabel(null);
                history.push('/teacher');
                !props.defaultOpen && props.setOpen(false);
            }}
            role='button'
            tabIndex={0}
            key={props.classId}
        >
            <div
                className={`class-logo ${
                    ['blue', 'brown', 'red', 'grey', 'light-blue', 'green', 'light-green', 'orange', 'pink'][
                        !props.adminClassView.status && props.className[0].toUpperCase().charCodeAt(0) % 9
                    ]
                }`}
            >
                <Avatar
                    src={String(props.classId).includes('demo_') ? test_class_icon : ''}
                    variant='circular'
                    alt={'3P'}
                >
                    {!props.adminClassView.status && props.className[0].toUpperCase()}
                </Avatar>
            </div>
            <div className='class-label'>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='text mr-2'>{props.className}</p>
                    {googleId && <img src={gci} alt='Google Classroom Logo' style={{ width: 20, height: 20 }} />}
                </span>
            </div>
        </li>
    );
};

const OrderedClassList = (props) => {
    const [classCards, setClassCards] = useState(props.classes);
    useEffect(() => {
        setClassCards(props.classes);
    }, [props.classes]);

    const findCard = useCallback(
        (id) => {
            const card = !props.adminClassView.status && classCards.filter((c) => `${c.classId}` === `${id}`)[0];
            return {
                card,
                index: classCards.indexOf(card),
            };
        },
        [classCards]
    );

    const moveClass = useCallback(
        (id, atIndex) => {
            const { card, index } = findCard(id);
            let newClassCards = classCards;
            newClassCards.splice(index, 1);
            newClassCards.splice(atIndex, 0, card);
            setClassCards([...newClassCards]);
        },
        [findCard, classCards, setClassCards]
    );

    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

    return (
        <ul ref={drop} className='class-list'>
            {classCards.map((c, index) => {
                return (
                    <ClassCard
                        key={c.classId}
                        {...props}
                        {...c}
                        moveClass={moveClass}
                        index={index}
                        id={c.classId}
                        findCard={findCard}
                        classCards={classCards}
                    />
                );
            })}
        </ul>
    );
};

// Pull used redux state into props
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        adminClassView: state.classes && state.classes.adminClassView,
    };
};

// Setup dispatch for redux actions and set as functions on props
const mapDispatchToProps = (dispatch) => {
    return {
        showServerNotification: (message, children, timeOut) =>
            dispatch(showServerNotification(message, children, timeOut)),
        setCurrentClass: (classObject) => dispatch(setCurrentClass(classObject)),
        onEditClass: (data) => dispatch(editClass(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderedClassList);
