import {
    STUDENT_LESSON_PAUSE,
    STUDENT_LESSON_RESUME,
    STUDENT_LESSON_STOP,
    SOCKET_STUDENT_ACTIVITY_DATA
} from '../../actions/actionTypes';

const initialState = {
    lessonPaused: false,
    lessonStopped: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case STUDENT_LESSON_PAUSE: {
            console.log('student_lessonPause');
            return {
                ...state,
                lessonPaused: true
            };
        }
        case STUDENT_LESSON_RESUME: {
            console.log('student_lessonResume');
            return {
                ...state,
                lessonPaused: false,
                lessonStopped: false
            };
        }
        case STUDENT_LESSON_STOP: {
            console.log('student_lessonStop');
            return {
                ...state,
                lessonStopped: true
            };
        }
        /** Action intercepted by middleware - no effect on store. */
        case SOCKET_STUDENT_ACTIVITY_DATA: {
            return state;
        }
        default: {
            return state;
        }
    }
}
