import { INIT_LEARNING_AREAS, INIT_COMPLETED_LESSONS, SET_CURRENT_CLASS } from '../actions/actionTypes';

const initialState = {
    areas: [],
    focusLoader: false,
    completed: { lessons: [], groups: [] },
    loadedComplete: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INIT_LEARNING_AREAS: {
            let areas = action.payload;

            return {
                ...state,
                areas,
            };
        }
        case INIT_COMPLETED_LESSONS: {
            return { ...state, completed: action.payload, loadedComplete: true };
        }

        case SET_CURRENT_CLASS: {
            return { ...state, completed: { lessons: [], groups: [] }, loadedComplete: false };
        }

        default:
            return state;
    }
}
