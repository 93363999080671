import axios from 'config/axios';
import Auth from './Auth';

class Users {
    static async updateClasses(id, classList) {
        await Auth.checkCurrentAuthToken();
        return axios.put(`/api/v3/users/${id}/classes`, {
            classList,
        });
    }

    static async getUserComments({ id, classId, start, end }) {
        return await axios
            .get(`/api/v3/checkin/user/${id}/comments?start=${start}&end=${end}&classId=${classId}`)
            .then((res) => res.data)
            .catch((err) => err);
    }

    static async delete(data) {
        return await axios
            .put(`api/v3/users/delete`, data)
            .then((res) => res.data)
            .catch((err) => err);
    }

    static async sendInvite(users) {
        return await axios
            .post(`api/v4/users/send-invite`, users)
            .then((res) => res.data)
            .catch((err) => err);
    }

    static async requestInvite(users) {
        return await axios
            .post(`api/v4/users/request-invite`, users)
            .then((res) => res.data)
            .catch((err) => err);
    }

    static async getUserCheckInComments({ id, start, end, range, sentral }) {
        return await axios.get(
            `/api/v3/checkin/users/${id}/reason?range=${range}&start=${start}&end=${end}&sentral=${sentral}`
        );
    }

    static async getClasses(id, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/users/${id}/classes?include=${opts.include || ''}`).then((res) => res.data);
    }

    static async getStudentCheckInResponses({ id, range, startDate, endDate }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(
                `/api/v4/users/${id}/checkInResponses/?classId=${opts.classId}&range=${range}&startDate=${startDate}&endDate=${endDate}`
            )
            .then((r) => r.data);
    }

    static async getStudentCheckInResponsesExport({ id, startDate, endDate, range }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return axios
            .get(
                `/api/v4/users/${id}/checkInResponses/export?classId=${opts.classId}&range=${range}&startDate=${startDate}&endDate=${endDate}`
            )
            .then((res) => res.data);
    }

    static async getUserProfile(id, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios.get(
            `/api/v4/users/${id}?include=${opts.include || ''}&${opts.sentral ? 'sentral=true' : ''}${
                opts?.lastCheckin ? '&lastCheckin=true' : ''
            }`
        );
    }

    static async getStudentCommentSummary({ id, startDate, endDate, range }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return axios
            .get(`/api/v4/users/${id}/studentCommentSummary/?range=${range}&startDate=${startDate}&endDate=${endDate}`)
            .then((res) => res.data);
    }

    static async getCommentCategoryResponses({ id, startDate, endDate, range, commentId }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return axios
            .get(
                `/api/v4/users/${id}/commentCategory/${commentId}/responseDetails?range=${range}&startDate=${startDate}&endDate=${endDate}`
            )
            .then((res) => res.data);
    }

    static async getBadges({ userId }) {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/users/${userId}/badges`).then((res) => res.data);
    }

    static async awardBadges({ userId, badgeId }) {
        await Auth.checkCurrentAuthToken();
        return await axios.post(`/api/v4/users/badges`, { badgeId, userId }).then((res) => res.data);
    }

    static async create(data) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .post(`/api/v4/users/create`, data)
            .then((res) => res.data)
            .catch((err) => err);
    }

    static async update({ userId, data }) {
        await Auth.checkCurrentAuthToken();
        return await axios.put(`/api/v4/users/${userId}`, data).then((res) => res.data);
    }

    static async removeFromClasses({ userId, classes }) {
        await Auth.checkCurrentAuthToken();
        return await axios.put(`/api/v4/users/${userId}/remove-classes`, classes).then((res) => res.data);
    }

    static async addToClasses({ userId, classes }) {
        await Auth.checkCurrentAuthToken();
        return await axios.put(`/api/v4/users/${userId}/add-classes`, classes).then((res) => res.data);
    }

    static async checkEmailExists({ email }) {
        await Auth.checkCurrentAuthToken();
        return await axios.post(`/api/v4/users/existing-email-check`, { email }).then((res) => res.data);
    }

    static async handlePinningStudent(userId) {
        await Auth.checkCurrentAuthToken();
        return await axios.post(`/api/v4/users/update-user-subgroup`, { userId }).then((res) => res.data);
    }

    static async getPinnedStudents({ userId }) {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/users/${userId}/pinned-students`).then((res) => res.data);
    }
}

export default Users;
