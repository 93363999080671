import ReactGA from 'react-ga4';
import { CATEGORIES, EVENTS } from 'constants/googleAnalytics';
import axios, { api } from 'config/axios';
import setAuthToken from 'utils/setAuthToken';
import { parseJwt } from 'utils';

import {
    GET_ERRORS,
    SET_CURRENT_USER,
    SET_CURRENT_STUDENT,
    PASSWORD_RESET,
    PASSWORD_RESETED,
    SET_UNAUTHORIZED_TEACHER,
    SERVER_NOTIFICATIONS,
} from './actionTypes';
// import { toggleModal } from './uiActions';
import { GET_FATAL_ERRORS } from 'store/actions/actionTypes';

import { showServerNotification } from 'store/actions/uiActions';
import { isEmpty } from '@lsgo/lsgo-common';
import sendAnalytics from 'utils/analytics';
import moment from 'moment';

import Auth from 'Api/Auth';
import Teacher from 'Api/Teachers';
import { studentAppLink, teacherAppLink, resourceAppLink } from 'utils';
import queryString from 'query-string';
import { Logger } from '@lsgo/lsgo-common';
import { ampli } from 'ampli';
import { licenseTypes, roleTypes } from 'constants/options';
import Users from 'Api/Users';
import Constants from 'Api/Constants';

//Login - get user login
export const loginTeacher = (teacherData, history, loginMethod) => async (dispatch) => {
    try {
        const result = await Auth.login(teacherData, loginMethod); // await axios.post('/api/teachers/login', teacherData);
        if (result && !result.error && !result.response) {
            if (!result.data.success) {
                if (result.data.message) {
                    dispatch({
                        type: GET_ERRORS,
                        payload: { error: result.data.message },
                    });
                }
                return result.data;
            } else {
                //Save to local storage
                Auth.userV2({ history, redirect: false }).then(async (token) => {
                    if (token) {
                        //unauthorized is set false when loading teacher and redirected to login
                        dispatch({ type: SET_UNAUTHORIZED_TEACHER, payload: false });

                        //Set token to Auth header
                        setAuthToken(token);
                        const decoded = await parseJwt(token);

                        if (decoded.id) {
                            console.log(decoded);
                            try {
                                if (
                                    !isEmpty(decoded.tfaEnabled) &&
                                    decoded.tfaEnabled &&
                                    !isEmpty(decoded.tfaAuthenticated) &&
                                    !decoded.tfaAuthenticated
                                ) {
                                    history.push('/tfa/verify/');
                                } else {
                                    const profile = await Users.getUserProfile(decoded.id);
                                    ampli.identify(profile.data.id, {
                                        license: licenseTypes[profile.data.groups.licenseId],
                                        role: roleTypes[profile.data.groups.roleId],
                                        payment_status: profile.data.groups.paymentStatus || 'free',
                                        group_id: profile.data.groups.groupId,
                                        school_id: profile.data.schoolId,
                                    });
                                    if (!profile.data.name) profile.data.name = profile.data.firstName;
                                    dispatch(setCurrentTeacher({ ...profile.data, alias: decoded.alias }));
                                }
                            } catch (error) {
                                new Logger().logError(error);
                                showServerNotification('Failed to update profile', null, 3000);
                            }
                        }
                        //GA custom event for teacher logging in
                        ReactGA.event({
                            category: CATEGORIES.TEACHER_ACTIONS,
                            action: `${EVENTS.LOG_IN}`,
                        });
                    }
                });
            }
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: { error: result.response && result.response.data },
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response && err.response,
        });
    }
};

export const loginAsTeacher = (teacherId) => async (dispatch) => {
    try {
        const loginResult = await axios.post(`/api/v4/auth/login-as/${teacherId}`);

        if (loginResult.data.success) {
            //Save to local storage
            const token = await Auth.userV2({});

            //unauthorized is set false when loading teacher and redirected to login
            dispatch({ type: SET_UNAUTHORIZED_TEACHER, payload: false });

            //GA custom event for logging as teacher
            ReactGA.event({
                category: CATEGORIES.TEACHER_ACTIONS,
                action: `${EVENTS.LOGIN_AS_TEACHER}`,
            });

            const decoded = await parseJwt(token.replace('Bearer ', '')).catch((e) => {
                console.log(e);
            });
            if (decoded.id) {
                try {
                    const profile = await Users.getUserProfile(decoded.id);
                    if (!profile.data.name) profile.data.name = profile.data.firstName;
                    dispatch(setCurrentTeacher({ ...profile.data, alias: decoded.alias }));
                } catch (error) {
                    new Logger().logError(error);
                    showServerNotification('Failed to update profile name', null, 3000);
                }
            }
            // open the student on new tab
            Object.assign(document.createElement('a'), {
                href: `${teacherAppLink}`,
            }).click();
        }
    } catch (err) {
        console.log({ err });
        dispatch({
            type: GET_ERRORS,
            payload: err.response,
        });
    }
};

export const loginStudent = (studentData, history) => async (dispatch) => {
    try {
        return await axios.post(`/api/v4/auth/login-as/${studentData.id}`).then(
            (result) => {
                return result.data;
            },
            (failed) => {
                dispatch({
                    type: GET_ERRORS,
                    payload: `Failed to login as student: ${failed}`,
                });
                return undefined;
            }
        );
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    }
};

//Set Logged in user
export const setCurrentTeacher = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

export const setCurrentStudent = (decoded) => {
    return {
        type: SET_CURRENT_STUDENT,
        payload: decoded,
    };
};

export const logOutAlias = (data) => async (dispatch) => {
    return await axios.post(`/api/v4/auth/logout-alias`, data).then(
        (
            r //redirect to teacher
        ) =>
            !data.redirect
                ? ''
                : Object.assign(document.createElement('a'), {
                      href: teacherAppLink,
                  }).click()
    );
};

//Log out user
export const logOutTeacher = (studentData) => async (dispatch) => {
    // If student data is provided we don't want to log out the teacher, but login as the student.
    /**@TODO - Split this into a separate redux action. */
    console.log('LOGGING OUTTT');
    if (studentData) {
        // Fetch a student token and redirect to student app.
        await dispatch(loginStudent(studentData));

        //GA custom event for logging as student
        ReactGA.event({
            category: CATEGORIES.TEACHER_ACTIONS,
            action: `${EVENTS.LOGIN_AS_STUDENT}`,
        });

        //send analyitcs for teacher logging out
        sendAnalytics(
            {
                area: 'TEACHERS',
                action: 'LOG_IN_AS_STUDENT',
                classId: null,
                params: studentData,
            },
            true
        );

        //open the student on new tab
        Object.assign(document.createElement('a'), {
            target: '_blank',
            href: `${studentAppLink}`,
        }).click();
    } else {
        //clear cookie
        await axios.delete('/api/v4/auth/logout').catch((err) => {
            console.log('Failed to logout for ', err.message);
        });

        ///remove all data from localStorage and session Storage
        // localStorage.clear();
        localStorage.removeItem('authToken');
        localStorage.removeItem('lsgoRT');
        localStorage.removeItem('apc_user_id');
        localStorage.removeItem('apc_local_id');

        //remove token from axios header
        setAuthToken(false);

        // remove unauthorized redux state if set previously
        dispatch({ type: SET_UNAUTHORIZED_TEACHER, payload: false });

        //send analyitcs for teacher logging out
        sendAnalytics(
            {
                area: 'AUTH',
                action: 'LOG_OUT',
                classId: null,
                params: null,
            },
            true
        );

        window.location.href = `/?redirectUri=${encodeURIComponent(window.location.pathname + window.location.search)}`;
    }
    return;
};

export const register = (data, history, referrer) => async (dispatch) => {
    try {
        ///teacher/updateEmail/
        return await Auth.register({ ...data, referrer })
            .then(async (result) => {
                if (result?.data?.error) {
                    dispatch({
                        type: GET_ERRORS,
                        payload: result?.data?.error,
                    });

                    return result;
                }

                if (referrer === 'resource_app') {
                    Object.assign(document.createElement('a'), {
                        href: resourceAppLink,
                    }).click();
                    return;
                } else
                    return await Auth.userV2({
                        redirect: true,
                        history,
                    }).then(async (token) => {
                        if (token) {
                            dispatch({
                                type: SERVER_NOTIFICATIONS,
                                payload: {
                                    content:
                                        'You have been registered successfully! You will be redirected to login page shortly.',
                                    children: [],
                                    timeOut: 3000,
                                },
                            });

                            //unauthorized is set false when loading teacher and redirected to login
                            dispatch({ type: SET_UNAUTHORIZED_TEACHER, payload: false });
                            //Set token to Auth header
                            setAuthToken(token);
                            console.log(token, 'token');
                            const decoded = await parseJwt(token);
                            const teacher = await Users.getUserProfile(decoded.id);
                            dispatch(
                                setCurrentTeacher({
                                    ...teacher.data,
                                    name: teacher.data.firstName,
                                    alias: decoded.alias,
                                })
                            );
                            setTimeout(() => {
                                history.push('/');
                            }, 3000);
                        }
                    });
            })
            .catch((e) => {
                console.log(e);
                dispatch({
                    type: GET_ERRORS,
                    payload: e,
                });
            });
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err,
        });
    }
};

//show error notifications
export const notify = (data) => async (dispatch) => {
    dispatch(showServerNotification(data, [], 5000, null));
};

export const forgotPassword = (data, history) => async (dispatch) => {
    try {
        const result = await Auth.forgotPassword(data);

        if (result.data && result.data.message && !result.data.error) {
            dispatch({
                type: PASSWORD_RESET,
                payload: result,
            });
            history.push('/');
        } else {
            return false;
        }
    } catch (err) {
        dispatch({
            type: GET_FATAL_ERRORS,
            payload: err,
        });
    }
};

export const resetPassword = (data, history) => async (dispatch) => {
    try {
        const result = await Auth.resetPassword(data);

        if (result.data && result.data.message && !result.data.error) {
            dispatch({
                type: PASSWORD_RESETED,
                // payload: result.data,
            });
            history.push('/');
            return result.data;
        } else {
            //set errors for failing to update
            dispatch({
                type: GET_ERRORS,
                payload: result.data.errors.message,
            });
        }
    } catch (err) {
        console.log(err);
        dispatch({
            type: GET_FATAL_ERRORS,
            payload: err,
        });
        dispatch({
            type: GET_ERRORS,
            payload: 'Failed to change password',
        });
    }
};

export const verifyResetToken = (token) => async (dispatch) => {
    try {
        const result = await Auth.verifyResetToken(token);

        if (result && +result.status === 200) {
            return result.data;
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: result.data.error,
            });
        }
    } catch (err) {
        console.log(err);
        dispatch({
            type: GET_FATAL_ERRORS,
            payload: err,
        });
        dispatch({
            type: GET_ERRORS,
            payload: 'Failed to verify password reset link',
        });
    }
};

export const handleSSO = async (params) => {
    try {
        return await axios.post('/api/v4/schools/sis/sso', params).then((result) => {
            if (result?.data?.refresh_token) {
                axios.defaults.headers.common['x-refresh-token'] = result?.data?.refresh_token;

                localStorage.setItem('lsgoRT', result?.data?.refresh_token);
            }
            return result;
        });
    } catch (e) {
        console.log(e, 'Failed SSO');
    }
};
