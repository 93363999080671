import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PrivateRoute from 'routing/PrivateRoute';
import PropTypes from 'prop-types';
import './ExternalEmptyLayout.scss';
import Header from 'components/Navigation/Sidebar/Header';
import { useMediaQuery } from '@mui/material';
import { logOutTeacher, setCurrentTeacher } from 'store/actions/authActions';
import { setCurrentClass, addClass } from 'store/actions/classActions';
import { showServerNotification } from 'store/actions/uiActions';
import Sidebar from 'components/Navigation/Sidebar/Sidebar';

const ExternalEmptyLayout = (props) => {
    const { component: Component, auth, serverNotifications, embed, ...rest } = props;
    const [toastType, setToastType] = useState(null);
    const defaultOpen = useMediaQuery('(min-width: 66.625em)', { noSsr: true });
    const [open, setOpen] = useState(defaultOpen);
    const isIframe = window.location !== window.parent.location || embed;

    return (
        <>
            <PrivateRoute
                {...rest}
                routeType={
                    rest.schoolBytesUnsafeRoute
                        ? 'schoolBytes'
                        : rest.sentralUnsafeRoute
                        ? 'sentral'
                        : rest.hsExternal
                        ? 'hs-external'
                        : 'guest'
                }
                embed={embed}
                isAuthenticated={auth.isAuthenticated}
                render={(componentProps) => {
                    return (
                        <div className='app-wrapper'>
                            {!isIframe && (
                                <Sidebar
                                    teacher={props.auth.teacher}
                                    open={open}
                                    setOpen={setOpen}
                                    defaultOpen={defaultOpen}
                                />
                            )}
                            {!embed && <Header open={open} setOpen={setOpen} externalLayout={isIframe} />}
                            <div
                                className={`content${`${isIframe ? '-page' : ''} ${embed ? 'embed' : ''}`} ${
                                    !isIframe && !open ? 'full-width' : ''
                                }${props.auth.teacher && props.auth.teacher.alias ? 'alias' : ''}`}
                                style={{ paddingTop: 100 }}
                            >
                                <Component {...componentProps} />
                            </div>
                        </div>
                    );
                }}
            />
        </>
    );
};

ExternalEmptyLayout.propTypes = {
    component: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    ui: state.ui,
    socketTeacher: state.socketTeacher.verifiedUser,
    serverNotifications: state.ui.serverNotifications,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onLogOutTeacher: () => dispatch(logOutTeacher()),
        setCurrentClass: (classObject) => dispatch(setCurrentClass(classObject)),
        addClassToStore: (clss) => dispatch(addClass(clss)),
        setCurrentTeacher: (decoded) => dispatch(setCurrentTeacher(decoded)),
        showServerNotification: (content, children, timeOut, onClick) =>
            dispatch(showServerNotification(content, children, timeOut, onClick)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalEmptyLayout);
