/**-------------------- REACT AND REDUX IMPORTS --------------------**/

import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { logOutTeacher, setCurrentTeacher } from 'store/actions/authActions';
import { setCurrentClass, addClass } from 'store/actions/classActions';
import { showServerNotification } from 'store/actions/uiActions';
import PrivateRoute from 'routing/PrivateRoute';

/**-------------------- RENDERING COMPONENTS --------------------**/

import Footer from 'components/UI/Footer';
import { ToastContainer } from 'react-toastify';
import ToastifyNotification from 'components/UI/Notifications/ToastifyNotification';
import Sidebar from 'components/Navigation/Sidebar/Sidebar';
import Header from 'components/Navigation/Sidebar/Header';
import VideoPlayer from 'components/UI/VideoPlayer';

import 'components/Navigation/Sidebar/sidebar.scss';

/**-------------------- CUSTOM THEMING --------------------**/

import 'react-toastify/dist/ReactToastify.css';
import './toast.scss';
import { useMediaQuery } from '@mui/material';

/**-------------------- UTILITIES --------------------**/

import { isEmpty } from '@lsgo/lsgo-common';
import ReactGA from 'react-ga4';

/**-------------------- CONSTANTS --------------------**/
import Users from 'Api/Users';
import Classes from 'Api/Classes';

/**
 *@returns The Admin Layout is a container for all authenticated routes, and manages high level authentication, administration of release slides and terms + conditions etc.
 */
const AdminLayout = (props) => {
    const {
        component: Component,
        setCurrentClass,
        auth,
        addClassToStore,
        socketTeacher,
        serverNotifications,
        location,
        ...rest
    } = props;

    const defaultOpen = useMediaQuery('(min-width: 66.625em)', { noSsr: true });
    const [open, setOpen] = useState(defaultOpen);
    const [toastType, setToastType] = useState(null);
    const [teacherClasses, setTeacherClasses] = useState([]);

    useEffect(() => {
        for (let t = 0; t <= 300; t += 10) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, t);
        }
    }, [open]);

    // Set tracking user based on authentication, run when the auth state changes.
    useEffect(() => {
        function authCheckEffects() {
            if (auth.isAuthenticated === true) {
                try {
                    ReactGA.set({ userId: `teacher-${auth.teacher.id}` });
                } catch (e) {
                    console.error(e);
                }
            }
        }

        authCheckEffects();
    }, [auth]);

    useEffect(() => {
        if (!isEmpty(serverNotifications.message)) {
            serverNotifications.type === 'warning'
                ? setToastType('server-notification warning-notification')
                : setToastType('server-notification success-notification');
        }
    }, [serverNotifications]);

    // Load all of the teacher's high level class data when auth is changed.
    useEffect(() => {
        const loadClasses = async (klass) => {
            const classIdFromLocalStorage = localStorage.getItem('currentClassId');
            const classList = await Users.getClasses(props.auth.teacher.id);

            if (!isEmpty(classList)) {
                const currentClass =
                    classList?.find(
                        (c) =>
                            String(c.classId) === String(classIdFromLocalStorage) ||
                            String(c.classId) === String(klass.classId)
                    ) || classList[0];

                setTeacherClasses(classList);
                classList.forEach(async (individualClass, index) => {
                    const isCurrent = currentClass?.classId
                        ? String(individualClass.classId) === String(currentClass?.classId)
                        : index === 0;

                    let users = { teachers: [], students: [] };
                    if (isCurrent) {
                        users = await Classes.getClassUsers(individualClass.classId, {
                            include: ['students', 'teachers'],
                        });
                    }
                    addClassToStore({ ...individualClass, Students: users?.students, teachers: users?.teachers });
                });

                setCurrentClass(currentClass);
            }
        };

        loadClasses(String(localStorage.getItem('currentClassId')));
    }, [auth.teacher.id, addClassToStore]);

    // Run checks for AppCues content when class, unit or auth data changes.
    useEffect(() => {
        if (auth.isAuthenticated === true && teacherClasses) {
            const { teacher } = auth;

            if (teacher.groups.roleId > 0) {
                let role = 'teacher';
                if (teacher.groups.roleId === 3) {
                    role = 'school-admin';
                } else if (teacher.groups.roleId === 5) {
                    role = 'parent';
                }

                window.Appcues &&
                    window.Appcues.identify(`teacher-${teacher.id}`, {
                        role,
                        name: teacher.name,
                        classCount: teacherClasses?.length,
                        platform: window.location.hostname,
                        camapaign: teacher.campaign,
                        lastLogIn: teacher.lastLogin || 0,
                        modalActive: props.ui.showModal ? 1 : 0,
                        modalName: props.ui.dashboardModal,
                        signUpDate: teacher.createdAt,
                        isSentralIntegration: teacher.isSentralIntegration,
                        license: { id: teacher.user_groups.licenseId },
                    });
            }
        }
    }, [auth, props.ui, teacherClasses]);

    // If user is authenticated, render a route with the current component, otherwise redirect to login page.
    return (
        <PrivateRoute
            {...rest}
            routeType='private'
            isAuthenticated={auth.isAuthenticated}
            render={(componentProps) => {
                return (
                    <div className='app-wrapper'>
                        <Sidebar teacher={auth.teacher} open={open} setOpen={setOpen} defaultOpen={defaultOpen} />
                        <Header open={open} setOpen={setOpen}></Header>
                        <div className={`content-page`}>
                            <div
                                className={`content ${!open ? 'full-width' : ''}${
                                    auth.teacher && auth.teacher.alias ? 'alias' : ''
                                }`}
                            >
                                <Component {...componentProps} />
                            </div>
                            <div>
                                <ToastifyNotification />
                            </div>
                        </div>
                        <ToastContainer toastClassName={toastType} autoClose={false} />
                        <VideoPlayer />
                        <Footer />
                    </div>
                );
            }}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        ui: state.ui,
        socketTeacher: state.socketTeacher.verifiedUser,
        serverNotifications: state.ui.serverNotifications,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogOutTeacher: () => dispatch(logOutTeacher()),
        setCurrentClass: (classObject) => dispatch(setCurrentClass(classObject)),
        addClassToStore: (clss) => dispatch(addClass(clss)),
        setCurrentTeacher: (decoded) => dispatch(setCurrentTeacher(decoded)),
        showServerNotification: (content, children, timeOut, onClick) =>
            dispatch(showServerNotification(content, children, timeOut, onClick)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
