import Axios from 'config/axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConstant } from 'store/actions/constantAction';

const url = '/api/v4/constants/options/WEATHER_REPORT';

let loading = false;
let settingsForClassIds = {};

const useWeatherReport = (classId, opts = {}) => {
    const dispatch = useDispatch();

    const [weatherReport, setWeatherReport] = useState({
        WR_CATEGORIES: [],
        WR_EMOTIONS: [],
        WR_ZOR: [
            { label: 'Ready to learn', value: 'Green' },
            { label: 'In a state of processing', value: 'Yellow' },
            { label: 'Not quite ready to learn', value: 'Blue' },
            { label: 'Heightened state of emotion', value: 'Purple' },
            { label: 'Emotional overload', value: 'Red' },
        ],
        WR_VIDEOS: {
            'k-2': [],
            '3-6': [],
            '7-12': [],
        },
        NSW_PILOT: [],
        assignedCheckinTemplate: null,
        steps: {
            roll_call: ['pre-checkin', 'emotion-context'],
            individual_checkin: ['pre-checkin', 'emotion-context', 'post-checkin'],
            prepare_for_learning: ['video', 'pre-checkin'],
        },
        templates: [],
        assignedCheckinTemplateIdForClass: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            if (opts?.updateSettings) {
                settingsForClassIds = {};
            } else if (classId && settingsForClassIds?.[classId]) {
                setWeatherReport(settingsForClassIds?.[classId]);
            } else {
                if (classId) {
                    const response = await Axios.get(`/api/v4/constants/options/WEATHER_REPORT/?classId=${classId}`);
                    const data = response.data.option;

                    const templates = await Axios.get(
                        `/api/v5/groups/checkin/template/${
                            data?.assignedCheckinTemplate?.assignedCheckinTemplateIdForClass
                                ? 'templateId'
                                : 'templateType'
                        }/${
                            data?.assignedCheckinTemplate?.assignedCheckinTemplateIdForClass
                                ? data?.assignedCheckinTemplate?.assignedCheckinTemplateIdForClass
                                : data?.assignedCheckinTemplate?.type
                        }`
                    ).then((r) => r?.data);

                    settingsForClassIds = {
                        ...settingsForClassIds,
                        [classId]: {
                            ...data,
                            templates,
                            WR_ZOR: [
                                { label: 'Ready to learn', value: 'Green' },
                                { label: 'In a state of processing', value: 'Yellow' },
                                { label: 'Not quite ready to learn', value: 'Blue' },
                                { label: 'Heightened state of emotion', value: 'Purple' },
                                { label: 'Emotional overload', value: 'Red' },
                            ],
                            WR_VIDEOS: {
                                'k-2': data.WR_EMOTIONS.map((emotion) => {
                                    return emotion.video[0];
                                }),
                                '3-6': data.WR_EMOTIONS.map((emotion) => {
                                    return emotion.video[1];
                                }),
                                '7-12': data.WR_EMOTIONS.map((emotion) => {
                                    return emotion.video[2];
                                }),
                            },
                        },
                    };

                    setWeatherReport({
                        ...data,
                        templates,
                        WR_ZOR: [
                            { label: 'Ready to learn', value: 'Green' },
                            { label: 'In a state of processing', value: 'Yellow' },
                            { label: 'Not quite ready to learn', value: 'Blue' },
                            { label: 'Heightened state of emotion', value: 'Purple' },
                            { label: 'Emotional overload', value: 'Red' },
                        ],
                        WR_VIDEOS: {
                            'k-2': data.WR_EMOTIONS.map((emotion) => {
                                return emotion.video[0];
                            }),
                            '3-6': data.WR_EMOTIONS.map((emotion) => {
                                return emotion.video[1];
                            }),
                            '7-12': data.WR_EMOTIONS.map((emotion) => {
                                return emotion.video[2];
                            }),
                        },
                    });
                }
            }
        };

        fetchData();
    }, [dispatch]);

    return weatherReport;
};

export default useWeatherReport;
