import axios from 'config/axios';
import Auth from './Auth';

class Classes {
    static async getStudentForClasses(selectedClasses) {
        await Auth.checkCurrentAuthToken();
        return await axios.post('/api/v4/classes/getStudentForClasses', selectedClasses).then((res) => res.data);
    }

    static async createClass(classData) {
        await Auth.checkCurrentAuthToken();
        return await axios.post('/api/v4/classes', classData);
    }

    static async updateClass(classId, classData) {
        await Auth.checkCurrentAuthToken();
        return await axios.put(`/api/v4/classes/${classId}`, classData);
    }

    static async getUsers(classId, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(`/api/v3/classes/${classId}/users?userType=${opts.userType || ''}`)
            .then((res) => res.data);
    }

    static async updateUsers(classId, classList) {
        await Auth.checkCurrentAuthToken();
        return await axios.put(`/api/v3/classes/${classId}/users`, {
            classList,
        });
    }

    static async getClassUsers(classId, opts = {}) {
        return await axios
            .get(
                `/api/v4/classes/${classId}/classUsers/?${opts.include ? `include=${opts.include}&` : ''}${
                    opts.studentLogin ? 'studentLogin=true&' : ''
                }${opts.grades ? `grades=${opts.grades}` : ''}`
            )
            .then((r) => r.data);
    }

    static async getClassCheckinSummaryForRings(classId, opts = {}) {
        const cF = Object.keys(opts.currentFilters)
            .map((filter) => {
                return `${filter}=${
                    Array.isArray(opts.currentFilters[filter])
                        ? opts.currentFilters[filter].join(',')
                        : opts.currentFilters[filter]
                }`;
            })
            .join('&');
        return await axios
            .get(`/api/v4/classes/${classId}/checkin/dailySummary?range=${opts.range || ''}&${cF}`)
            .then((r) => r.data);
    }

    static async downloadClassStudentLogins(classId) {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/classes/${classId}/downloadStudentLogins`).then((res) => res.data);
    }

    static async getClassCheckInFeed(classId, opts = {}) {
        await Auth.checkCurrentAuthToken();
        const { startDate, endDate, range } = opts;
        const cF = Object.keys(opts.currentFilters)
            .map((filter) => {
                return `${filter}=${
                    Array.isArray(opts.currentFilters[filter])
                        ? opts.currentFilters[filter].join(',')
                        : opts.currentFilters[filter]
                }`;
            })
            .join('&');
        return await axios
            .get(
                `/api/v4/classes/${classId}/checkInFeed?range=${range}&startDate=${startDate}&endDate=${endDate}&${cF}`
            )
            .then((res) => res.data);
    }

    static async getCheckInSummary({ classId, startDate, endDate, range, location }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        const cF = Object.keys(opts.currentFilters)
            .map((filter) => {
                return `${filter}=${
                    Array.isArray(opts.currentFilters[filter])
                        ? opts.currentFilters[filter].join(',')
                        : opts.currentFilters[filter]
                }`;
            })
            .join('&');
        return await axios
            .get(
                `/api/v4/classes/${classId}/checkInSummary?range=${range}&startDate=${startDate}&endDate=${endDate}&location=${location}${
                    opts.grades ? `&grades=${opts.grades}` : ''
                }&${cF}`
            )
            .then((res) => res.data);
    }

    static async getStudentCommentSummary({ classId, startDate, endDate, range }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        const cF = Object.keys(opts.currentFilters)
            .map((filter) => {
                return `${filter}=${
                    Array.isArray(opts.currentFilters[filter])
                        ? opts.currentFilters[filter].join(',')
                        : opts.currentFilters[filter]
                }`;
            })
            .join('&');
        return await axios
            .get(
                `/api/v4/classes/${classId}/studentCommentSummary?range=${range}&startDate=${startDate}&endDate=${endDate}${
                    opts.grades ? `&grades=${opts.grades}` : ''
                }&${cF}`
            )
            .then((res) => res.data);
    }

    static async getCommentCategoryResponses({ classId, startDate, endDate, range, commentId }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(
                `/api/v4/classes/${classId}/commentCategory/${commentId}/responseDetails?range=${range}&startDate=${startDate}&endDate=${endDate}`
            )
            .then((res) => res.data);
    }

    static async getCheckInSummaryExport({ classId, startDate, endDate, range, currentFilters = {} }, opts = {}) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(
                `/api/v4/classes/${classId}/checkInSummary/export?range=${range}&startDate=${startDate}&endDate=${endDate}${
                    opts.grades ? `&grades=${opts.grades}` : ''
                }&${Object.keys(currentFilters)
                    ?.map((filter) => {
                        return `${filter}=${
                            Array.isArray(currentFilters[filter])
                                ? currentFilters[filter].join(',')
                                : currentFilters[filter]
                        }`;
                    })
                    .join('&')}`
            )
            .then((res) => res.data);
    }

    static async getClassSettings({ classId }) {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/classes/${classId}/settings`).then((res) => res.data);
    }

    static async updateClassSettings({ classId, settings }) {
        await Auth.checkCurrentAuthToken();
        return await axios.put(`/api/v4/classes/${classId}/settings`, settings).then((res) => res.data);
    }

    static async getBadges({ classId }) {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/classes/${classId}/badges`).then((res) => res.data);
    }

    static async awardBadges({ classId, badgeId }) {
        await Auth.checkCurrentAuthToken();
        return await axios.post(`/api/v4/classes/${classId}/badges`, { badgeId }).then((res) => res.data);
    }

    static async removeUsers({ classId, userId }) {
        await Auth.checkCurrentAuthToken();
        return await axios.put(`/api/v4/classes/${classId}/remove-users`, { userId }).then((res) => res.data);
    }

    static async addUsers({ classId, userId }) {
        await Auth.checkCurrentAuthToken();
        return await axios.put(`/api/v4/classes/${classId}/add-users`, { userId }).then((res) => res.data);
    }

    static async deleteClass(classId, teacherId) {
        await Auth.checkCurrentAuthToken();
        return await axios.put(`/api/v4/classes/${classId}/archive`);
    }

    static async getCheckInForStudentRequiringAttention(classId, userIds, opts = {}) {
        const { startDate, endDate, range, currentFilters } = opts;
        return await axios
            .post(
                `/api/v4/classes/${classId}/studentCheckInById/?range=${range}&startDate=${startDate}&endDate=${endDate}&${
                    opts?.recommendedUsers ? 'recommendedUsers=true' : ''
                }&${Object.keys(currentFilters)
                    .map((filter) => {
                        return `${filter}=${
                            Array.isArray(currentFilters[filter])
                                ? currentFilters[filter].join(',')
                                : currentFilters[filter]
                        }`;
                    })
                    .join('&')}`,
                userIds
            )
            .then((r) => r.data);
    }
}

export default Classes;
