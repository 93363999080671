import { useEffect, useRef, useCallback, cloneElement } from 'react';

const AnchorTag = ({ children, id, name, pushToArray }) => {
    useEffect(() => {
        pushToArray({ id, name });
    }, [id, name, pushToArray]);
    if (children.length !== 1 && !(typeof children === 'object')) {
        throw new Error('Anchor tag expects singular child element.');
    }
    return (
        <>
            <a className='anchor' href={`#`} id={id}></a>
            {children}
        </>
    );
};

const useAnchorTags = () => {
    const anchorArray = useRef([]);
    const AnchorTagWrapped = useRef(() => {
        return <div></div>;
    });
    const pushToArray = useCallback((itemToPush) => {
        if (anchorArray.current.map((item) => item.id).includes(itemToPush.id))
            throw new Error(`Item with id ${itemToPush.id} already rendered. Anchor ID's must be unique.`);
        anchorArray.current.push(itemToPush);
    }, []);

    useEffect(() => {
        AnchorTagWrapped.current = (props) => {
            return <AnchorTag pushToArray={pushToArray} {...props} />;
        };
    }, [anchorArray, pushToArray]);

    return { AnchorTag: AnchorTagWrapped.current, anchorArray: anchorArray.current };
};

export default useAnchorTags;
