import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConstant } from 'store/actions/constantAction';
import context_image from 'assets/images/checkin_settings/Context_smaller.png';
import foundation from 'assets/images/checkin_settings/FFEL_smaller.png';
import weather from 'assets/images/checkin_settings/WeatherReport_smaller.png';
import illustration from 'assets/images/checkin_settings/Illustrations_smaller.png';
import Classes from 'Api/Classes';
import { Typography } from '@mui/material';
import { isEmpty } from '@lsgo/lsgo-common';

const SETTINGS_TEMPLATE = (existing) => {
    return {
        checkin: {
            appearance: {
                title: 'Appearance (Mode)',
                help: (
                    <>
                        <Typography variant='h5'>Update the style of the check-in.</Typography>
                        <Typography variant='h6' className='mt-2'>
                            Foundation for Emotional Literacy:
                        </Typography>
                        <Typography variant='body1'>
                            6 core emotions to build a fundamental understanding of how to identifying emotions
                        </Typography>
                        <Typography variant='h6' className='mt-2'>
                            Weather Report:
                        </Typography>
                        <Typography variant='body1'>
                            Develop emotional literacy by using emoticons to represent the different emotions in the
                            check-in. The number of emotions presented to students is dependant on their grade.
                        </Typography>
                        <Typography variant='h6' className='mt-2'>
                            Character Illustrations:
                        </Typography>
                        <Typography variant='body1'>
                            Help students recognise the facial expression and body language of different emotions using
                            illustrated characters in the check-in. The number of emotions presented to students is
                            dependant on their grade.
                        </Typography>
                    </>
                ),

                enabled: true,
                images: true,
                subsettings: {
                    foundation_for_emotional_literacy: {
                        title: 'Foundation for Emotional Literacy',
                        help: '6 core emotions to build a fundamental understanding of how to  identifying emotions.',
                        enabled: existing?.checkin?.learning_support?.student_override,
                        image: foundation,
                        key_override: 'learning_support',
                        subkey_override: 'student_override',
                    },
                    weather_report: {
                        title: 'Weather Report',
                        help: 'Develop emotional literacy by using emoticons to represent the different emotions in the check-in. The number of emotions presented to students is dependant on their grade.',
                        enabled:
                            !existing?.checkin?.images?.illustrations &&
                            !existing?.checkin?.learning_support?.student_override &&
                            !existing?.checkin?.appearance?.nsw_pilot,
                        image: weather,
                        key_override: 'images',
                        subkey_override: 'illustrations',
                    },
                    illustrations: {
                        title: 'Character Illustrations',
                        help: 'Help students recognise the facial expression and body language of different emotions using illustrated characters in the check-in. The number of emotions presented to students is dependant on their grade.',
                        enabled:
                            existing?.checkin?.images?.illustrations &&
                            !existing?.checkin?.learning_support?.student_override &&
                            !existing?.checkin?.appearance?.nsw_pilot,
                        image: illustration,
                        key_override: 'images',
                        subkey_override: 'illustrations',
                    },
                    nsw_pilot: {
                        enabled:
                            existing?.checkin?.appearance?.nsw_pilot &&
                            !existing?.checkin?.learning_support?.student_override,
                        hidden: true,
                    },
                },
            },
            comments: {
                title: 'Ask students to provide context to their check-in response',
                help: 'After students identify how they are feeling, enable this option on to gather information on why students may be feeling that way.',
                enabled: true,
                image: context_image,
                subsettings: {
                    lessons: {
                        title: 'When a student is logged in',
                        help: 'When enabled, students will be asked to provide additional context to their check-in response when they are logged into an individual device.',
                        enabled: existing?.checkin?.comments?.lessons || existing?.WeatherReportComments,
                    },
                    rollcall: {
                        title: 'In the Class Check-in',
                        help: 'When enabled, students will be asked to provide additional context to their check-in response during a whole class check-in. e.g the interactive whiteboard',
                        enabled: existing?.checkin?.comments?.rollcall,
                    },
                },
            },
            checkin_before_lesson: {
                title: 'Include a check-in during digital lessons',
                help: 'When enabled, students will be asked to complete a check-in as part of their Life Skills GO digital lessons.',
                enabled: existing?.checkin?.checkin_before_lesson || existing.LessonWeatherReport,
            },
            enable_video_activity: {
                title: 'Include a prepare for learning video activity after students check-in on individual devices',
                help: 'After a student completes an Emotion Check-in when logged in on an individual device, play a Prepare for Learning video activity and perform a post-activity check-in.',
                enabled: !isEmpty(existing?.checkin?.enable_video_activity)
                    ? existing?.checkin?.enable_video_activity
                    : true,
            },
            learning_support: {
                title: 'Foundation for Emotional Literacy Mode',
                enabled: true,
                hidden: true,
                subsettings: {
                    student_override: {
                        title: 'Turn on for all students (does not affect individual student setting)',
                        enabled: existing?.checkin?.learning_support?.student_override,
                    },
                    prepare_for_learning: {
                        title: 'During a Prepare for Learning',
                        enabled: existing?.checkin?.learning_support?.student_override,
                    },
                },
            },
            images: {
                title: 'Check-in Illustrations',
                enabled: true,
                hidden: true,
                subsettings: {
                    illustrations: {
                        title: 'Enable this setting to use character illustrations for the check-in. Disable this setting to use the Emoji style check-in.',
                        enabled: existing?.checkin?.images?.illustrations,
                    },
                },
            },
        },
        students: {
            student_management_dashboard: {
                title: 'Use our new student dashboard with bulk editing, selection and download of student details.',
                enabled: existing?.students?.student_management_dashboard,
            },
        },
        dashboard: {
            daily_summary: {
                enabled: existing?.dashboard?.daily_summary !== undefined ? existing?.dashboard?.daily_summary : true,
            },
            comments_summary_graph_view: {
                enabled:
                    existing?.dashboard?.comments_summary_graph_view !== undefined
                        ? existing?.dashboard?.comments_summary_graph_view
                        : false,
            },
        },
    };
};

const useClassSettings = (classId, subsetting) => {
    const classSettings = useSelector((state) => state.constants.classSettings);
    const classCheckinSettings = useSelector((state) => state.classes?.currentClass?.classSettings?.checkin);
    const dispatch = useDispatch();
    const [settings, setSettings] = useState({ checkin: {}, dashboard: {} });

    useEffect(() => {
        const fetchData = async () => {
            if (classSettings?.[classId]) {
                setSettings(classSettings[classId]);
            } else {
                if (classId) {
                    const response = await Classes.getClassSettings({ classId });
                    const template = SETTINGS_TEMPLATE(response.classSettings || {});
                    const settings = {
                        checkin: template.checkin,
                        dashboard: template.dashboard,
                    };
                    dispatch(updateConstant('classSettings', settings, classId));
                    setSettings(settings);
                }
            }
        };

        fetchData();
    }, [classSettings, classId, dispatch]);

    const saveSettings = (settings) => {
        const saveTemplate = {
            checkin: {
                appearance: {
                    // foundation_for_emotional_literacy,
                    // weather_report,
                    // illustrations,
                    nsw_pilot: settings?.checkin?.appearance?.subsettings?.nsw_pilot?.enabled,
                },
                comments: {
                    lessons: settings.checkin.comments.subsettings.lessons.enabled,
                    rollcall: settings.checkin.comments.subsettings.rollcall.enabled,
                },
                checkin_before_lesson: settings.checkin.checkin_before_lesson.enabled,
                enable_video_activity: settings.checkin.enable_video_activity.enabled,
                learning_support: {
                    student_override: settings.checkin.learning_support.subsettings.student_override.enabled,
                    prepare_for_learning: settings.checkin.learning_support.subsettings.student_override.enabled,
                },
                images: {
                    illustrations: settings.checkin.images.subsettings.illustrations.enabled,
                },
            },
            students: {
                student_management_dashboard: settings?.students?.student_management_dashboard?.enabled,
            },
            dashboard: {
                daily_summary: settings?.dashboard?.daily_summary?.enabled,
                comments_summary_graph_view: settings?.dashboard?.comments_summary_graph_view?.enabled,
            },
        };
        Classes.updateClassSettings({ classId, settings: saveTemplate }).then((newClassSetting) => {
            const newSettingObject = SETTINGS_TEMPLATE(newClassSetting);
            dispatch(updateConstant('classSettings', newSettingObject));
        });
    };

    return [settings, saveSettings, classCheckinSettings];
};

export default useClassSettings;
