import axios from 'config/axios';
import setAuthToken from 'utils/setAuthToken';

// Handle JWT token decode and date checks
import { parseJwt } from 'utils';
import moment from 'moment';
import { ampli } from 'ampli';

class Auth {
    // Check auth token for expiry and existence to run before all API calls
    static async checkCurrentAuthToken() {
        const currentAuthToken = localStorage.getItem('authToken');
        if (currentAuthToken === null) {
            await Auth.setNewAuthToken();
        } else {
            const decoded = await parseJwt(currentAuthToken);
            const expired = moment().isAfter(moment(decoded.exp * 1000));
            if (expired) {
                await Auth.setNewAuthToken();
            } else {
                return;
            }
        }
    }

    static async setNewAuthToken() {
        return await Auth.userV2({ redirect: false, history: null });
    }

    static async login(authData, loginMethod) {
        ampli.buttonClicked({
            button_name: loginMethod === 'googleLogin' ? 'google_sso' : 'lsgo_login',
            location: 'Teacher Login',
        });
        if (loginMethod === 'googleLogin') {
            return await axios.post('/api/v4/auth/google-login', 'idToken=' + authData);
        } else {
            return await axios.post('/api/v4/auth/login', { ...authData, username: authData.email }).then((result) => {
                return result;
            });
        }
    }

    static async userV2({ redirect, history, redirectUri, lsgoRT }) {
        if (lsgoRT) {
            axios.defaults.headers.common['x-refresh-token'] = lsgoRT;
        }
        return await axios
            .get('/api/v4/auth/user')
            .then((resp) => {
                if (resp && resp.data) {
                    const { success, token } = resp.data;
                    if (success) {
                        setAuthToken(token);
                        if (redirect && history) history.push(redirectUri || `/teacher`);
                        if (redirect && !history) window.location.href = redirectUri || `/teacher`;
                        return token;
                    }
                } else if (resp && resp.response && +resp.response.status === 401) {
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('lsgoRT');
                }
            })
            .catch((err) => {
                console.log(err);
                return { error: 'Something went wrong' };
            });
    }

    static async register(userData) {
        return await axios.post('/api/v4/auth/finish-registration', userData);
    }

    static async validateToken(token) {
        return await axios
            .post(`/api/v4/auth/verify-signup-token`, { signupToken: token })
            .then((res) => {
                return +res.status === 200 ? res.data : res.data.error ? { error: res.data.error } : false;
            })
            .catch((err) => {
                //for invalid token handling on frontend
                return false;
            });
    }

    static async verifyResetToken(token) {
        return await axios
            .post(`/api/v4/auth/verify-signup-token`, { resetPasswordToken: token })
            .then((res) => {
                return res;
            })
            .catch(() => {
                //for invalid token handling on frontend
                return false;
            });
    }

    static async forgotPassword(data) {
        return await axios.post('/api/v4/auth/forgot-password', data);
    }

    static async resetPassword(data) {
        return await axios.post('/api/v4/auth/reset-password', data);
    }

    static async trialRegister(registerData) {
        return await axios.post(`/api/v3/users/signup`, registerData);
    }

    static async updateTFA(user) {
        return await axios.post(`/api/v3/auth/2fa/register`, user);
    }
}

export default Auth;
