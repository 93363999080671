import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

import background from '../../assets/images/student/StudentViewFinalSmall.jpg';
import logo from '../../assets/images/logo.png';
import Footer from '../../components/UI/Footer';
import PrivateRoute from 'routing/PrivateRoute';

import { ToastContainer } from 'react-toastify';
import '../../containers/Student/student.scss';
import { connect } from 'react-redux';
import { isEmpty } from '@lsgo/lsgo-common';
import ToastifyNotification from 'components/UI/Notifications/ToastifyNotification';
import { Button } from '@mui/material';
import { ampli } from 'ampli';
import { CustomColors } from 'assets/customisedMUI';

const EmptyLayout = ({
    component: Component,
    noBox,
    auth,
    serverNotifications,
    sentralUnsafeRoute,
    schoolBytesUnsafeRoute,
    history,
    attendanceData,
    ...rest
}) => {
    const [toastType, setToastType] = useState(null);
    const path = rest.location.pathname ? rest.location.pathname : '';

    useEffect(() => {
        if (serverNotifications && !isEmpty(serverNotifications.message)) {
            serverNotifications.type === 'warning'
                ? setToastType('server-notification warning-notification')
                : setToastType('server-notification success-notification');
        }
    }, [serverNotifications]);

    useEffect(() => {
        const { teacher } = auth;

        window.Appcues &&
            window.Appcues.identify(`teacher-${teacher?.id}`, {
                name: teacher?.name,
                platform: window.location.hostname,
                camapaign: teacher?.campaign,
                lastLogIn: teacher?.lastLogin || 0,
                signUpDate: teacher?.createdAt,
                isSentralIntegration: teacher?.isSentralIntegration,
                license: { id: teacher?.user_groups?.licenseId || '' },
            });
    }, [auth]);

    return (
        <>
            <div
                className={`student-main-background container-fluid studentv2 ${
                    schoolBytesUnsafeRoute
                        ? 'schoolBytes'
                        : sentralUnsafeRoute || path.includes('sentral')
                        ? 'sentral'
                        : ''
                }`}
                style={{
                    backgroundImage: 'url(' + background + ')',
                }}
            >
                <div className='row'>
                    <div className='col-12 top-bar'>
                        <div className='row'>
                            <div className='col-3 main-logo'>
                                <Link to='/teacher'>
                                    <img alt='LSGO ' id='lsgo-logo' src={logo} />
                                </Link>
                            </div>
                            {attendanceData?.isAttendance && (
                                <div className='col-9' style={{ textAlign: 'right' }}>
                                    <Button
                                        variant='contained'
                                        style={{ backgroundColor: CustomColors('blue', 'darkest'), color: 'white' }}
                                        size='large'
                                        onClick={() => {
                                            ampli.buttonClicked({
                                                button_name: schoolBytesUnsafeRoute
                                                    ? 'school_bytes_complete_attendance'
                                                    : 'sentral_complete_attendance',
                                                location: 'Roll Call',
                                            });
                                            let parentWindow = window.parent;

                                            console.log(
                                                {
                                                    action: 'onRollComplete',
                                                    present: attendanceData.students
                                                        .filter((s) => attendanceData.completed.includes(s.id))
                                                        ?.map((s) => s.sisId),
                                                    absent: attendanceData.students
                                                        .filter((s) => !attendanceData.completed.includes(s.id))
                                                        ?.map((s) => s.sisId),
                                                },
                                                'ATTENDANCE'
                                            );

                                            parentWindow.postMessage(
                                                {
                                                    action: 'onRollComplete',
                                                    present: attendanceData.students
                                                        .filter((s) => attendanceData.completed.includes(s.id))
                                                        ?.map((s) => s.sisId),
                                                    absent: attendanceData.students
                                                        .filter((s) => !attendanceData.completed.includes(s.id))
                                                        ?.map((s) => s.sisId),
                                                },
                                                '*'
                                            );
                                        }}
                                    >
                                        {schoolBytesUnsafeRoute && (
                                            <i className='fa fa-check' style={{ color: 'white', marginRight: 20 }} />
                                        )}
                                        {schoolBytesUnsafeRoute ? 'Mark' : 'Complete Attendance'}
                                    </Button>
                                    {!schoolBytesUnsafeRoute && (
                                        <Button
                                            variant='outlined'
                                            style={{
                                                borderColor: CustomColors('blue', 'darkest'),
                                                color: CustomColors('blue', 'darkest'),
                                            }}
                                            size='large'
                                            onClick={() => {
                                                ampli.buttonClicked({
                                                    button_name: schoolBytesUnsafeRoute
                                                        ? 'school_bytes_cancel_attendance'
                                                        : 'sentral_cancel_attendance',
                                                    location: 'Roll Call',
                                                });
                                                let parentWindow = window.parent;
                                                parentWindow.postMessage(
                                                    {
                                                        action: 'onRollCancel',
                                                    },
                                                    '*'
                                                );
                                            }}
                                        >
                                            Cancel Attendance
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='col-12 main-studnet-content'></div>
                <div id='canvas-wrapper2'>
                    {noBox ? (
                        <PrivateRoute
                            isAuthenticated={auth.isAuthenticated}
                            routeType={
                                path.includes('rollcall') || path.includes('quick-weather-report')
                                    ? 'private'
                                    : schoolBytesUnsafeRoute
                                    ? 'schoolBytes'
                                    : sentralUnsafeRoute
                                    ? 'sentral'
                                    : 'guest'
                            }
                            {...rest}
                            render={(props) => <Component {...props} />}
                        />
                    ) : (
                        <div className={`student-box2`}>
                            <PrivateRoute
                                isAuthenticated={auth.isAuthenticated}
                                routeType={
                                    path.includes('rollcall') || path.includes('quick-weather-report')
                                        ? 'private'
                                        : schoolBytesUnsafeRoute
                                        ? 'schoolBytes'
                                        : sentralUnsafeRoute || path.includes('sentral')
                                        ? 'sentral'
                                        : 'guest'
                                }
                                {...rest}
                                render={(props) => <Component {...props} />}
                            />
                        </div>
                    )}
                </div>
                {/* Button Specifically for quick Emotion Check-in */}
                <div className='student-action-buttons text-center'>
                    <div className='actionButtons'>
                        {([
                            '/teacher/quick-weather-report/:classId',
                            '/teacher/rollcall/:classId',
                            '/sentral/checkin',
                        ].includes(rest.path) ||
                            rest.location.search.includes('action=sentralAdditionalCheckin')) && (
                            <div
                                className='btn btn-lsgo btn-rounded waves-light mt-2 mb-4'
                                onClick={() => {
                                    if (rest.location.search.includes('action=sentralAdditionalCheckin')) {
                                        let parentWindow = window.parent;
                                        return parentWindow.postMessage(
                                            {
                                                action: 'onAdditionalComplete',
                                            },
                                            '*'
                                        );
                                    }
                                    history.action === 'PUSH' ? history.goBack() : history.push('/');
                                }}
                            >
                                Back to Dashboard
                            </div>
                        )}
                        {}
                    </div>
                </div>

                <ToastContainer toastClassName={toastType} autoClose={false} />
                <div>
                    <ToastifyNotification />
                </div>
                <Footer />
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    serverNotifications: state.ui.serverNotifications,
    attendanceData: state.classes.attendance,
});

export default withRouter(connect(mapStateToProps)(EmptyLayout));
