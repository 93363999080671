import axios from 'config/axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConstant } from 'store/actions/constantAction';

const url = '/api/v3/constants/term-dates';

function weekOfYear(date) {
    var year = new Date(date.getFullYear(), 0, 1);
    var days = Math.floor((date - year) / (24 * 60 * 60 * 1000));
    var week = Math.ceil((date.getDay() + 1 + days) / 7);
    return week;
}

const daysOfWeek = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
};

let loading = false;

const useTermDates = () => {
    const term_dates = useSelector((state) => state.constants.term_dates);
    const term_dates_loading = useSelector((state) => state.constants.term_dates_loading);
    const dispatch = useDispatch();
    const [terms, setTerms] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (term_dates) {
                setTerms(term_dates);
            } else {
                if (loading) {
                    return;
                } else {
                    dispatch(updateConstant('term_dates_loading', true));
                    loading = true;
                    const response = await axios.get(url);
                    if (response.data) {
                        dispatch(updateConstant('term_dates', response.data));
                        setTerms(response.data);
                        dispatch(updateConstant('term_dates_loading', false));
                        loading = false;
                    }
                }
            }
        };

        fetchData();
    }, [term_dates, dispatch, term_dates_loading]);

    const convertDateStringToTermDate = (dateString) => {
        // TODO set a global date format object for display and processing (like moment used to do)
        const dateParts = dateString.split('/');

        const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        const dateWeekOfYear = weekOfYear(date);
        const dateDayOfWeek = date.getDay();
        const term = terms?.find((term) => term.from_week <= dateWeekOfYear && dateWeekOfYear <= term.to_week);
        if (!term) {
            return dateString;
        } else {
            return `${daysOfWeek[dateDayOfWeek]}, Week ${dateWeekOfYear - term.from_week + 1} Term ${
                term.termNumber
            }, ${dateParts[2]}`;
        }
    };

    return { terms, convertDateStringToTermDate };
};

export default useTermDates;
