import { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { Tab, Tabs } from '@mui/material';
import { toggleAdminView, toggleFeatureFlag } from 'store/actions/uiActions';
import { ampli } from 'ampli';
import theme from 'assets/customisedMUI/theme';

const tabOptions = [
    { title: 'Dashboard', link: '/teacher' },
    { title: 'Lessons', link: '/teacher/classes/dashboard/curriculum', requiresClass: true },
    { title: 'Reporting', link: '/teacher/classes/dashboard/reporting', requiresClass: true },
    { title: 'Students', link: '/teacher/classes/dashboard/students', requiresClass: true },
];

const NavTabs = (props) => {
    const { location, hidden, history, toggleAdminView, scrollable } = props;
    const [currentTab, setCurrentTab] = useState(0);
    const currentTabOptions = tabOptions.filter((option) =>
        option.featureFlag ? props[`featureFlag${option.featureFlag}`] : true
    );

    const handlePageChange = useCallback(
        (e, newTab) => {
            ampli.buttonClicked({ button_name: currentTabOptions[newTab].title, location: 'Top Navigation Bar' });
            history.push(currentTabOptions[newTab].link);
        },
        [currentTabOptions, history]
    );

    useEffect(() => {
        const newTab = tabOptions.find((option) =>
            option.link === '/teacher' ? location.pathname === option.link : location.pathname.includes(option.link)
        );
        const newTabIndex = tabOptions.indexOf(newTab);
        if (newTabIndex >= 0) {
            setCurrentTab(newTabIndex);
        }
    }, [location, toggleAdminView]);

    return hidden ? null : (
        <Tabs
            scrollButtons={scrollable}
            allowScrollButtonsMobile
            value={currentTab}
            onChange={handlePageChange}
            style={{ flex: '1 1 411px' }}
            variant='scrollable'
        >
            {currentTabOptions.map((tab, index) => {
                const selected = index === currentTab;
                return (
                    <Tab
                        key={index}
                        style={{ color: selected ? '#ffffff' : 'white', opacity: selected ? 1 : 0.7 }}
                        label={tab.title}
                        disabled={tab.requiresClass && !props.hasClass}
                        sx={{
                            textTransform: 'none',
                            minWidth: 72,
                            fontWeight: theme.typography.fontWeightRegular,
                            marginRight: theme.spacing(3),
                            fontFamily: 'Museo700',
                            opacity: 0.7,
                            '&:hover': {
                                color: 'white',
                                opacity: 1,
                            },
                            '&:focus': {
                                color: 'white',
                            },
                            '& > span': {
                                fontFamily: 'Museo700',
                            },
                        }}
                    ></Tab>
                );
            })}
        </Tabs>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAdminView: (adminView) => dispatch(toggleAdminView(adminView)),
        toggleFeatureFlag: (flag, checked, allowed) => dispatch(toggleFeatureFlag(flag, checked, allowed)),
    };
};

export default withRouter(
    connect((state) => {
        return {
            featureFlagCheckinCustomisation: state?.ui?.featureFlag?.customiseCheckin?.accessAllowed || false,
            featureFlagDashboardV5: state?.ui?.featureFlag?.dashboardV5?.accessAllowed || false,
        };
    }, mapDispatchToProps)(NavTabs)
);
