import Axios from 'config/axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConstant } from 'store/actions/constantAction';

const url = '/api/v4/groups/classes';

let loading = false;

const useClasses = () => {
    const CLASSES = useSelector((state) => state.constants.classes);
    const dispatch = useDispatch();

    const [classes, setClasses] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (CLASSES) {
                setClasses(CLASSES);
            } else {
                if (loading) return;
                loading = true;
                const response = await Axios.get(url);
                const data = response.data.map((c) => {
                    return { value: String(c.classId), label: c.className };
                });
                dispatch(updateConstant('classes', data));
                setClasses(data);
                loading = false;
            }
        };

        fetchData();
    }, [CLASSES, dispatch]);

    return classes;
};

export default useClasses;
