import Axios from 'config/axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConstant } from 'store/actions/constantAction';

const url = '/api/v4/constants/schoolconfig/';

let loading = false;

const useSchoolConfig = () => {
    const config = useSelector((state) => state.constants.school_config);
    const dispatch = useDispatch();

    const [schoolConfig, setSchoolConfig] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (config) {
                setSchoolConfig(config);
            } else {
                if (loading) return;
                loading = true;
                const response = await Axios.get(url);
                const data = response.data;
                dispatch(updateConstant('school_config', data));
                setSchoolConfig(data);
                loading = false;
            }
        };

        fetchData();
    }, [config, dispatch]);
    return { schoolConfig };
};

export default useSchoolConfig;
