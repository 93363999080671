import axios from 'config/axios';
import { saveAs } from 'file-saver';
import Auth from './Auth';

export default class Units {
    static async createSchoolUnit(schoolId, unit) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .post(`/api/v4/lessons/school/unit/create`, {
                unitData: { ...unit },
            })
            .then((res) => res.data);
    }

    static async editUnit(unitId, unit) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .post(`/api/v4/lessons/units/${unitId}`, {
                ...unit,
            })
            .then((res) => res.data);
    }

    static async paidLessonAccess(schoolId) {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/constants/options/paid-lesson-access/${schoolId}`).then((res) => res.data);
    }

    static async paidUnitAccess() {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/constants/options/paid-unit-access`).then((res) => res.data);
    }

    static async getTemplateUnitsForSchool() {
        await Auth.checkCurrentAuthToken();
        return await axios.get(`/api/v4/lessons/units`).then((res) => res.data.units);
    }
}
