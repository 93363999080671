import { UPDATE_CONSTANT } from '../actions/actionTypes';
const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        // Add new unit data to the redux state if it does not already exist. If it does, overwrite the existing store data with the new data.
        case UPDATE_CONSTANT:
            return {
                ...state,
                [action.payload.key]: action.payload.subkey
                    ? { [action.payload.subkey]: action.payload.value }
                    : action.payload.value,
            };

        default:
            return state;
    }
}
