import Axios from 'config/axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConstant } from 'store/actions/constantAction';

const url = '/api/v4/groups/groupUsers?include=teachers';
let loading = false;

const useSchoolTeachers = () => {
    const TEACHERS = useSelector((state) => state.constants.teachers);
    const dispatch = useDispatch();

    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (TEACHERS) {
                setTeachers(TEACHERS);
            } else {
                if (loading) return;
                loading = true;
                const response = await Axios.get(url);
                const data = response.data.teachers;
                dispatch(updateConstant('teachers', data));
                setTeachers(data);
                loading = false;
            }
        };

        fetchData();
    }, [TEACHERS, dispatch]);

    return teachers;
};

export default useSchoolTeachers;
