import Axios from 'config/axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConstant } from 'store/actions/constantAction';

const url = '/api/v4/groups/groupUsers?include=students';
let loading = false;

const useSchoolStudents = () => {
    const STUDENTS = useSelector((state) => state.constants.students);
    const dispatch = useDispatch();

    const [students, setStudents] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (STUDENTS) {
                setStudents(STUDENTS);
            } else {
                if (loading) return;
                loading = true;
                const response = await Axios.get(url);
                const data = response.data.students;
                dispatch(updateConstant('students', data));
                setStudents(data);
                loading = false;
            }
        };

        fetchData();
    }, [STUDENTS, dispatch]);

    return students;
};

export default useSchoolStudents;
