import { createStore, createHook } from 'react-sweet-state';
import Api from 'Api/Constants';
import { defaults } from 'react-sweet-state';
defaults.devtools = true;

const Store = createStore({
    // value of the store on initialisation
    initialState: {
        stages: {
            0: 'F',
            1: '1-2',
            2: '3-4',
            3: '5-6',
            4: '7-8',
            5: '9-10',
            6: '11-12',
        },
        grades: [
            { label: 'All Grades', value: -1 },
            { label: 'F', value: 0 },
            { label: 1, value: 1 },
            { label: 2, value: 2 },
            { label: 3, value: 3 },
            { label: 4, value: 4 },
            { label: 5, value: 5 },
            { label: 6, value: 6 },
            { label: 7, value: 7 },
            { label: 8, value: 8 },
            { label: 9, value: 9 },
            { label: 10, value: 10 },
            { label: 11, value: 11 },
            { label: 12, value: 12 },
        ],
        VIDEO_TAGS: {
            STAGE_12: 'K-2',
            STAGE_3: '3-6',
            STAGE_4: '7-8',
            STAGE_5: '9-10',
            STAGE_6: '11-12',
        },
        stageZeroLabel: 'Foundation',
        loaded: false,
        loading: false,
    },

    // actions that trigger store mutation
    actions: {
        load:
            () =>
            async ({ setState, getState }) => {
                if (getState().loading === true || getState().loaded) return;

                setState({
                    loading: true,
                });

                const grades = await Api.getGrades();

                setState({
                    loading: false,
                    loaded: true,
                    stages: grades.data.stages,
                    grades: grades.data.grades,
                    stageZeroLabel: grades.data.stageZeroLabel,
                    VIDEO_TAGS: grades.data.VIDEO_TAGS,
                });
            },
    },
    // optional, mostly used for easy debugging
    name: 'grades',
});

const useGrades = createHook(Store);

export default useGrades;
