import React from 'react';
import FourOhFour from 'components/UI/404';
import { withRouter } from 'react-router-dom';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidMount() {
        const { history } = this.props;

        this.unlisten = history.listen((location, action) => {
            if (this.state.hasError) {
                this.setState({
                    hasError: false,
                });
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <FourOhFour error />;
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
