// const ENV_GOOGLE_ANALYTICS_ID = window.location.hostname.includes('-staging') ? 'UA-129313868-2' : 'UA-129313868-4';
const ENV_GOOGLE_ANALYTICS_ID = window.location.hostname.includes('-staging') ? 'G-BWTGG3ZJJB' : 'G-9QBTQ5F0TB';
export const ID = ENV_GOOGLE_ANALYTICS_ID;

export const EVENTS = {
    LOG_IN: 'LogIn',
    CREATE_CLASS: 'CreateClass',
    EDIT_CLASS: 'EditClass',
    DELETE_CLASS: 'DeleteClass',
    VIEW_NOTIFICATIONS: 'ViewNotifications',
    READ_NOTIFICATIONS: 'ReadNotifications',
    UPDATE_PROFILE: 'UpdateProfile',
    UPDATE_PASSWORD: 'UpdatePassword',
    PURCHASE_SUBSCRIPTION: 'PurchaseSubscription',
    CANCEL_SUBSCRIPTION: 'CancelSubscription',
    EXPORT_WEATHER_REPORT: 'ExportWeatherReport',
    ADD_UNIT: 'AddUnit',
    EDIT_UNIT: 'EditUnit',
    DELETE_UNIT: 'DeleteUnit',
    VIEW_UNIT_DETAILS: 'ViewUnitDetails',
    PREVIEW_LESSON: 'PreviewLesson',
    VIEW_LESSON_CONTENT: 'ViewLessonContent',
    START_LESSON: 'StartLesson',
    EXPORT_CURRICULUM_REPORT: 'ExportCurriculumReport',
    EXPORT_LESSON_REPORT: 'ExportLessonReport',
    CARD_RESOURCES: 'CardResources',
    VIDEO_RESOURCES: 'VideoResources',
    VIEW_CURRICULUM_MAP: 'ViewCurriculumMap',
    VIEW_MASTERCLASSES: 'ViewMasterClasses',
    CREATE_TEACHER: 'CreateTeacher',
    LOGIN_AS_TEACHER: 'LoginAsTeacher',
    EDIT_TEACHER: 'EditTeacher',
    RESENT_INVITES: 'ResendInvites',
    DELETE_TEACHER: 'DeleteTeacher',
    LOGIN_AS_STUDENT: 'LoginAsStudent',
    EDIT_STUDENT: 'EditStudent',
    AWARD_STUDENT_BADGES: 'AwardStudentBadges',
    AWARD_CLASS_BADGES: 'AwardClassBadges',
    DELETE_STUDENT: 'DeleteStudent',
    CREATE_NEW_SCHOOL_UNIT: 'CreateNewSchoolUnit',
    ASSIGN_CLASSES_SCHOOL_UNIT: 'AssignClassesSchoolUnit',
    UPDATE_STUDENT_CLASS_LIST: 'UpdateStudentClassList',
};

export const CATEGORIES = {
    TEACHER_ACTIONS: 'TeacherActions',
};
