import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { removeAlert } from 'store/actions/alertActions';
import axios from 'config/axios';

const StudentAlert = ({ messages, removeAlert }) => {
    const [playingAudio, setPlayingAudio] = useState(null);

    useEffect(() => {
        async function getAudioUrl() {
            if (messages.length > 0) {
                if (messages[0].text) {
                    await axios
                        .post('/api/v4/lessons/audio/audible', {
                            text: messages[0].text,
                            voice: messages[0].voice,
                        })
                        .then((result) => {
                            if (!playingAudio) {
                                setPlayingAudio(result.data.audioUrl);
                            }
                        })
                        .catch((err) => {
                            console.log('Error generating audio ', err);
                        });
                } else {
                    setPlayingAudio(messages[0].url);
                }
            }
        }

        getAudioUrl();
        //eslint-disable-next-line
    }, [messages]);

    const onEnded = () => {
        setPlayingAudio(null);
        removeAlert();
    };

    // return <>{audioUrls.length > 0 && <ReactAudioPlayer src={audioUrls[0]} onEnded={onEnded} autoplay />}</>;
    return <>{playingAudio && <audio src={playingAudio} onEnded={onEnded} autoPlay />}</>;
};

const mapStateToProps = (state) => {
    return {
        messages: state.alerts.messages,
    };
};

const mapDispatchToProps = {
    removeAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentAlert);
