import {
    ADD_CLASS,
    REMOVE_CLASS,
    EDIT_CLASS,
    SHOW_CLASS_LOADER,
    HIDE_CLASS_LOADER,
    SAVE_CURRICULUM,
    SET_CURRENT_CLASS,
    ADD_STUDENT_TO_CLASS_LIST,
    ADD_CLASS_V2,
    SET_CLASS,
    ADD_TEACHERS_TO_CLASS,
    ADD_STUDENTS_TO_CLASS,
    REMOVE_STUDENT,
    EDIT_STUDENT,
    REMOVE_STUDENTS_FROM_CLASS,
    UPDATE_CLASS_USERS,
    BULK_REMOVE_STUDENTS,
    ADMIN_CLASS_VIEW,
    ATTENDANCE,
} from '../actions/actionTypes';

import cloneDeep from 'lodash/cloneDeep';
import { getArrayOfUniqueObjects } from 'utils/tools';

import { isEmpty } from '@lsgo/lsgo-common';

const initialState = {
    classes: [],
    currentClass: null,
    loading: false,
    adminClassView: { status: false, classId: null, className: null },
    attendance: { isAttendance: false, students: [], completed: [] },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ATTENDANCE: {
            return {
                ...state,
                attendance: {
                    isAttendance: action.payload.isAttendance,
                    students: action.payload.students,
                    completed: action.payload.completed,
                },
            };
        }

        case SAVE_CURRICULUM: {
            const classes = [...state.classes];
            const classId = Number(state.currentClass);

            for (let cl of classes) {
                if (String(cl.classId) === String(classId)) {
                    if (cl.units === undefined) {
                        Object.assign(cl, { units: [] });
                    } else {
                        let currentUnit = cl.units.find((u) => +u.unitId === +action.payload.unitId);
                        if (currentUnit) {
                            currentUnit = { ...currentUnit, ...action.payload };
                        } else {
                            cl.units.push(action.payload);
                        }
                    }
                }
            }

            return {
                ...state,
                classes: cloneDeep(classes),
            };
        }
        case SHOW_CLASS_LOADER: {
            return {
                ...state,
                loading: true,
            };
        }
        case HIDE_CLASS_LOADER: {
            return {
                ...state,
                loading: false,
            };
        }
        case ADMIN_CLASS_VIEW: {
            return {
                ...state,
                adminClassView: {
                    status: true,
                    classId: action.payload.classId,
                    className: action.payload.className,
                },
            };
        }
        case ADD_STUDENT_TO_CLASS_LIST: {
            const selectedClass = state.classes.find((c) => c.classId === action.payload.classId);
            let { students } = selectedClass;
            if (!students) {
                students = [];
            }
            const { name, password, studentId, username, gradeId, createdAt } = action.payload.student;
            const ClassesStudents = { studentId, classId: action.payload.classId, createdAt };
            const student = { name, password, studentId, username, ClassesStudents, gradeId };
            students.push(student);
            selectedClass.students = students;
            const studentGrades = selectedClass.students.map((student) => {
                return Math.floor((student.gradeId + 1) / 2);
            });
            const studentGradesUnique = studentGrades.filter((a, i) => studentGrades.indexOf(a) === i);
            selectedClass.classGrades = studentGradesUnique;
            const newClasses = cloneDeep(state.classes);
            return {
                ...state,
                classes: newClasses,
            };
        }
        case REMOVE_STUDENT: {
            const currentClass = state.classes.find((c) => String(c.classId) === String(state.currentClass));
            const { Students } = currentClass;
            const newStudentsArray = [...Students];
            newStudentsArray.splice(
                newStudentsArray.findIndex((s) => +s.id === +action.payload),
                1
            );
            currentClass.Students = newStudentsArray;
            const newClassArray = [...state.classes];
            return { ...state, classes: newClassArray };
        }
        case BULK_REMOVE_STUDENTS: {
            const currentClass = state.classes.find((c) => String(c.classId) === String(state.currentClass));
            const { Students } = currentClass;
            const newStudentsArray = [...Students];
            action.payload.forEach((student) => {
                newStudentsArray.splice(
                    newStudentsArray.findIndex((s) => +s.id === +student),
                    1
                );
            });
            currentClass.Students = newStudentsArray;
            const newClassArray = [...state.classes];
            return { ...state, classes: newClassArray };
        }

        case ADD_CLASS: {
            //new class data

            const newClass = action.payload;

            //get copy of the current classes list
            const _newClasses = [...state.classes];

            //add the new class to the begging of the list
            _newClasses.push(newClass);

            localStorage.setItem('currentClassId', String(newClass.classId));
            console.log(newClass, 'newclasss');

            return {
                ...state,
                classes: _newClasses,
                currentClass: newClass,
            };
        }

        case ADD_CLASS_V2: {
            //new class data
            const nc = action.payload;

            if (state.classes.find((cls) => cls.classId === nc.classId) === undefined) {
                //add the new class to the begging of the list
                return {
                    ...state,
                    classes: [...state.classes, nc],
                };
            }

            return {
                ...state,
            };
        }

        case EDIT_CLASS: {
            let _classes = [...state.classes];
            let { classId, className, Students, teachers } = action.payload;

            if (!isEmpty(_classes) && _classes.find((c) => c.classId === classId)) {
                for (let item of _classes) {
                    if (item.classId === classId) {
                        item.className = className;
                        if (Students) {
                            item.Students = Students;
                        }
                        if (teachers) {
                            item.teachers = teachers;
                        }
                        break;
                    }
                }
            } else {
                _classes = !isEmpty(_classes)
                    ? [..._classes, { classId, className, Students, teachers }]
                    : [{ classId, className, Students, teachers }];
            }

            let currentClass = state.currentClass;
            if (currentClass?.classId === classId) currentClass = { classId, className };

            return {
                ...state,
                classes: _classes,
                ...(currentClass && { currentClass }),
            };
        }

        case SET_CLASS: {
            let _clsss = [...state.classes];
            let cl = _clsss.find((c) => c.classId === action.payload.classId);
            const nc2 = { ...cl, ...action.payload };
            _clsss.splice(_clsss.indexOf(cl), 1, nc2);
            return {
                ...state,
                classes: cloneDeep(_clsss),
            };
        }

        case REMOVE_CLASS: {
            let classId = action.payload;

            const classes = state.classes.filter((c) => c.classId !== classId);
            const currentClass = classes.length > 0 ? classes[0] : null;

            currentClass && localStorage.setItem('currentClassId', +currentClass.classId);
            return {
                ...state,
                classes,
                currentClass,
            };
        }

        case SET_CURRENT_CLASS: {
            let classId = action.payload;
            localStorage.setItem(
                'currentClassId',
                typeof classId === 'object' ? String(classId.classId) : String(classId)
            );
            console.log(classId, 'SET CURRENT CLASS');
            return {
                ...state,
                currentClass: classId,
            };
        }

        case UPDATE_CLASS_USERS: {
            let { classId, users, actionType } = action.payload;
            const currentClass = {
                ...state.adminClassView,
                ...state.classes.find((c) => String(c.classId) === String(classId)),
            };
            const index = state.classes.findIndex((c) => String(c.classId) === String(classId));
            // currentClass.Students = action.payload.users.filter((u) => u.userType === 'student');
            // currentClass.teachers = action.payload.users.filter((u) => u.userType === 'teacher');

            if (actionType === 'remove' && !isEmpty(users)) {
                currentClass.Students = currentClass.Students.filter((s) => !users.map((u) => u.id).includes(s.id));
                currentClass.teachers = currentClass.teachers.filter((s) => !users.map((u) => u.id).includes(s.id));
            }

            if (actionType === 'add' && !isEmpty(users)) {
                currentClass.Students = getArrayOfUniqueObjects(
                    [
                        ...currentClass.Students,
                        ...(!isEmpty(users.filter((u) => u.id.startsWith('s-')))
                            ? users.filter((u) => u.id.startsWith('s-'))
                            : []),
                    ],
                    'id'
                );
                currentClass.teachers = getArrayOfUniqueObjects(
                    [
                        ...currentClass.teachers,
                        ...(!isEmpty(users.filter((u) => u.id.startsWith('t-')))
                            ? users.filter((u) => u.id.startsWith('t-'))
                            : []),
                    ],
                    'id'
                );
            }

            if (actionType === 'updateInfo' && !isEmpty(users)) {
                currentClass.Students = currentClass.Students.map((s) => {
                    const userToUpdate = users.find((u) => u.id === s.id);
                    console.log(userToUpdate, users);
                    if (!isEmpty(userToUpdate)) {
                        return { ...s, ...userToUpdate };
                    } else return s;
                });
                currentClass.teachers = currentClass.teachers.map((s) => {
                    const userToUpdate = users.find((u) => u.id === s.id);
                    if (!isEmpty(userToUpdate)) {
                        return { ...s, ...userToUpdate };
                    } else return s;
                });
            }

            const studentGrades = currentClass.Students.map((student) => {
                return Math.floor((student.gradeId + 1) / 2);
            });
            const studentGradesUnique = studentGrades
                .filter((a, i) => studentGrades.indexOf(a) === i)
                .sort((a, b) => a - b);
            currentClass.classGrades = studentGradesUnique;
            const newClassArray = [...state.classes];
            newClassArray.splice(index === -1 ? state.classes.length : index, 1, currentClass);
            return { ...state, classes: newClassArray };
        }
        default:
            return state;
    }
}
