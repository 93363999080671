import { INIT_STUDENTS, ADD_STUDENT, EDIT_STUDENT, ADD_STUDENTS, STUDENT_DONATED } from '../actions/actionTypes';

const initialState = {
    students: [],
};

export default function (state = initialState, action) {
    var _students;

    switch (action.type) {
        case ADD_STUDENT: {
            const newStudent = action.payload;

            //get copy of the current student list
            _students = [...state.students];

            //add the new class to the begging of the list
            _students.unshift(newStudent);

            return {
                ...state,
                students: _students,
            };
        }
        case ADD_STUDENTS: {
            _students = action.payload;

            return {
                ...state,
                students: state.students.concat(_students),
            };
        }

        case INIT_STUDENTS: {
            const students = action.payload.Students;
            return {
                ...state,
                students,
            };
        }

        case STUDENT_DONATED: {
            return {
                ...state,
                seedsCount: action.payload.data.studentSeeds,
                data: action.payload,
            };
        }
        default:
            return state;
    }
}
