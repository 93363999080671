import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, { useEffect } from 'react';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import './assets/css/bootstrap.min.css';
import './assets/css/style.css';

import { unregister } from './registerServiceWorker';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import { ThemeProvider } from '@mui/material/styles';
import theme from './assets/customisedMUI/theme';
// import { PublicClientApplication } from '@azure/msal-browser';
// import { MsalProvider } from '@azure/msal-react';
// import { msalConfig } from 'config/msal';
import { GoogleOAuthProvider } from '@react-oauth/google';
import _ from 'lodash';

//redux
import { Provider } from 'react-redux';

import store from './store/';

// const msalInstance = new PublicClientApplication(msalConfig);
const clientId = '651065819092-t97i9tfuknprp3cl13cg2bgf6c3dapvn.apps.googleusercontent.com';
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <GoogleOAuthProvider clientId={clientId}>
                        {/* <MsalProvider instance={msalInstance}> */}
                        <App />
                        {/* </MsalProvider> */}
                    </GoogleOAuthProvider>
                </ErrorBoundary>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
);

root.render(app);

window.lsgo = {};
window.lsgo.registerLsgoFunction = (key, fun) => _.set(window.lsgo, key, fun);

unregister();
