import { TOGGLE_DEVELOPER_MODE } from '../actions/actionTypes';

const initialState = {
    developerMode: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TOGGLE_DEVELOPER_MODE: {
            return {
                ...state,
                developerMode: !state.developerMode,
            };
        }
        default:
            return state;
    }
}
