import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import axios from 'config/axios';
import Auth from 'Api/Auth';
import Users from 'Api/Users';
import { parseJwt } from 'utils';
import { setCurrentTeacher } from 'store/actions/authActions';
import store from 'store';

const PrivateRoute = ({ routeType, auth, embed, accountExpired, history, redirect, ...props }) => {
    const { isAuthenticated } = auth;
    const isExpired = !moment().isBefore(moment(auth.expiryDate));
    const { redirectUri, _rt } = queryString.parse(new URL(window.location.href).search);

    if (embed) {
        return <Route {...props} />;
    }

    if (_rt) {
        axios.defaults.headers.common['x-refresh-token'] = _rt;
        localStorage.setItem('lsgoRT', _rt);
        Auth.userV2({
            redirect: true,
            history,
            redirectUri,
        }).then(async (token) => {
            if (token) {
                const decoded = await parseJwt(token.replace('Bearer ', ''));
                if (decoded.id) {
                    try {
                        const profile = await Users.getUserProfile(decoded.id);
                        if (!profile.data.name) profile.data.name = profile.data.firstName;
                        store.dispatch(setCurrentTeacher({ ...profile.data, alias: decoded.alias }));
                    } catch (error) {
                        console.log(error, 'error');
                    }
                }
                // history.push(redirectUri);
                setTimeout(() => history.push(redirectUri), 500);
            }
        });
    }

    if (isAuthenticated) {
        if (redirectUri || redirect) {
            return <Redirect to={redirectUri || redirect} />;
        } else if (routeType === 'guest') {
            return <Redirect to='/teacher' />;
        } else if (isAuthenticated && isExpired && !history.location.pathname.includes('/teacher/profile')) {
            return <Redirect to='/teacher/profile' />;
        }
    } else {
        if (['sentral', 'schoolBytes'].includes(routeType))
            return (
                <Redirect
                    to={`/?redirectUri=${encodeURIComponent(
                        history.location.pathname + history.location.search
                    )}&auth_method=${routeType === 'schoolBytes' ? 'schoolBytes_sso' : 'sentral_sso'}`}
                />
            );

        if (routeType === 'private' || !routeType)
            return (
                <Redirect
                    to={`/?redirectUri=${encodeURIComponent(history.location.pathname + history.location.search)}`}
                />
            );
    }

    return <Route {...props} />;
};

export default withRouter(
    connect((state) => {
        return { auth: state.auth };
    })(PrivateRoute)
);
