import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PrivateRoute from 'routing/PrivateRoute';
import PropTypes from 'prop-types';

import logo from 'assets/images/logo.jpg';
import logo_horizontal from 'assets/images/logo_long_horizontal.png';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastifyNotification from 'components/UI/Notifications/ToastifyNotification';
import { Card } from '@mui/material';

import Api from 'Api';
import './EmptyLayout.scss';
import { isEmpty } from '@lsgo/lsgo-common';
import { logOutTeacher } from 'store/actions/authActions';

/**
 *@returns JSX
 */
const EmptyLayout = ({ component: Component, auth, serverNotifications, ...rest }) => {
    const [release, setRelease] = useState(null);
    const [toastType, setToastType] = useState(null);
    //notify the master template to the page type
    document.body.classList.toggle('account-pages', true);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    useEffect(() => {
        async function fetchVersion() {
            const newRelease = await Api.getVersion();

            setRelease(newRelease);
        }
        // fetchVersion();
    }, []);

    useEffect(() => {
        if (!isEmpty(serverNotifications.message)) {
            serverNotifications.type === 'warning'
                ? setToastType('server-notification warning-notification')
                : setToastType('server-notification success-notification');
        }
    }, [serverNotifications]);

    return (
        <Fragment>
            <div className={`lsgo-empty-layout container-fluid ${rest.path === '/trial' ? 'is-trial' : ''}`}>
                <div className={`empty-component-holder `}>
                    <Card className='component-card'>
                        <h2 className='text-uppercase text-center'>
                            <a href='/' className='text-success'>
                                <span>
                                    <img
                                        src={
                                            rest.path === '/trial' || rest.path.includes('/teacher/verify')
                                                ? logo_horizontal
                                                : logo
                                        }
                                        alt='lsgo'
                                        style={{ maxWidth: 250, height: 'auto', maxHeight: 100 }}
                                    />
                                </span>
                            </a>
                        </h2>
                        <PrivateRoute
                            routeType={
                                rest.schoolBytesUnsafeRoute
                                    ? 'schoolBytes'
                                    : rest.sentralUnsafeRoute
                                    ? 'sentral'
                                    : 'guest'
                            }
                            isAuthenticated={auth.isAuthenticated}
                            {...rest}
                            render={(props) => <Component {...props} />}
                        />
                    </Card>
                </div>

                <div className='row text-center empty-footer'>
                    <div id='versionNumber' className='col-sm-6'>
                        <a
                            href='https://help.lifeskillsgroup.com.au/life-skills-go-change-list'
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ display: 'block' }}
                        >
                            {/* <span>Version: v{release ? release : '0.0.0'}</span> */}
                            <span>Version: v2023.05.01</span>
                        </a>
                        <span style={{ display: 'block' }}>{currentYear} &copy; Life Skills Group Online Pty Ltd.</span>
                    </div>

                    <div className='col-sm-6'>
                        <a
                            href='https://www.facebook.com/groups/GOusers/'
                            className='cursor'
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ display: 'block' }}
                        >
                            <i className='fab fa-facebook-square' style={{ verticalAlign: 'middle' }} /> &nbsp; Join our
                            teacher community!
                        </a>

                        <a
                            href='https://www.lifeskillsgroup.com.au/terms-and-conditions'
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ display: 'block' }}
                        >
                            <span>Terms and Conditions</span>
                        </a>
                        <div>
                            <button
                                style={{ backgroundColor: 'white', color: 'black' }}
                                onClick={async () => {
                                    console.log('clearing cache');
                                    localStorage.removeItem('authToken');
                                    localStorage.removeItem('lsgoRT');
                                    logOutTeacher();
                                    // props.history.push('/login');
                                    window.location.reload();
                                }}
                            >
                                Clear Cache
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <ToastifyNotification />
                <ToastContainer toastClassName={toastType} autoClose={false} closeOnClick={false} />
            </div>
        </Fragment>
    );
};

EmptyLayout.propTypes = {
    component: PropTypes.func.isRequired,
};

export default connect((state) => {
    return { auth: state.auth, serverNotifications: state.ui.serverNotifications };
})(EmptyLayout);
