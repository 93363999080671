import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useQueryParams = ({ schema }) => {
    const { search } = useLocation();
    const history = useHistory();

    const queryParams = useMemo(() => {
        const params = new URLSearchParams(search);
        return Object.keys(schema).reduce((obj, key) => {
            const param = params.get(key) || '';
            obj[key] =
                schema[key] === 'array'
                    ? param.split(',').length === 1
                        ? param === ''
                            ? []
                            : [param]
                        : param.split(',')
                    : schema[key] === 'bool'
                    ? param === 'true'
                    : param;
            return obj;
        }, {});
    }, [schema, search]);

    const updateQueryParams = ({ method, ...params }) => {
        if (method !== 'push' && method !== 'replace') {
            throw new Error(`Unsupported history method ${method}. Please specify 'push' or 'replace'`);
        }
        let newQueryParams = { ...queryParams };
        Object.entries(params).forEach(([k, v]) => {
            if (schema[k] === 'bool' || schema[k] === 'string') {
                newQueryParams[k] = v;
            } else if (schema[k] === 'array') {
                const currentArray = newQueryParams[k] || [];
                if (Array.isArray(v)) {
                    newQueryParams[k] = v;
                    return;
                }
                const itemIndex = currentArray.indexOf(String(v));
                if (itemIndex > -1) {
                    currentArray.splice(itemIndex, 1);
                } else {
                    currentArray.push(String(v));
                }

                newQueryParams[k] = currentArray;
            }
        });
        return history[method](
            `?${Object.entries(newQueryParams)
                .map(([k, v]) => `${k}=${v}`)
                .join('&')}`
        );
    };

    return { queryParams, updateQueryParams };
};

export default useQueryParams;
