/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull lsgo-teacher'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 57
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/lifeskillsgroup/Life%20Skills%20Group%20Online/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/lifeskillsgroup/Life%20Skills%20Group%20Online/implementation/lsgo-teacher)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'production'|'development'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} production
 * @property {string} development
 */
export const ApiKey = {
  production: 'a1ee6e0db3237c5be7b02d90dafcae77',
  development: '9e049131bda5b0754a344ec7a53b2676'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '57',
    branch: 'main',
    source: 'lsgo-teacher',
    versionId: '21eb633e-696b-4b51-9b19-8d30d5083979'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export class Identify {
  constructor(properties) {
    this.event_type = amplitude.Types.SpecialEventType.IDENTIFY;
    this.event_properties = properties;
  }
}

export class ButtonClicked {
  constructor(properties) {
    this.event_type = 'ButtonClicked';
    this.event_properties = properties;
  }
}

export class FileDownloaded {
  constructor(properties) {
    this.event_type = 'FileDownloaded';
    this.event_properties = properties;
  }
}

export class LessonActivityActioned {
  constructor(properties) {
    this.event_type = 'LessonActivityActioned';
    this.event_properties = properties;
  }
}

export class LmsCourseActioned {
  constructor(properties) {
    this.event_type = 'LMSCourseActioned';
    this.event_properties = properties;
  }
}

export class TermSearched {
  constructor(properties) {
    this.event_type = 'TermSearched';
    this.event_properties = properties;
  }
}

export class VideoActioned {
  constructor(properties) {
    this.event_type = 'VideoActioned';
    this.event_properties = properties;
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} [properties] The user's properties.
   * @param {number} [properties.group_id] Property has no description in tracking plan.
   * @param {'full_product'|'emotion_checkin'|'free_trial'} [properties.license] Property has no description in tracking plan.
   * @param {'free'|'paid'} [properties.payment_status] Property has no description in tracking plan.
   * @param {'LS_admin'|'school_admin'|'teacher'|'student'} [properties.role] Property has no description in tracking plan.
   * @param {number} [properties.school_id] Property has no description in tracking plan.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, properties, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        ampIdentify.set(key, value);
      }
    }
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * ButtonClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/lifeskillsgroup/Life%20Skills%20Group%20Online/events/main/latest/ButtonClicked)
   *
   * Owner: Ishita Vig
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.button_name Property has no description in tracking plan.
   * @param {string} properties.course_name Property has no description in tracking plan.
   * @param {'Teacher Login'|'Account Setup'|'Lessons Dashboard'|'Sidebar'|'School Admin Dashboard'|'Student Dashboard'|'Emotion Check-in Dashboard'|'School Admin - Teachers'|'School Admin - Students'|'Class Dashboard'|'Student Login'|'Top Navigation Bar'|'Roll Call'|'Journal Dashboard'|'Reporting Dashboard'|'Getting Started Dashboard'|'Sidebar - v2'} properties.location Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  buttonClicked(properties, options) {
    return this.track(new ButtonClicked(properties), options);
  }

  /**
   * FileDownloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/lifeskillsgroup/Life%20Skills%20Group%20Online/events/main/latest/FileDownloaded)
   *
   * Owner: Ishita Vig
   *
   * @param {Object} properties The event's properties.
   * @param {'school'|'class'|'student'|'resource'|'teacher'} properties.entity Property has no description in tracking plan.
   * @param {string} properties.name Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  fileDownloaded(properties, options) {
    return this.track(new FileDownloaded(properties), options);
  }

  /**
   * LessonActivityActioned
   *
   * [View in Tracking Plan](https://data.amplitude.com/lifeskillsgroup/Life%20Skills%20Group%20Online/events/main/latest/LessonActivityActioned)
   *
   * Owner: Ishita Vig
   *
   * @param {Object} properties The event's properties.
   * @param {'paused'|'played'|'ended'|'seeked'|'created'|'assigned'|'updated'|'started'|'completed'|'email_delivered'|'viewed'|'link_clicked'|'resumed'|'restarted'|'mark_activity_completed'|'downloaded'|'view_my_courses'|'next_course'} properties.action Property has no description in tracking plan.
   * @param {string} properties.activity_id Property has no description in tracking plan.
   * @param {'QUIZ_MULTI_SINGLE'|'QUIZ_MULTI_MULTI'|'QUIZ_TEXT_FIELD'|'QUIZ_DRAW_RESPONSE'|'GAME'|'ECI_VIDEO'|'LESSON_VIDEO'|'WEATHERREPORT'|'WEATHERCHECKIN'|'SUMMARY'} properties.activity_type Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  lessonActivityActioned(properties, options) {
    return this.track(new LessonActivityActioned(properties), options);
  }

  /**
   * LMSCourseActioned
   *
   * [View in Tracking Plan](https://data.amplitude.com/lifeskillsgroup/Life%20Skills%20Group%20Online/events/main/latest/LMSCourseActioned)
   *
   * Owner: Ishita Vig
   *
   * @param {Object} properties The event's properties.
   * @param {'paused'|'played'|'ended'|'seeked'|'created'|'assigned'|'updated'|'started'|'completed'|'email_delivered'|'viewed'|'link_clicked'|'resumed'|'restarted'|'mark_activity_completed'|'downloaded'|'view_my_courses'|'next_course'} properties.action Property has no description in tracking plan.
   * @param {string} properties.activity_name Property has no description in tracking plan.
   * @param {string} properties.name Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  lmsCourseActioned(properties, options) {
    return this.track(new LmsCourseActioned(properties), options);
  }

  /**
   * TermSearched
   *
   * [View in Tracking Plan](https://data.amplitude.com/lifeskillsgroup/Life%20Skills%20Group%20Online/events/main/latest/TermSearched)
   *
   * Owner: Ishita Vig
   *
   * @param {Object} properties The event's properties.
   * @param {'Teacher Login'|'Account Setup'|'Lessons Dashboard'|'Sidebar'|'School Admin Dashboard'|'Student Dashboard'|'Emotion Check-in Dashboard'|'School Admin - Teachers'|'School Admin - Students'|'Class Dashboard'|'Student Login'|'Top Navigation Bar'|'Roll Call'|'Journal Dashboard'|'Reporting Dashboard'|'Getting Started Dashboard'|'Sidebar - v2'} properties.location Property has no description in tracking plan.
   * @param {string} properties.term Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  termSearched(properties, options) {
    return this.track(new TermSearched(properties), options);
  }

  /**
   * VideoActioned
   *
   * [View in Tracking Plan](https://data.amplitude.com/lifeskillsgroup/Life%20Skills%20Group%20Online/events/main/latest/VideoActioned)
   *
   * Owner: Ishita Vig
   *
   * @param {Object} properties The event's properties.
   * @param {'paused'|'played'|'ended'|'seeked'|'created'|'assigned'|'updated'|'started'|'completed'|'email_delivered'|'viewed'|'link_clicked'|'resumed'|'restarted'|'mark_activity_completed'|'downloaded'|'view_my_courses'|'next_course'} properties.action Property has no description in tracking plan.
   * @param {'individual_student_lesson_checkin'|'roll_call'|'prepare_for_learning'|'teacher_lesson_preview'|'resource_section'|'individual_student_lesson'|'teacher_led_mode_checkin'|'teacher_led_mode_lesson'|'individual_student_emotion_checkin'} properties.context Property has no description in tracking plan.
   * @param {number} properties.duration Property has no description in tracking plan.
   * @param {string} properties.name Property has no description in tracking plan.
   * @param {number} properties.percent Property has no description in tracking plan.
   * @param {number} properties.seconds Property has no description in tracking plan.
   * @param {'teacher'|'student'|'system'} properties.triggered_by Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  videoActioned(properties, options) {
    return this.track(new VideoActioned(properties), options);
  }
}

export const ampli = new Ampli();
