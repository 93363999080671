import axios from 'config/axios';
import Auth from './Auth';

class Teachers {
    static async getSchool(groupId) {
        await Auth.checkCurrentAuthToken();
        return await axios.get('/api/schools/' + groupId).then((res) => res.data);
    }

    static async getSchoolClasses(groupId) {
        await Auth.checkCurrentAuthToken();
        return await axios.get('/api/schools/classes/' + groupId).then((res) => res.data.classes);
    }
}

export default Teachers;
