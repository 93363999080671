import { ADD_LESSON_TO_STATE } from '../actions/actionTypes';
const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        // Add new unit data to the redux state if it does not already exist. If it does, overwrite the existing store data with the new data.
        case ADD_LESSON_TO_STATE: {
            if (state && action.payload.unitId) {
                if (state[action.payload.unitId]) {
                    const unitLessonsGroupId = state[action.payload.unitId].map((u) => +u.groupId);
                    action.payload &&
                        !unitLessonsGroupId.includes(+action.payload.groupId) &&
                        state[action.payload.unitId].push(action.payload);
                } else {
                    state[action.payload.unitId] = [action.payload];
                }
            }
        }

        default:
            return state;
    }
}
