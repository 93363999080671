/**
 * This module manages socket connections on the client.
 *
 * It will interface with the rest of the application through a redux-middleware function.
 */

import io from 'socket.io-client';
import { parseJwt } from 'utils';
import sendAnalytics from 'utils/analytics';

// Base socket action types.
import {
    CREATE_SOCKET,
    CLOSE_SOCKET,
    CLOSE_ALL_SOCKETS,
    JOIN_LESSON,
    SOCKET_STUDENT_ACTIVITY_DATA,
    SOCKET_STUDENT_WR_DATA,
    TEACHER_LESSON_PAUSE,
    TEACHER_LESSON_RESUME,
    TEACHER_LESSON_STOP,
    SET_CURRENT_CLASS,
    REMOVE_CLASS,
    ADD_CLASS,
    SOCKET_TEACHER_MESSAGE,
    REQUEST_STUDENT_LIST,
    SOCKET_TEACHER_AWARD_BADGE,
    SET_CURRENT_USER,
    VERIFY_USER,
} from '../store/actions/actionTypes';

import {
    socketConnected,
    socketDisconnected,
    student_LessonPause,
    student_LessonResume,
    student_LessonStop,
    teacher_socketActivityData,
    teacher_socketWRData,
    teacher_receiveMessage,
    updateTokenForVerifiedUser,
} from '../store/actions/socketActions';
import { WSServerLink } from '@lsgo/lsgo-fe';
import { v4 as uuid } from 'uuid';
import { showServerNotification, platformNotifications } from 'store/actions/uiActions';

/** ------------------------------------------------------------------------------------------------------------ */
/** ----------------------------------------SOCKET SET-UP DETAILS ---------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Socket connection URL. */
const SOCKET_URL = WSServerLink(window.location);

/** Default socket connection parameters. */
const socketParams = {
    'force new connection': true,
    autoConnect: false,
    reconnectionAttempts: 1,
    reconnectionDelay: 1000,
    timeout: 10000,
    reconnectionDelayMax: 500,
};

/** Object to hold our active sockets, indexed by class ID. */
let activeSockets = [];
let activeSocketId = null;
let connecting = false;

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------- STUDENT EVENT LISTENERS ------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Subscribe a socket to lesson pause events, dispatch 'STUDENT_LESSON_PAUSE' action on event. */
const student_subscribeLessonPause = (socket) => (dispatch) =>
    socket.on('lessonPause', () => {
        // console.log('Received Lesson Pause');
        dispatch(student_LessonPause());
    });

/** Subscribe a socket to lesson resume events, dispatch 'STUDENT_LESSON_RESUME' action on event. */
const student_subscribeLessonResume = (socket) => (dispatch) =>
    socket.on('lessonResume', () => {
        // console.log('Received Lesson Resume');
        dispatch(student_LessonResume());
    });

/** Subscribe a socket to lesson stop events, dispatch 'STUDENT_LESSON_STOP' action on event. */
const student_subscribeLessonStop = (socket) => (dispatch) =>
    socket.on('lessonStop', () => {
        // console.log('Received Lesson Stop');
        dispatch(student_LessonStop());
    });

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------- STUDENT EVENT EMITTERS -------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** On dispatch of 'SOCKET_STUDENT_ACTIVITY_DATA action, emit 'studentActivityData' event through active socket. */
const student_emitStudentActivity = (report, classId) => {
    if (activeSockets.includes(String(classId))) {
        console.log('Student Emit Activity:', report);
        activeSocketId.emit('studentActivityData', { report, classId });
    }
};

const student_emitStudentWR = (report, classId) => {
    if (activeSockets.includes(String(classId))) {
        console.log('Student Emit WR:', report);
        activeSocketId.emit('studentWRData', { report, classId });
    }
};

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------- TEACHER EVENT LISTENERS ------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Subscribe a socket to lesson report events, dispatch 'SOCKET_TEACHER_ACTIVITY_DATA' action on event. */
const teacher_subscribeStudentActivity = (socket) => (dispatch) => {
    socket.on('teacher_studentActivityData', (data) => {
        console.log('teacher_subscribeStudentActivity', data);
        dispatch(teacher_socketActivityData(data));
    });
};

const teacher_subscribeStudentWR = (socket) => (dispatch) => {
    socket.on('teacher_studentWRData', (data) => {
        console.log('teacher_subscribeWRData', data);
        dispatch(teacher_socketWRData(data));
    });
};

const teacher_subscribeStudentMessage = (socket) => (dispatch) => {
    socket.on('teacher_receiveMessage', (data) => {
        dispatch(teacher_receiveMessage(data));
    });
};

const teacher_subscribeVerifyUser = (socket) => (dispatch) => {
    socket.on('authRefresh', (data) => {
        dispatch(updateTokenForVerifiedUser(data));
    });
};

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------- TEACHER EVENT EMITTERS -------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** On dispatch of 'TEACHER_LESSON_PAUSE' action, emit 'lessonPause' event through class socket. */
const teacher_emitLessonPause = (classId, unitId, lessonId) => {
    if (activeSockets.includes(String(classId))) {
        console.log('Emit Lesson Pause', classId, unitId, lessonId);
        activeSocketId.emit('lessonPause', { classId, unitId, lessonId });
    }
};

/** On dispatch of 'TEACHER_LESSON_RESUME' action, emit 'lessonResume' event through class socket. */
const teacher_emitLessonResume = (classId, unitId, lessonId) => {
    if (activeSockets.includes(String(classId))) {
        // console.log('Emit Lesson Resume', classId, unitId, lessonId);
        activeSocketId.emit('lessonResume', { classId, unitId, lessonId });
    }
};

/** On dispatch of 'TEACHER_LESSON_STOP' action, emit 'lessonStop' event through class socket. */
const teacher_emitLessonStop = (classId, unitId, lessonId) => {
    if (activeSockets.includes(String(classId))) {
        // console.log('Emit Lesson Stop', classId, unitId, lessonId);
        activeSocketId.emit('lessonStop', { classId, unitId, lessonId });
    }
};

const teacher_emitMessage = (message, classId) => {
    if (activeSockets.includes(String(classId))) {
        activeSocketId.emit('teacherMessage', { message, classId });
    }
};

const teacher_emitAwardedBadge = (badgeData, classId) => {
    if (activeSockets.includes(String(classId))) {
        activeSocketId.emit('teacherBadgeAwarded', { badgeData, classId });
    }
};

const teacher_requestStudentListDownload = (teacherId, teacher, classId, className, type) => {
    if (activeSockets.includes(String(classId))) {
        activeSocketId.emit('requestStudentListDownload', { teacherId, teacher, classId, className, type });
    }
};

/** On dispatch of 'TEACHER_LESSON_RESUME' action, emit 'lessonResume' event through class socket. */
const teacher_emitVerifyUser = (teacherId, verified) => {
    if (activeSocketId) {
        console.log('Emit Verify User', teacherId, verified);
        activeSocketId.emit('verifyUser', { teacherId, verified });
    }
};

/** ------------------------------------------------------------------------------------------------------------ */
/** ---------------------------------------- COMMON EVENT EMITTERS --------------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** On dispatch of 'JOIN_LESSON' action, emit 'joinLesson' event through class socket. */
const common_emitJoinLesson = (classId, unitId, lessonId) => {
    if (activeSockets.includes(String(classId))) activeSocketId.emit('joinLesson', { classId, unitId, lessonId });
};

export const emitTrackingEvent = (data) => {
    const dataWithDevice = {
        ...data,
        _device: {
            screen: {
                width: window.screen.width,
                height: window.screen.height,
                availHeight: window.screen.availHeight,
                availWidth: window.screen.availWidth,
            },
            window: {
                width: window.innerWidth || document.documentElement.clientWidth,
                height: window.innerHeight || document.documentElement.clientHeight,
                pixelDensity: window.devicePixelRatio,
            },
        },
    };
    console.log(dataWithDevice);
    if (activeSocketId) activeSocketId.emit('clickEvent', dataWithDevice);
    else sendAnalytics(data, true);
};

/** ------------------------------------------------------------------------------------------------------------ */
/** -------------------------------------- SOCKET CREATION/DESTRUCTION ----------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

const onClickEventListener = (socket) => (event) => {
    const actualTarget = event.target.closest('[data-pagename]');
    if (actualTarget) {
        const dataToEmit = {
            page: actualTarget.getAttribute('data-pagename'),
            object: actualTarget.getAttribute('data-objectname'),
            classId: actualTarget.getAttribute('data-classid'),
            params: JSON.parse(actualTarget.getAttribute('data-params')),
        };
        socket.emit('clickEvent', dataToEmit);
    }
};

/** Create a new socket for a class (indexed by ID) if none exists. */
const createSocket = (classId, token) => (dispatch) => {
    if (!activeSocketId && !connecting && classId !== 0) {
        connecting = true;
        /** Create a new socket, splitting out and passing the encrypted component of the JWT string. */
        const newSocket = io(SOCKET_URL, { ...socketParams, query: 'token=' + token.split(' ')[1] });
        document.addEventListener('click', onClickEventListener(newSocket));
        activeSocketId = newSocket;

        /** Set-up actions on establishment of server connection. */
        newSocket.on('connect', () => {
            /** Socket ready for work, append to active socket list and return. */

            /** Subscribing to class-specific socket room on server-side. */
            newSocket.emit('joinClass', { classId });
            newSocket.emit('setSession', { sessionId: uuid() });
            connecting = false;

            activeSockets.push(classId);

            /** Places the classId in a Redux store so that program knows web-socket is connected & available. */
            dispatch(socketConnected(classId));
        });

        /** Tear-down actions on loss of connection to server.*/
        newSocket.on('disconnect', () => {
            /** Removing classId from list of available web-sockets in redux store. */
            newSocket.emit('clickEvent', { pagename: 'CLOSING_APPLICATION', object: 'CLOSED' });
            dispatch(socketDisconnected(classId));

            connecting = false;
            /** Cleaning up activeSockets object. */
            closeSocket(classId);
        });

        newSocket.on('serverNotification', (data) => {
            dispatch(showServerNotification(data.message, data.children, data.timeOut));
        });

        newSocket.on('platformNotification', (data, level, levelId) => {
            // console.log(data, level, levelId, 'platformNotifications');
            dispatch(platformNotifications(data, level, levelId));
        });

        try {
            /** Decoding JWT storage passed from local storage. */
            parseJwt(token).then((decodedToken) => {
                /** Attaching student-specific event-listeners if user is a student. */
                if ('studentId' in decodedToken) {
                    student_subscribeLessonPause(newSocket)(dispatch);
                    student_subscribeLessonResume(newSocket)(dispatch);
                    student_subscribeLessonStop(newSocket)(dispatch);
                }

                /** Attaching teacher-specific event-listeners if user is a student. */
                if ('teacherId' in decodedToken) {
                    teacher_subscribeStudentActivity(newSocket)(dispatch);
                    teacher_subscribeStudentWR(newSocket)(dispatch);
                    student_subscribeLessonPause(newSocket)(dispatch);
                    student_subscribeLessonResume(newSocket)(dispatch);
                    student_subscribeLessonStop(newSocket)(dispatch);
                    teacher_subscribeStudentMessage(newSocket)(dispatch);
                    teacher_subscribeVerifyUser(newSocket)(dispatch);
                }
                /** Connect to the server. */
                newSocket.connect();
            });
        } catch (err) {
            connecting = false;
            console.log('Error decoding token on client Socket\n', err);
        }
    }

    const existingClass = activeSockets.find((cId) => cId === classId);

    if (!existingClass && activeSocketId && classId !== 0 && classId !== null) {
        /** Subscribing to class-specific socket room on server-side. */
        activeSocketId.emit('joinClass', { classId });
        activeSockets.push(classId);

        /** Places the classId in a Redux store so that program knows web-socket is connected & available. */
        dispatch(socketConnected(classId));
    }
};

/** Close a socket (indexed by class ID) if it exists. */
const closeSocket = (classId) => {
    if (activeSocketId) {
        document.removeEventListener('click', onClickEventListener(activeSocketId));
        activeSocketId.emit('leaveClass');
        activeSocketId.disconnect();
        activeSocketId.destroy();
        activeSocketId = null;
        activeSockets = [];
    }
};

/** Close all sockets. */
const closeAllSockets = () => {
    Object.keys(activeSockets).forEach((key) => closeSocket(key));
};

/** ------------------------------------------------------------------------------------------------------------ */
/** ------------------------------------------- SOCKET REDUX MIDDLEWARE ---------------------------------------- */
/** ------------------------------------------------------------------------------------------------------------ */

/** Middleware to intercept actions meant to emit a socket event. */
const socketMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        /** Action to directly create a new socket and join a class. */
        case CREATE_SOCKET: {
            const { classId, token } = action.payload;
            createSocket(classId, token)(store.dispatch);
            break;
        }
        /** Action to directly disable/remove a socket. */
        case CLOSE_SOCKET: {
            const { classId } = action.payload;
            closeSocket(classId);
            break;
        }
        case CLOSE_ALL_SOCKETS: {
            closeAllSockets();
            break;
        }
        /** Join a socket.io room indexed by class, unit, and lesson ID (used for lesson pause/start/stop). */
        case JOIN_LESSON: {
            const { classId, unitId, lessonId } = action.payload;
            common_emitJoinLesson(classId, unitId, lessonId);
            break;
        }
        case SOCKET_STUDENT_ACTIVITY_DATA: {
            const { report, classId } = action.payload;
            student_emitStudentActivity(report, classId);
            break;
        }
        case SOCKET_STUDENT_WR_DATA: {
            const { report, classId } = action.payload;
            student_emitStudentWR(report, classId);
            break;
        }
        case TEACHER_LESSON_PAUSE: {
            const { classId, unitId, lessonId } = action.payload;
            teacher_emitLessonPause(classId, unitId, lessonId);
            break;
        }
        case TEACHER_LESSON_RESUME: {
            const { classId, unitId, lessonId } = action.payload;
            teacher_emitLessonResume(classId, unitId, lessonId);
            break;
        }
        case TEACHER_LESSON_STOP: {
            const { classId, unitId, lessonId } = action.payload;
            teacher_emitLessonStop(classId, unitId, lessonId);
            break;
        }
        case SOCKET_TEACHER_MESSAGE: {
            const { message, classId } = action.payload;
            teacher_emitMessage(message, classId);
            break;
        }
        case SOCKET_TEACHER_AWARD_BADGE: {
            const { badgeData, classId } = action.payload;
            teacher_emitAwardedBadge(badgeData, classId);
            break;
        }
        case VERIFY_USER: {
            const { teacherId, verified } = action.payload;
            teacher_emitVerifyUser(teacherId, verified);
            break;
        }

        /** --------- Dirty hacks to ensure the connected websocket is synced to the currently viewed class ID. -------- */

        /**
         * It's not easy to determine which class is currently being viewed, as there are multiple sources of truth
         * that sometimes update independently of each other (e.g. redux store 'currentClass' in classReducer,
         * local storage 'currentClassId', etc.)
         *
         * The class Id stored in local storage seems to be the most accurate indicator.
         *
         * Intercepting actions that modify local storage variable, and using them to activate/deactivate sockets.
         */
        case SET_CURRENT_USER: {
            const teacher = action.payload;

            if (teacher.id && activeSockets.length === 0) createSocket(null, localStorage.authToken)(store.dispatch);
            break;
        }
        case SET_CURRENT_CLASS: {
            /** ID of new current class. */
            const classId = action.payload?.classId;

            /** Create a socket for the new class if required. */
            if (classId) createSocket(classId, localStorage.authToken)(store.dispatch);
            break;
        }
        case REMOVE_CLASS: {
            /** Retrieving ID of new current class (replicating internals of REMOVE_CLASS reducer case). */
            const classId = action.payload;
            const classes = store.getState().classes.classes.filter((c) => c.classId !== classId);
            const currentClass = classes.length > 0 ? classes[0].classId : null;

            /** Create a socket for the new class if required. */
            if (currentClass) createSocket(currentClass, localStorage.authToken)(store.dispatch);
            break;
        }
        case ADD_CLASS: {
            /** Class ID of new class. */
            const classId = action.payload.classId;

            /** Create a socket for the new class if required. */
            if (classId) createSocket(classId, localStorage.authToken)(store.dispatch);
            break;
        }
        case REQUEST_STUDENT_LIST: {
            teacher_requestStudentListDownload(
                action.payload.id,
                action.payload.email,
                action.payload.classId,
                action.payload.className,
                action.payload.type
            );
            break;
        }

        default: {
            break;
        }
        /** -------------------------------------------- End of dirty hacks. -------------------------------------------- */
    }

    return next(action);
};

export default socketMiddleware;
