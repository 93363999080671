import axios from 'config/axios';

/**
 *
 * @param {object} analyticsData
 * @param {boolean} authorizedLogs
 */
export default (analyticsData, authorized = false) => {
    const analyticsURL = authorized ? '/api/v4/lessons/analytics/authorized' : '/api/v4/lessons/analytics/unauthorized';

    axios.post(analyticsURL, analyticsData);
};
