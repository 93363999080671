export default [
    { name: 'Welcome', path: '', id: '-M8xjHslE3Ffjc3jbm3J', display: true },
    { name: 'Add Students', path: '', id: '-M8yHsr4KeDti5-6RCOO', display: true },
    { name: 'Manage Students', path: 'classes/dashboard/students', id: '-M8yR3zSfY5UPdM5aumT', display: true },
    { name: 'Add Units', path: '', id: '-M8ytIU5LbmP6Lrt4e0a', display: true },
    { name: 'Manage Units', path: 'classes/dashboard/curriculum', id: '-M8z3bQEQVBnO-p68d1b', display: true },
    { name: 'Emotion Check-in', path: '', id: '-M8z8TLWu-TdNSTjzrzY', display: true },
    { name: 'Unit Report', path: '', id: '-M8zKotMSTJmKWXyGeLD', display: true },
    { name: 'Printable Resources', path: '', id: '-M90w4my8b4KQFNx1K10', display: true },
    { name: 'Video Resources', path: '', id: '-M91akSX26lwC6fGdUe0', display: true },
    { name: 'Teacher Resources', path: '', id: '-M8zKotMSTJmKWXyGeLD', display: true },
    { name: 'Student Badges', path: '', id: '5f9211cc-9887-4690-9f46-c4881e3ea447', display: true },
    { name: 'Class Leaderboard', path: '', id: '798929fe-fa72-4dcb-bf67-33fb01abdbd1', display: true },
    { name: 'Life Skills GO', path: '', id: '3f13f221-3ab0-47ab-9478-1b12ef42476c', display: false },
    {
        name: 'your class reporting',
        path: 'reporting/unit/',
        id: 'ad0dbb76-6fdd-44a5-964d-df873b7a3a7a',
        display: false,
    },
    {
        name: 'your class lessons',
        path: 'classes/dashboard/curriculum',
        id: 'bcb150e4-a597-420e-8292-6e961035c1ba',
        display: false,
    },
    {
        name: 'the Life Skills GO Feedback Group',
        path: '',
        id: '36580f9f-43b5-4d2e-9e8f-7f7ffd02ba49',
        display: false
    }
];
