import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorsReducer from './errorReducer';
import uiReducer from './uiReducer';
import classReducer from './classReducer';
import studentReducer from './studentReducer';
import learningReducer from './learningReducer';
import alertsReducer from './alertsReducer';
import skillsReducer from './skillsReducer';
import lessonsReducer from './lessonsReducer';
import unitsReducer from './unitsReducer';
import constantsReducer from './constantsReducer';
import developerReducer from './developerReducer';

import socketReducerCommon from './sockets/socketReducerCommon';
import socketReducerStudent from './sockets/socketReducerStudent';
import socketReducerTeacher from './sockets/socketReducerTeacher';

export default combineReducers({
    auth: authReducer,
    errors: errorsReducer,
    ui: uiReducer,
    classes: classReducer,
    students: studentReducer,
    learningContent: learningReducer,
    alerts: alertsReducer,
    skills: skillsReducer,
    lessons: lessonsReducer,
    socketCommon: socketReducerCommon,
    socketStudent: socketReducerStudent,
    socketTeacher: socketReducerTeacher,
    units: unitsReducer,
    constants: constantsReducer,
    developer: developerReducer,
});
