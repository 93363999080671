import {
    SET_CURRENT_USER,
    SET_CURRENT_STUDENT,
    PASSWORD_RESET,
    PASSWORD_RESETED,
    SET_UNAUTHORIZED_TEACHER,
    UPDATE_TEACHER,
} from '../actions/actionTypes';
import { isEmpty } from '@lsgo/lsgo-common';

const initialState = {
    isAuthenticated: false,
    expiryDate: '1970-01-01 00:00:00',
    teacher: { name: '' },
    unauthorized: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER: {
            localStorage.user = JSON.stringify(action.payload);
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                teacher: action.payload,
                expiryDate: action.payload.expiryDate,
                commentCategories: action.payload.commentCategories,
            };
        }
        case SET_CURRENT_STUDENT: {
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                student: action.payload,
            };
        }

        case PASSWORD_RESET: {
            return {
                ...state,
                reset_message:
                    'We have received your request and if we find an account registered with the supplied email address we will send you instruction on how to reset your password.',
            };
        }
        case PASSWORD_RESETED: {
            return {
                ...state,
                returnToLogin: true,
                passwordChanged: 'The password was changed successfully.',
            };
        }
        case SET_UNAUTHORIZED_TEACHER: {
            return {
                ...state,
                unauthorized: Boolean(action.payload),
            };
        }
        case UPDATE_TEACHER: {
            const teacherInfo = state.teacher;
            teacherInfo.name = action.payload.firstName;
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                teacher: teacherInfo,
                expiryDate: teacherInfo.expiryDate,
            };
        }
        default:
            return state;
    }
}
