import { ADD_ALERT, REMOVE_ALERT } from '../actions/actionTypes';

const initialState = {
    messages: [],
};

let _messages = [];

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_ALERT: {
            _messages = [...state.messages];
            let newMessage = '';

            if (Object.prototype.hasOwnProperty.call(action.payload, 'voice')) {
                newMessage = { time: Date.now(), text: action.payload.text, voice: action.payload.voice };
            } else if (Object.prototype.hasOwnProperty.call(action.payload, 'text')) {
                newMessage = { time: Date.now(), text: action.payload, voice: 'Justin' };
            } else {
                newMessage = { time: Date.now(), url: action.payload.url };
            }

            if (_messages.length === 2) {
                _messages[1] = newMessage;
            } else {
                _messages.push(newMessage);
            }

            return {
                ...state,
                messages: _messages,
            };
        }

        case REMOVE_ALERT: {
            if (_messages.length > 0) {
                _messages = [...state.messages];
                _messages.shift();
                return {
                    ...state,
                    messages: _messages,
                };
            }
            return { ...state };
        }
        default:
            return state;
    }
}
