import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateConstant } from 'store/actions/constantAction';
import axios from 'config/axios';

const SETTINGS_TEMPLATE = (existing) => {
    return {
        createUnitData: existing?.createUnitData,
        exec_report: {
            layouts: existing?.exec_report?.layouts,
            pinned_students: existing?.exec_report?.pinned_students,
            v2: existing?.exec_report?.v2,
        },
        class_dashboard: {
            layouts: existing?.class_dashboard?.layouts,
            v2: existing?.class_dashboard?.v2,
        },
        eci_dashboard: {
            layouts: existing?.eci_dashboard?.layouts,
            v2: existing?.eci_dashboard?.v2,
        },
        student_profile_dashboard: {
            layouts: existing?.student_profile_dashboard?.layouts,
            v2: existing?.student_profile_dashboard?.v2,
        },
    };
};

const useUserSettings = (userId) => {
    const userSettings = useSelector((state) => state.constants.userSettings);
    const dispatch = useDispatch();
    const [settings, setSettings] = useState({
        createUnitData: {},
        exec_report: {},
        class_dashboard: {},
        eci_dashboard: {},
        student_profile_dashboard: {},
    });

    useEffect(() => {
        const fetchData = async () => {
            if (userSettings) {
                setSettings(userSettings[userId]);
            } else {
                if (userId) {
                    const response = await axios
                        .get(`/api/v4/users/user/${userId}/settings/user_preferences`)
                        .then((r) => r.data);

                    const template = SETTINGS_TEMPLATE(response);
                    const settings = {
                        createUnitData: template.createUnitData,
                        exec_report: template.exec_report,
                        class_dashboard: template.class_dashboard,
                        eci_dashboard: template.eci_dashboard,
                        student_profile_dashboard: template.student_profile_dashboard,
                    };
                    dispatch(updateConstant('userSettings', settings, userId));
                    setSettings(settings);
                }
            }
        };

        fetchData();
    }, [userSettings, userId, dispatch]);

    const saveSettings = (settings) => {
        const saveTemplate = {
            createUnitData: settings?.createUnitData,
            exec_report: {
                layouts: settings?.exec_report?.layouts,
                pinned_students: settings?.exec_report?.pinned_students,
                v2: settings?.exec_report?.v2,
            },
            class_dashboard: {
                layouts: settings?.class_dashboard?.layouts,
                v2: settings?.class_dashboard?.v2,
            },
            eci_dashboard: {
                layouts: settings?.eci_dashboard?.layouts,
                v2: settings?.eci_dashboard?.v2,
            },
            student_profile_dashboard: {
                layouts: settings?.student_profile_dashboard?.layouts,
                v2: settings?.student_profile_dashboard?.v2,
            },
        };
        axios.post(`/api/v4/users/user/${userId}/settings/user_preferences`, { data: saveTemplate }).then((res) => {
            const newSettingObject = SETTINGS_TEMPLATE(saveTemplate);
            dispatch(updateConstant('userSettings', newSettingObject, userId));
        });
    };

    return [settings, saveSettings];
};

export default useUserSettings;
