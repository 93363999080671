import axios from 'config/axios';
import Auth from './Auth';

export default class Api {
    static async loadOption(optionTitle) {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get(`/api/v4/constants/options/${optionTitle}`)
            .then((res) => res.data.option)
            .catch((error) => {
                console.log(`Cannot check current auth token: ${error}`);
            });
    }

    static async getNotifications(teacherId, limit, offset) {
        await Auth.checkCurrentAuthToken();
        const apiUrl = `/api/v4/users/teachers/${teacherId}/notifications?limit=${limit}&offset=${offset}`;

        return axios.get(apiUrl).then((res) => res.data);
    }

    static async readNotification(teacherId, notificationId, notifiedAt) {
        await Auth.checkCurrentAuthToken();
        await axios.put(`/api/v4/users/teachers-notification/${teacherId}`, {
            notificationId,
            notifiedAt,
        });
    }

    static async getVersion() {
        // await Auth.checkCurrentAuthToken();
        return '2023.04.01';
        // return await Api.loadOption('version');
    }

    static async badgesAwardable() {
        await Auth.checkCurrentAuthToken();
        return await axios.get('/api/v4/constants/awards/badges?awardable=1').then((res) => res.data);
    }

    static async getSchoolsList() {
        await Auth.checkCurrentAuthToken();
        return await axios
            .get('/api/schools/list')
            .then((resp) => resp.data)
            .catch((err) => console.log('Error: resending verify email. ', err.message || 'No error info.'));
    }
}
