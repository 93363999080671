export const GET_ERRORS = 'GET_ERRORS';
export const GET_FATAL_ERRORS = 'GET_FATAL_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_STUDENT = 'SET_CURRENT_STUDENT';
export const USER_LOG_OUT = 'USER_LOG_OUT';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESETED = 'PASSWORD_RESETED';
export const SET_UNAUTHORIZED_TEACHER = 'SET_UNAUTHORIZED_TEACHER';

//UI ACTIONS
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const PAUSE_STUDENT = 'PAUSE_STUDENT';
export const TOGGLE_STUDENT_OVERLAY = 'TOGGLE_STUDENT_OVERLAY';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_VISIBLE_UNIT = 'SET_VISIBLE_UNIT';
export const SERVER_NOTIFICATIONS = 'SERVER_NOTIFICATIONS';
export const PLATFORM_NOTIFICATIONS = 'PLATFORM_NOTIFICATIONS';
export const OPEN_NOTIFICATION = 'OPEN_NOTIFICATION';
export const SET_DIALOG_PREVIEW = 'SET_DIALOG_PREVIEW';
export const TOGGLE_ADMIN_VIEW = 'TOGGLE_ADMIN_VIEW';
export const SET_HEADER_LABEL = 'SET_HEADER_LABEL';
export const ADMIN_CLASS_VIEW = 'ADMIN_CLASS_VIEW';

//CLASSES
export const ADD_CLASS = 'ADD_CLASS';
export const ADD_CLASS_V2 = 'ADD_CLASS_V2';
export const REMOVE_CLASS = 'REMOVE_CLASS';
export const EDIT_CLASS = 'EDIT_CLASS';
export const SHOW_CLASS_LOADER = 'SHOW_CLASS_LOADER';
export const HIDE_CLASS_LOADER = 'HIDE_CLASS_LOADER';

export const REMOVE_UNIT = 'REMOVE_UNIT';
export const UPDATE_CURRICULUM = 'UPDATE_CURRICULUM';

export const GET_CLASS_DATA = 'GET_CLASS_DATA';
export const SET_CURRENT_CLASS = 'SET_CURRENT_CLASS';

export const ADD_STUDENT_TO_CLASS_LIST = 'ADD_STUDENT_TO_CLASS_LIST';
export const SET_CLASS = 'SET_CLASS';

export const ADD_TEACHERS_TO_CLASS = 'ADD_TEACHERS_TO_CLASS';
export const ADD_STUDENTS_TO_CLASS = 'ADD_STUDENTS_TO_CLASS';

//TEACHERS
export const UPDATE_TEACHER = 'UPDATE_TEACHER';
export const VERIFY_USER = 'VERIFY_USER';
export const VERIFIED_USER = 'VERIFIED_USER';

//STUDENTS
export const INIT_STUDENTS = 'INIT_STUDENTS';
export const ADD_STUDENT = 'ADD_STUDENT';
export const REMOVE_STUDENT = 'REMOVE_STUDENT';
export const EDIT_STUDENT = 'EDIT_STUDENT';
export const ADD_STUDENTS = 'ADD_STUDENTS';
export const STUDENT_DONATED = 'STUDENT_DONATED';
export const REMOVE_STUDENTS_FROM_CLASS = 'REMOVE_STUDENTS_FROM_CLASS';
export const UPDATE_CLASS_USERS = 'UPDATE_CLASS_USERS';
export const BULK_REMOVE_STUDENTS = 'BULK_REMOVE_STUDENTS';

//learning area & focus
export const INIT_LEARNING_AREAS = 'INIT_LEARNING_AREAS';
export const INIT_COMPLETED_LESSONS = 'INIT_COMPLETED_LESSONS';

//REDUCER
export const SAVE_CURRICULUM = 'SAVE_CURRICULUM';

// alerts
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// skill scores
export const SUBTRACT_FROM_SCORE = 'SUBTRACT_FROM_SCORE';
export const ADD_TO_SCORE = 'ADD_TO_SCORE';

/** SOCKET ACTION TYPES */

// Base socket action types.
export const CREATE_SOCKET = 'CREATE_SOCKET';
export const CLOSE_SOCKET = 'CLOSE_SOCKET';
export const CLOSE_ALL_SOCKETS = 'CLOSE_ALL_SOCKETS';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';

// Shared socket action types.
export const JOIN_LESSON = 'JOIN_LESSON';

// Student-specific socket action types.
export const STUDENT_LESSON_PAUSE = 'STUDENT_LESSON_PAUSE';
export const STUDENT_LESSON_RESUME = 'STUDENT_LESSON_RESUME';
export const STUDENT_LESSON_STOP = 'STUDENT_LESSON_STOP';
export const SOCKET_STUDENT_ACTIVITY_DATA = 'SOCKET_STUDENT_ACTIVITY_DATA';
export const SOCKET_STUDENT_WR_DATA = 'SOCKET_STUDENT_WR_DATA';
export const SOCKET_STUDENT_MESSAGE = 'SOCKET_STUDENT_MESSAGE';
export const SOCKET_STUDENT_MESSAGE_RECEIVE = 'SOCKET_STUDENT_MESSAGE_RECEIVE';

// Teacher-specific socket action types.
export const TEACHER_LESSON_PAUSE = 'TEACHER_LESSON_PAUSE';
export const TEACHER_LESSON_RESUME = 'TEACHER_LESSON_RESUME';
export const TEACHER_LESSON_STOP = 'TEACHER_LESSON_STOP';
export const SOCKET_TEACHER_ACTIVITY_DATA = 'SOCKET_TEACHER_ACTIVITY_DATA';
export const SOCKET_TEACHER_WR_DATA = 'SOCKET_TEACHER_WR_DATA';

export const CLEAR_ALL_ACTIVITY_RECORDS = 'CLEAR_ALL_ACTIVITY_RECORDS';
export const CLEAR_LESSON_ACTIVITY_RECORDS = 'CLEAR_LESSON_ACTIVITY_RECORDS';
export const CLEAR_STUDENT_ACTIVITY_RECORDS = 'CLEAR_STUDENT_ACTIVITY_RECORDS';
export const CLEAR_SINGLE_ACTIVITY_RECORD = 'CLEAR_SINGLE_ACTIVITY_RECORD';
export const CLEAR_WR_DATA = 'CLEAR_WR_DATA';
export const SOCKET_TEACHER_MESSAGE_RECEIVE = 'SOCKET_TEACHER_MESSAGE_RECEIVE';
export const SOCKET_TEACHER_MESSAGE = 'SOCKET_TEACHER_MESSAGE';
export const REQUEST_STUDENT_LIST = 'REQUEST_STUDENT_LIST';
export const SOCKET_TEACHER_AWARD_BADGE = 'SOCKET_TEACHER_AWARD_BADGE';

// UNIT ACTIONS
export const ADD_UNIT_TO_STATE = 'ADD_UNIT_TO_STATE';
export const SET_UNIT_ACTIVE = 'SET_UNIT_ACTIVE';
export const EDIT_LESSON_START_DATE = 'EDIT_LESSON_START_DATE';

// LESSON_ACTIONS
export const ADD_LESSON_TO_STATE = 'ADD_LESSON_TO_STATE';
export const SET_LESSON_CURRENT = 'SET_LESSON_CURRENT';

//Constants
export const UPDATE_CONSTANT = 'UPDATE_CONSTANT';

export const ATTENDANCE = 'ATTENDANCE';

export const FEATURE_FLAG = 'FEATURE_FLAG';

// Developer
export const TOGGLE_DEVELOPER_MODE = 'TOGGLE_DEVELOPER_MODE';
