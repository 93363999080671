import {
    ADD_UNIT_TO_STATE,
    SET_LESSON_CURRENT,
    SET_UNIT_ACTIVE,
    REMOVE_UNIT,
    EDIT_LESSON_START_DATE,
} from '../actions/actionTypes';
const initialState = [];

export default function (state = initialState, action) {
    let newUnitArray;
    switch (action.type) {
        // Add new unit data to the redux state if it does not already exist. If it does, overwrite the existing store data with the new data.
        case ADD_UNIT_TO_STATE: {
            const unitExistIndex = state.findIndex((unit) => unit.unitId === action.payload.unitId);
            if (unitExistIndex >= 0) {
                newUnitArray = [...state];
                newUnitArray.splice(unitExistIndex, 1, { ...action.payload });
            } else {
                newUnitArray = [...state, { ...action.payload }];
            }
            return [...newUnitArray];
        }

        case SET_LESSON_CURRENT: {
            const unitIndex = state.findIndex((unit) => +unit.unitId === +action.payload.unitId);
            newUnitArray = state;
            const unit = newUnitArray.find((unit) => +unit.unitId === +action.payload.unitId);
            let { UnitLessonGroup } = unit;
            const newUnitLessonsGroup = UnitLessonGroup.map((ul) => {
                return +ul.groupId === +action.payload.groupId ? { ...ul, isCurrent: 1 } : { ...ul, isCurrent: 0 };
            });
            unit.UnitLessons = [...newUnitLessonsGroup];
            newUnitArray.splice(unitIndex, 1, { ...unit });
            return [...newUnitArray];
        }

        case SET_UNIT_ACTIVE: {
            newUnitArray = state;
            newUnitArray = [
                ...newUnitArray.map((unit) => {
                    let { ClassUnit } = unit;
                    if (unit.unitId === action.payload.unitId) {
                        ClassUnit = { ...ClassUnit, isActive: 1 };
                    } else {
                        ClassUnit = { ...ClassUnit, isActive: 0 };
                    }
                    return { ...unit, ClassUnit };
                }),
            ];
            return [...newUnitArray];
        }

        case REMOVE_UNIT: {
            const removedUnitIndex = state.findIndex(
                (unit) =>
                    String(unit.unitId) === String(action.payload.unitId) &&
                    String(unit.ClassUnit.classId) === String(action.payload.classId)
            );
            newUnitArray = [...state];
            newUnitArray.splice(removedUnitIndex, 1);
            return [...newUnitArray];
        }

        case EDIT_LESSON_START_DATE: {
            const index = state.findIndex((unit) => String(unit.unitId) === String(action.payload.unitId));
            const unit = state.find((unit) => String(unit.unitId) === String(action.payload.unitId));
            const UnitLessonGroup =
                unit && unit.UnitLessonGroup.find((ulg) => +ulg.groupId === +action.payload.groupId);
            UnitLessonGroup.lessonStart = action.payload.startDate;
            const newUnitLessonGroup = [...unit.UnitLessonGroup];
            unit.UnitLessonGroup = newUnitLessonGroup;
            const newUnits = [...state];
            newUnits.splice(index, 1, unit);
            return [...newUnits];
        }

        default:
            return state;
    }
}
