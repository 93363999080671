import { createStore, createHook } from 'react-sweet-state';
import Api from 'Api/Constants';
import { defaults } from 'react-sweet-state';
defaults.devtools = true;

const Store = createStore({
    // value of the store on initialisation
    initialState: {
        sidebarOptions: [],
        loaded: false,
        loading: false,
    },

    // actions that trigger store mutation
    actions: {
        load:
            () =>
            async ({ setState, getState }) => {
                if (getState().loading === true || getState().loaded) return;

                setState({
                    loading: true,
                });

                const sidebarOptions = await Api.getSidebarOptions();

                setState({
                    loading: false,
                    loaded: true,
                    sidebarOptions: sidebarOptions.data,
                });
            },
    },
    // optional, mostly used for easy debugging
    name: 'sidebar',
});

const useSideBarOptions = createHook(Store);

export default useSideBarOptions;
